import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../user/user.actions';
import * as RegionActions from './region.actions';
import { initialState, RegionState } from './region.state';

const regionsReducer = createReducer(
  initialState,
  // fetch regions
  on(RegionActions.fetchRegions, (state) => ({
    ...state,
    loading: true,
  })),
  on(RegionActions.fetchRegionsSuccess, (state, { regions }) => ({
    ...state,
    regions,
    loading: false,
    loadedAt: new Date(),
  })),
  on(RegionActions.fetchRegionsFailed, (state, { error }) => ({
    ...state,
    loading: false,
  })),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    regions: [],
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: RegionState | undefined, action: Action) {
  return regionsReducer(state, action);
}
