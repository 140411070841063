import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../user/user.actions';
import * as CreateCapsuleActions from './create-capsule.actions';
import { CreateCapsuleState, initialState } from './create-capsule.state';

const createCapsuleReducer = createReducer(
  initialState,
  on(
    CreateCapsuleActions.setCreateCapsuleStateSuccess,
    (state, { createCapsuleState }) => ({
      ...state,
      createCapsuleState,
    })
  ),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    createCapsuleState: null,
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: CreateCapsuleState | undefined, action: Action) {
  return createCapsuleReducer(state, action);
}
