import { BaseState } from '../base.state';
import { Plan } from '../../models/plan';

export interface PlanState extends BaseState {
  plans?: Plan[];
  error?: any;
}

export const initialState: PlanState = {
  plans: [],
  error: null,
};
