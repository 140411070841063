import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { appstraxAuth } from '@appstrax/services/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isAuthenticated = await appstraxAuth.isAuthenticated();
    if (!isAuthenticated) {
      let redirectUrl = state.url;
      let params = null;
      if (state.url.includes('?')) {
        redirectUrl = state.url.substring(0, state.url.indexOf('?'));
        params = JSON.stringify(route.queryParams);
      }

      this.router.navigate(['/auth/login'], {
        queryParams: { redirectUrl, params },
      });
      return false;
    }

    return true;
  }
}
