declare var grecaptcha: any;
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { APIResponse } from '../api';

export enum RecaptchaAction {
  login = 'login',
  register = 'register',
  createTeam = 'createTeam',
  createSpace = 'createSpace',
  createCapsule = 'createCapsule',
}

export interface RecaptchaResult {
  action: RecaptchaAction;
  score: number;
}

@Injectable()
export class ReCaptchaService {
  constructor(private http: HttpClient) {}

  async execute(action: RecaptchaAction): Promise<RecaptchaResult> {
    environment.reCaptchaSiteId;
    return new Promise((resolve, reject) => {
      grecaptcha.enterprise.ready(async () => {
        try {
          const token = await grecaptcha.enterprise.execute(
            environment.reCaptchaSiteId,
            { action }
          );
          const response = await this.postActionToServer(token, action);

          // TODO: decide what to do with the score

          resolve({
            score: response.score,
            action,
          });
        } catch (err) {
          reject(err);
        }
      });
    });
  }

  private postActionToServer(
    token: string,
    action: RecaptchaAction
  ): Promise<{ score }> {
    return new Promise((resolve, reject) => {
      const endpoint = `${environment.apiBaseUrl}/recaptcha`;
      this.http
        .post<APIResponse<{ score: number }>>(endpoint, {
          token: token,
          action: action,
        })
        .subscribe({
          next: (response) => resolve(response.data),
          error: reject,
        });
    });
  }

  // grecaptcha.enterprise.ready(async () => {
  //   const token = await grecaptcha.enterprise.execute('6LcURt4mAAAAADGQsQl5o9LJcfpKoNyKK2ZAlmWR', {action: 'LOGIN'});
  //   // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
  //   // reCAPTCHA Enterprise to the end user's browser.
  //   // This token must be validated by creating an assessment.
  //   // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
  // });
}
