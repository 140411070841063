// angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

// ngrx
import * as NotificationActions from './notification.actions';
import * as UserActions from '../user/user.actions';

import { environment } from '../../../../environments/environment';
import { APIResponse } from '../../api';
import { TeamInvitation } from '../../models/team-invitation';

@Injectable()
export class NotificationsEffects {
  constructor(private actions$: Actions, private httpClient: HttpClient) {}

  fetchNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess, NotificationActions.fetchNotifications),

      switchMap(() => {
        const url = `${environment.apiBaseUrl}/users/invitations`;
        return this.httpClient.get<APIResponse<TeamInvitation[]>>(url).pipe(
          map((apiResponse) => {
            if (!apiResponse.data) {
              return NotificationActions.fetchNotificationsFailed({
                error: new Error('Something went wrong.'),
              });
            }

            return NotificationActions.fetchNotificationsSuccess({
              notifications: apiResponse.data,
            });
          }),
          catchError((error) =>
            of(NotificationActions.fetchNotificationsFailed({ error }))
          )
        );
      })
    )
  );

  acceptTeamInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.acceptTeamInvite),
      switchMap((action) => {
        const inviteId = action.inviteId;
        const url = `${environment.apiBaseUrl}/users/invitations/${inviteId}/accept`;
        return this.httpClient.put(url, {}).pipe(
          map(() => {
            return NotificationActions.acceptTeamInviteSuccess({
              inviteId: inviteId,
            });
          }),
          catchError((error) =>
            of(NotificationActions.acceptTeamInviteFailed({ error }))
          )
        );
      })
    )
  );

  rejectTeamInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.rejectTeamInvite),
      switchMap((action) => {
        const inviteId = action.inviteId;
        const url = `${environment.apiBaseUrl}/users/invitations/${inviteId}/reject`;
        return this.httpClient.put(url, {}).pipe(
          map(() => {
            return NotificationActions.rejectTeamInviteSuccess({ inviteId });
          }),
          catchError((error) =>
            of(NotificationActions.rejectTeamInviteFailed({ error }))
          )
        );
      })
    )
  );
}
