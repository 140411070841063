import { BillingInvoice } from '../../models/billing-invoice';
import { BaseState } from '../base.state';

export class InvoiceState extends BaseState {
  invoices: Array<BillingInvoice>;
  error?: any;
}

export const initialState: InvoiceState = {
  invoices: [],
  loadedAt: null,
  loading: false,
  saving: false,
  error: null,
};
