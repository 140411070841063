import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'info-modal.component.html',
})
export class InfoModalComponent {
  public options: InfoModalOptions;
  public hide: boolean = false;

  constructor(private bsModalRef: BsModalRef) {}

  close(): void {
    if (this.options.close) {
      this.options.close();
    }
    this.bsModalRef.hide();
  }
}

export class InfoModalOptions {
  title: string;
  message: string;
  close: () => void;
}
