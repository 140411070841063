import { Action, createReducer, on } from '@ngrx/store';

import { StateUtils } from '../state-utils';

import * as UserActions from '../user/user.actions';
import * as SpacesActions from './space.actions';
import { SpaceState, initialState } from './space.state';

const spacesReducer = createReducer(
  initialState,
  // fetch spaces
  on(SpacesActions.fetchSpaces, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    SpacesActions.fetchSpacesSuccess,
    SpacesActions.initialFetchSpacesSuccess,
    (state, { spaces }) => ({
      ...state,
      spaces,
      loading: false,
      loadedAt: new Date(),
    })
  ),
  on(SpacesActions.fetchSpacesFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch spaces by team
  on(SpacesActions.fetchSpacesByTeam, (state) => ({
    ...state,
    loading: true,
  })),
  on(SpacesActions.fetchSpacesByTeamSuccess, (state, { spaces }) => ({
    ...state,
    spaces: StateUtils.combineStateArr(state.spaces, spaces),
    loading: false,
  })),
  on(SpacesActions.fetchSpacesByTeamFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // add space
  on(SpacesActions.addSpace, (state) => ({
    ...state,
    loading: true,
  })),
  on(SpacesActions.addSpaceSuccess, (state, { space }) => ({
    ...state,
    spaces: [...state.spaces, space],
    loading: false,
  })),
  on(SpacesActions.addSpaceFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // update Space
  on(SpacesActions.updateSpace, (state) => ({
    ...state,
    loading: true,
  })),
  on(SpacesActions.updateSpaceSuccess, (state, { space }) => {
    return {
      ...state,
      spaces: [...state.spaces].map((x) => (x.id === space.id ? space : x)),
      loading: false,
    };
  }),
  on(SpacesActions.updateSpaceFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch space usage
  on(SpacesActions.fetchSpaceUsage, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    SpacesActions.fetchSpaceUsageSuccess,
    (state, { space, teamProductUsage }) => ({
      ...state,
      usageBySpace: { ...state.usageBySpace, [space.id]: teamProductUsage },
      loading: false,
    })
  ),
  on(SpacesActions.fetchSpaceUsageFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // remove space
  on(SpacesActions.removeSpace, (state) => ({
    ...state,
    loading: true,
  })),
  on(SpacesActions.removeSpaceSuccess, (state, { spaceId }) => ({
    ...state,
    spaces: state.spaces.filter((x) => x.id !== spaceId),
    usageBySpace: { ...state.usageBySpace, [spaceId]: null },
    loading: false,
  })),
  on(SpacesActions.removeSpaceFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    spaces: [],
    usageBySpace: {},
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: SpaceState | undefined, action: Action) {
  return spacesReducer(state, action);
}
