import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../user/user.actions';
import * as Actions from './plan.actions';
import { initialState, PlanState } from './plan.state';

const planReducer = createReducer(
  initialState,

  on(Actions.setError, (state, { error }) => ({
    ...state,
    error,
  })),

  // fetch phone numbers
  on(Actions.fetchPlans, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(Actions.fetchPlansSuccess, (state, { plans }) => ({
    ...state,
    loading: false,
    loadedAt: new Date(),
    plans,
  })),

  on(Actions.fetchPlansFailed, (state, { error }) => ({
    ...state,
    loading: false,
    currentUser: null,
    error,
  })),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    plans: [],
    error: null,
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: PlanState | undefined, action: Action) {
  return planReducer(state, action);
}
