import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../user/user.actions';
import * as Actions from './phone-number.actions';
import { initialState, PhoneNumberState } from './phone-number.state';

const phoneNumberReducer = createReducer(
  initialState,

  on(Actions.setError, (state, { error }) => ({
    ...state,
    error,
  })),

  // fetch phone numbers
  on(Actions.fetchPhoneNumbers, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(Actions.fetchPhoneNumbersSuccess, (state, { phoneNumbers }) => ({
    ...state,
    loading: false,
    loadedAt: new Date(),
    phoneNumbers,
    activePhoneNumber: phoneNumbers.find((x) => x.active),
  })),

  on(Actions.fetchPhoneNumbersFailed, (state, { error }) => ({
    ...state,
    loading: false,
    currentUser: null,
    error,
  })),

  // create phone number
  on(Actions.createPhoneNumber, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(Actions.createPhoneNumberSuccess, (state, { phoneNumber }) => {
    return {
      ...state,
      loading: false,
      phoneNumbers: [...state.phoneNumbers, phoneNumber],
    };
  }),

  on(Actions.createPhoneNumberFailed, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  }),

  // set phone number active
  on(Actions.setPhoneNumberActive, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(Actions.setPhoneNumberActiveSuccess, (state, { phoneNumbers }) => {
    return {
      ...state,
      loading: false,
      phoneNumbers,
      activePhoneNumber: phoneNumbers.find((x) => x.active),
    };
  }),

  on(Actions.setPhoneNumberActiveFailed, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  }),

  // send code to phone number
  on(Actions.sendCodeToPhoneNumber, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(Actions.sendCodeToPhoneNumberSuccess, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(Actions.sendCodeToPhoneNumberFailed, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  }),

  // verify phone number
  on(Actions.verifyPhoneNumber, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(Actions.verifyPhoneNumberSuccess, (state, { phoneNumbers }) => {
    return {
      ...state,
      loading: false,
      phoneNumbers,
      activePhoneNumber: phoneNumbers.find((x) => x.active),
    };
  }),

  on(Actions.verifyPhoneNumberFailed, (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  }),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    activePhoneNumber: null,
    phoneNumbers: [],
    error: null,
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: PhoneNumberState | undefined, action: Action) {
  return phoneNumberReducer(state, action);
}
