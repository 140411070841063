import { Action, createReducer, on } from '@ngrx/store';
import { initialState, PaymentMethodState } from './payment-method.state';

import * as UserActions from '../user/user.actions';
import * as PaymentMethodActions from './payment-method.actions';

const paymentMethodReducer = createReducer(
  initialState,

  on(PaymentMethodActions.createPaymentMethod, (state) => ({
    ...state,
    saving: true,
    error: null,
  })),

  on(
    PaymentMethodActions.createPaymentMethodSuccess,
    (state, { paymentMethod }) => ({
      ...state,
      paymentMethods: [...state.paymentMethods, paymentMethod],
      saving: false,
    })
  ),

  on(PaymentMethodActions.createPaymentMethodnFailed, (state, { error }) => ({
    ...state,
    saving: false,
    error,
  })),

  on(PaymentMethodActions.fetchPaymentMethods, (state) => ({
    ...state,
    loading: true,
  })),

  on(
    PaymentMethodActions.fetchPaymentMethodsSuccess,
    (state, { paymentMethods, primaryPaymentMethod }) => ({
      ...state,
      paymentMethods,
      primaryPaymentMethod,
      loading: false,
      loadedAt: new Date(),
    })
  ),

  on(PaymentMethodActions.fetchPaymentMethodsFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    primaryPaymentMethod: null,
    paymentMethods: [],
    loadedAt: null,
    loading: false,
    saving: false,
    error: null,
  }))
);

export function reducer(state: PaymentMethodState | undefined, action: Action) {
  return paymentMethodReducer(state, action);
}
