import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpService } from '../http.service';
import { Cluster } from '../../models';

@Injectable()
export class ClusterService {
  private baseUrl = environment.apiBaseUrl + '/clusters';

  private clusters: Array<Cluster> = [];

  constructor(private httpService: HttpService) {}

  async getClusters(): Promise<Array<Cluster>> {
    const res = await this.httpService.get(`${this.baseUrl}`);
    this.clusters = res.data;
    return this.clusters;
  }
}
