import { Cloud } from '../../models/cloud';
import { BaseState } from '../base.state';

export interface CloudState extends BaseState {
  clouds: Array<Cloud>;
  currentCloud: Cloud;
}

export const initialState: CloudState = {
  clouds: [],
  currentCloud: null,
};
