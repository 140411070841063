import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NotificationState } from './notification.state';

export const selectNotifications =
  createFeatureSelector<NotificationState>('notification');

export const selectAllNotifications = createSelector(
  selectNotifications,
  (state: NotificationState) => state.notifications
);
