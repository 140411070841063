import { createAction, props } from '@ngrx/store';

import { RecaptchaAction } from '../../services/recaptcha.service';

export const recaptchaLogin = createAction('[Recaptcha] Login Initiated');
export const recaptchaRegister = createAction('[Recaptcha] Register Initiated');
export const recaptchaCreateTeam = createAction(
  '[Recaptcha] Create Team Initiated'
);
export const recaptchaCreateSpace = createAction(
  '[Recaptcha] Create Space Initiated'
);
export const recaptchaCreateCapsule = createAction(
  '[Recaptcha] Create Capsule Initiated'
);
export const recaptchaSuccess = createAction(
  '[Recaptcha] Recaptcha Success',
  props<{ action: RecaptchaAction, score: number }>()
);
export const recaptchaFailed = createAction(
  '[Recaptcha] Recaptcha Failed',
  props<{ error: Error }>()
);
