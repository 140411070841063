import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpService } from '../../clients/http.service';
import { Seller } from '../../models/seller';

@Injectable()
export class SellerService {
  private baseUrl = environment.apiBaseUrl + '/marketplace';

  public seller: Seller;

  constructor(private httpService: HttpService) {}

  async createSeller(seller: Seller): Promise<Seller> {
    const res = await this.httpService.post(`${this.baseUrl}/sellers`, seller);
    return res.data;
  }
}
