import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { APIResponse } from '../../api';

import * as UserActions from '../user/user.actions';
import * as PlanActions from './plan.actions';

@Injectable()
export class PlanEffects {
  constructor(private actions$: Actions, private httpClient: HttpClient) {}

  private baseUrl = `${environment.apiBaseUrl}/plans`;

  // fetch plans
  fetchPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActions.loginSuccess,
        PlanActions.fetchPlans
      ),

      switchMap(() =>
        this.httpClient.get<APIResponse<any>>(this.baseUrl).pipe(
          map((response) =>
            PlanActions.fetchPlansSuccess({
              plans: response.data,
            })
          ),
          catchError((error) =>
            of(
              PlanActions.fetchPlansFailed({
                error: this.handleError(error),
              })
            )
          )
        )
      )
    )
  );

  private handleError(error): string {
    if (error?.error?.error?.message) {
      return error?.error?.error?.message;
    }
    if (error?.error?.message) {
      return error?.error?.message;
    }
    if (error?.message) {
      return error?.message;
    }
    if (typeof error === 'string') {
      return error;
    }

    return 'An unexpected error occurred';
  }
}
