import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../user/user.actions';
import * as CapsuleActions from './capsule.actions';
import { StateUtils as Utils } from '../state-utils';
import { CapsuleState, initialState } from './capsule.state';

import { Capsule } from '../../models';

function sortByFetchedAt(itemA, itemB) {
  if (itemA.fetchedAt === itemB.fetchedAt) return 0;
  return itemA.fetchedAt > itemB.fetchedAt ? 1 : 0;
}

const capsuleReducer = createReducer(
  initialState,

  // fetch capsule
  on(CapsuleActions.fetchCapsule, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.fetchCapsuleSuccess, (state, { capsule }) => ({
    ...state,
    capsules: Utils.combineState(state.capsules, capsule),
    loading: false,
  })),
  on(CapsuleActions.fetchCapsuleFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch capsules
  on(CapsuleActions.fetchSpaceCapsules, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.fetchSpacesCapsules, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.fetchCapsulesSuccess, (state, { capsules }) => ({
    ...state,
    capsules: Utils.combineStateArr(state.capsules, capsules),
    loading: false,
  })),
  on(CapsuleActions.fetchCapsulesFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch capsule usage
  on(CapsuleActions.fetchCapsuleUsage, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.fetchCapsuleUsageSuccess, (state, { capsuleUsage }) => {
    const usageByCapsule = { ...state.usageByCapsule };
    usageByCapsule[capsuleUsage.capsuleId] = capsuleUsage;
    return {
      ...state,
      usageByCapsule,
      loading: false,
    };
  }),
  on(CapsuleActions.fetchCapsuleUsageFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // set capsule auto build
  on(CapsuleActions.updateCapsuleAutoBuild, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.updateCapsuleAutoBuildSuccess, (state, { capsule }) => {
    return {
      ...state,
      capsules: Utils.combineState(state.capsules, capsule),
      loading: false,
    };
  }),
  on(CapsuleActions.updateCapsuleAutoBuildFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch capsule configs
  on(CapsuleActions.fetchCapsuleConfig, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.fetchCapsuleConfigSuccess, (state, { config, capsule }) => {
    const configByCapsule = { ...state.configByCapsule };
    configByCapsule[capsule.id] = config;
    return {
      ...state,
      configByCapsule,
      loading: false,
    };
  }),
  on(CapsuleActions.fetchCapsuleConfigFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // set capsule configs
  on(CapsuleActions.updateCapsuleConfig, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    CapsuleActions.updateCapsuleConfigSuccess,
    (state, { config, capsule }) => ({
      ...state,
      configByCapsule: { ...state.configByCapsule, [capsule.id]: config },
      loading: false,
    })
  ),
  on(CapsuleActions.updateCapsuleConfigFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // update capsule manifest
  on(CapsuleActions.updateCapsuleManifest, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.updateCapsuleManifestSuccess, (state, { capsule }) => ({
    ...state,
    capsules: Utils.combineState(state.capsules, capsule),
    loading: false,
  })),
  on(CapsuleActions.updateCapsuleManifestFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch capsule builds
  on(CapsuleActions.fetchCapsuleBuilds, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.fetchCapsuleBuildsSuccess, (state, { builds, capsule }) => {
    const buildsByCapsule = { ...state.buildsByCapsule };
    buildsByCapsule[capsule.id] = builds;
    return {
      ...state,
      buildsByCapsule,
      loading: false,
    };
  }),
  on(CapsuleActions.fetchCapsuleBuildsFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch capsule backups
  on(CapsuleActions.fetchCapsuleBackups, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    CapsuleActions.fetchCapsuleBackupsSuccess,
    (state, { backups, capsule }) => {
      const backupsByCapsule = { ...state.backupsByCapsule };
      backupsByCapsule[capsule.id] = backups;
      return {
        ...state,
        backupsByCapsule,
        loading: false,
      };
    }
  ),
  on(CapsuleActions.fetchCapsuleBackupsFailed, (state) => ({
    ...state,
    loading: false,
  })),

  //set polling state
  on(CapsuleActions.stopPolling, (state) => ({
    ...state,
    pollingStopped: true,
  })),

  // fetch capsule backup restore
  on(CapsuleActions.fetchCapsuleBackupRestore, (state) => ({
    ...state,
    loading: false,
  })),
  on(
    CapsuleActions.fetchCapsuleBackupRestoreSuccess,
    (state, { backupRestore }) => {
      return {
        ...state,
        backupRestore,
        loading: false,
      };
    }
  ),
  on(CapsuleActions.fetchCapsuleBackupRestoreFailed, (state) => ({
    ...state,
    loading: false,
  })),

  //fetch backup download link
  on(CapsuleActions.fetchBackupDownloadLink, (state) => ({
    ...state
  })),
  on(CapsuleActions.fetchBackupDownloadLinkSuccess, (state, { backupDownloadUrl }) => ({
    ...state,
    backupDownloadUrl,
    error: null,
  })),
  on(CapsuleActions.fetchBackupDownloadLinkFailed, (state, { error }) => ({
    ...state,
    error,
  })),

  // update capsule description
  on(CapsuleActions.updateCapsuleDescription, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.updateCapsuleDescriptionSuccess, (state, { capsule }) => ({
    ...state,
    capsules: Utils.combineState(state.capsules, capsule),
    loading: false,
  })),
  on(CapsuleActions.updateCapsuleDescriptionFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // update capsule product
  on(CapsuleActions.updateCapsuleProducts, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.updateCapsuleProductsSuccess, (state, { capsule }) => ({
    ...state,
    capsules: Utils.combineState(state.capsules, capsule), // TODO validate that repo, namespace and products are on the capsule
    loading: false,
  })),
  on(CapsuleActions.updateCapsuleProductsFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // set capsule branch
  on(CapsuleActions.setCapsuleRepo, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.setCapsuleRepoSuccess, (state, { capsuleId, repo }) => ({
    ...state,
    capsules: state.capsules.map((x) =>
      x.id === capsuleId ? ({ ...x, repo } as Capsule) : x
    ),
    loading: false,
  })),
  on(CapsuleActions.setCapsuleRepoFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // remove capsule
  on(CapsuleActions.removeCapsule, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.removeCapsuleSuccess, (state, { capsule }) => {
    return {
      ...state,
      capsules: state.capsules.filter((x) => x.id !== capsule.id),
      usageByCapsule: { ...state.usageByCapsule, [capsule.id]: null },
      configByCapsule: { ...state.configByCapsule, [capsule.id]: null },
      buildsByCapsule: { ...state.buildsByCapsule, [capsule.id]: null },
      loading: false,
    };
  }),
  on(CapsuleActions.removeCapsuleFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // set capsule ingress enabled
  on(CapsuleActions.setCapsuleIngressEnabled, (state) => ({
    ...state,
    loading: true,
  })),
  on(CapsuleActions.setCapsuleIngressEnabledSuccess, (state, { capsule }) => ({
    ...state,
    capsules: Utils.combineState(state.capsules, capsule),
    loading: false,
  })),
  on(CapsuleActions.setCapsuleIngressEnabledFailed, (state) => ({
    ...state,
    loading: false,
  })),

    // set capsule webdav enabled
    on(CapsuleActions.setCapsuleWebDavEnabled, (state) => ({
      ...state,
      loading: true,
    })),
    on(CapsuleActions.setCapsuleWebDavEnabledSuccess, (state, { capsule }) => ({
      ...state,
      capsules: Utils.combineState(state.capsules, capsule),
      loading: false,
    })),
    on(CapsuleActions.setCapsuleWebDavEnabledFailed, (state) => ({
      ...state,
      loading: false,
    })),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    capsules: [],
    usageByCapsule: {},
    configByCapsule: {},
    buildsByCapsule: {},
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: CapsuleState | undefined, action: Action) {
  return capsuleReducer(state, action);
}
