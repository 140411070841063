import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

export interface PageMetadata {
  title: string;
  image: string;
  description: string;
  author: string;
  keywords: string[];
  type: string;
}

const defaultMetadata: PageMetadata = {
  title: 'Code Capsules',
  image: '_og-home.jpg',
  description: 'Build, Push, Deploy and Scale your web applications',
  author: 'Code Capsules Crew',
  keywords: ['Deployment', 'Platform as a Service', 'Backend', 'Frontend', 'Database', 'Angular', 'React', 'Node', 'Express', 'Python'],
  type: 'website',
};

@Injectable()
export class MetadataService {
  private titleBase: string = 'Code Capsules';

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private metaService: Meta,
    private titleService: Title,
    private router: Router
  ) { }

  clearAllMetadata(): void {
    this.clearMetadata();
    this.clearOgMetadata();
    this.clearCanonicalLink();
    this.clearSchemaScript();
  }

  private clearOgMetadata(): void {
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:image"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:author"');
    this.metaService.removeTag('property="og:url"');
    this.metaService.removeTag('property="og:type"');
    this.metaService.removeTag('property="og:site_name"');
  }

  private clearMetadata(): void {
    this.metaService.removeTag('name="title"');
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('name="author"');
    this.metaService.removeTag('name="keywords"');
    this.metaService.removeTag('name="robots"');
    this.metaService.removeTag('name="viewport"');

    this.metaService.removeTag('http-equiv="Content-Type"');
  }

  private clearCanonicalLink(): void {
    this.doc.querySelectorAll('[rel="canonical"]').forEach((link) => {
      link.remove();
    });
  }

  private clearSchemaScript(): void {
    this.doc.querySelectorAll('[type="application/ld+json"]').forEach((script) => {
      script.remove();
    });
  }

  setMetadata(metadata: Partial<PageMetadata>, index: boolean = true): void {
    const pageMetadata: PageMetadata = { ...defaultMetadata, ...metadata };
    const metatags: MetaDefinition[] = this.generateMetaDefinitions(pageMetadata);

    this.metaService.addTags([
      ...metatags,
      { property: 'og:url', content: `https://codecapsules.io${this.router.url}` },
      { property: 'og:image', content: `https://codecapsules.io/assets/seo/${metadata.image}` },
      { name: 'robots', content: index ? 'index, follow' : 'noindex' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
    ]);

    this.setTitle(pageMetadata.title);
    this.setCanonicalLink(`https://codecapsules.io${this.router.url}`);
  }

  private generateMetaDefinitions(metadata: PageMetadata): MetaDefinition[] {
    return [
      { name: 'title', content: metadata.title },
      { property: 'og:title', content: metadata.title },

      { name: 'description', content: metadata.description },
      { property: 'og:description', content: metadata.description },

      { name: 'author', content: metadata.author },
      { property: 'og:author', content: metadata.author },

      { name: 'keywords', content: metadata.keywords.join(', ') },

      { property: 'og:type', content: metadata.type },
      { property: 'og:site_name', content: defaultMetadata.title },
    ];
  }

  setTitle(titlePart: string): void {
    this.titleService.setTitle(titlePart);
  }

  resetTitle(): void {
    this.titleService.setTitle(this.titleBase);
  }

  setCanonicalLink(linkUrl: string): void {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', linkUrl);
  }

  setSchemaScript(scriptBody: string): void {
    const script: HTMLScriptElement = this.doc.createElement('script');
    this.doc.head.appendChild(script);
    script.setAttribute('type', 'application/ld+json');
    script.innerHTML = scriptBody;
  }
}
