import { createAction, props } from '@ngrx/store';

import { Space, TeamProductUsage } from '../../models';

// fetch spaces
export const fetchSpaces = createAction('[Space] Fetch Spaces');
export const fetchSpacesSuccess = createAction(
  '[Space] Fetch Spaces Success',
  props<{ spaces: Space[] }>()
);
export const initialFetchSpacesSuccess = createAction(
  '[Space] Initial Fetch Spaces Success',
  props<{ spaces: Space[] }>()
);
export const fetchSpacesFailed = createAction(
  '[Space] Fetch Spaces Failed',
  props<{ error: Error }>()
);

// fetch space
export const fetchSpace = createAction(
  '[Space] Fetch Space',
  props<{ spaceId: string }>()
);
export const fetchSpaceSuccess = createAction(
  '[Space] Fetch Space Success',
  props<{ spaces: Space }>()
);
export const fetchSpaceFailed = createAction(
  '[Space] Fetch Space Failed',
  props<{ error: Error }>()
);

// fetch spaces by team
export const fetchSpacesByTeam = createAction(
  '[Space] Fetch Space By Team',
  props<{ teamSlugOrId: string }>()
);
export const fetchSpacesByTeamSuccess = createAction(
  '[Space] Fetch Spaces By Team Success',
  props<{ spaces: Space[] }>()
);
export const fetchSpacesByTeamFailed = createAction(
  '[Space] Fetch Spaces By Team Failed',
  props<{ error: Error }>()
);

// add space
export const addSpace = createAction(
  '[Space] Add Space',
  props<{ space: Space }>()
);
export const addSpaceSuccess = createAction(
  '[Space] Add Space Success',
  props<{ space: Space }>()
);
export const addSpaceFailed = createAction(
  '[Space] Add Space Failed',
  props<{ error: Error }>()
);

// update space
export const updateSpace = createAction(
  '[Space] Update Space',
  props<{ space: Space }>()
);
export const updateSpaceSuccess = createAction(
  '[Space] Update Space Success',
  props<{ space: Space }>()
);
export const updateSpaceFailed = createAction(
  '[Space] Update Space Failed',
  props<{ error: Error }>()
);

// fetch space usage
export const fetchSpaceUsage = createAction(
  '[Space] Fetch Space Usage',
  props<{ space: Space }>()
);
export const fetchSpaceUsageSuccess = createAction(
  '[Space] Fetch Space Usage Success',
  props<{ space: Space; teamProductUsage: TeamProductUsage[] }>()
);
export const fetchSpaceUsageFailed = createAction(
  '[Space] Fetch Space Usage Failed',
  props<{ error: Error }>()
);

// remove space
export const removeSpace = createAction(
  '[Space] Remove Space',
  props<{ spaceId: string }>()
);
export const removeSpaceSuccess = createAction(
  '[Space] Remove Space Successful',
  props<{ spaceId: string }>()
);
export const removeSpaceFailed = createAction(
  '[Space] Remove Space Failed',
  props<{ error: Error }>()
);
