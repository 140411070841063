import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PhoneNumberState } from './phone-number.state';

export const selectPhoneNumber = createFeatureSelector<PhoneNumberState>(
  'phoneNumber'
);

export const selectLoading = createSelector(
  selectPhoneNumber, (state) => state.loading
);

export const selectError = createSelector(
  selectPhoneNumber, (state) => state.error
);

export const selectActivePhoneNumber = createSelector(
  selectPhoneNumber, (state) => state.activePhoneNumber
);

export const selectPhoneNumbers = createSelector(
  selectPhoneNumber, (state) => state.phoneNumbers
);
