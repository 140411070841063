import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

class LocalStorage implements Storage {
  [name: string]: any;
  readonly length: number;
  clear(): void {}
  getItem(key: string): string | null {
    return undefined;
  }
  key(index: number): string | null {
    return undefined;
  }
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}

@Injectable()
export class StorageService {
  private static DARK_MODE = 'DARK_MODE';
  private static AD_CAMPAIGN = 'AD_CAMPAIGN';
  private static LANDING_PAGE_ID = 'LANDING_PAGE_ID';
  private static COUPON_CODE = 'COUPON_CODE';
  private static TEAMS_ARRANGEMENT = 'TEAMS_ARRANGEMENT';
  private static HAS_AUTHENTICATED_KEY = 'HAS_AUTHENTICATED_KEY';

  private storage: Storage;

  constructor(@Inject(PLATFORM_ID) platformId: any) {
    if (isPlatformBrowser(platformId)) {
      this.storage = localStorage;
    } else {
      this.storage = new LocalStorage();
    }
  }

  public getTheme() {
    const isDarkMode = this.storage.getItem(StorageService.DARK_MODE);
    return JSON.parse(isDarkMode);
  }

  public setTheme(isDarkMode) {
    this.storage.setItem(StorageService.DARK_MODE, isDarkMode);
  }

  public clearTheme() {
    this.storage.removeItem(StorageService.DARK_MODE);
  }

  public setAdCampaignQueryParams(queryParams: any) {
    this.storage.setItem(
      StorageService.AD_CAMPAIGN,
      JSON.stringify(queryParams)
    );
  }

  public setCouponCode(couponCode: string) {
    this.storage.setItem(StorageService.COUPON_CODE, couponCode);
  }

  public getCouponCode() {
    return this.storage.getItem(StorageService.COUPON_CODE);
  }

  public setLandingPageId(id: string) {
    this.storage.setItem(StorageService.LANDING_PAGE_ID, id);
  }

  public getAdCampaignQueryParams() {
    return this.storage.getItem(StorageService.AD_CAMPAIGN) || null;
  }

  public getLandingPageId() {
    return this.storage.getItem(StorageService.LANDING_PAGE_ID) || null;
  }

  public setTeamsArrangement(sortedTeamIds: string[]) {
    this.storage.setItem(
      StorageService.TEAMS_ARRANGEMENT,
      JSON.stringify(sortedTeamIds)
    );
  }

  public getTeamsArrangement(): string[] {
    const teamIds = this.storage.getItem(StorageService.TEAMS_ARRANGEMENT);
    if (teamIds) return JSON.parse(teamIds);
    return [];
  }





  public getHasAuthenticated(): boolean {
    const hasAuthenticated = this.storage.getItem(StorageService.HAS_AUTHENTICATED_KEY);
    return JSON.parse(hasAuthenticated);
  }

  public setHasAuthenticated(authenticated: boolean) {
    this.storage.setItem(StorageService.HAS_AUTHENTICATED_KEY, JSON.stringify(authenticated));
  }

  public clearHasAuthenticated() {
    this.storage.removeItem(StorageService.HAS_AUTHENTICATED_KEY);
  }
}
