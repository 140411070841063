import { createFeatureSelector, createSelector } from '@ngrx/store';
import moment from 'moment';

import { TeamState } from './team.state';
import { RouteState, selectRoute } from './../route';
import { selectAllSpaces, selectCurrentSpace } from '../space';
import { selectAllCapsules } from './../capsule';
import { Capsule, Space, Team, TeamProductUsage } from '../../models';

export const selectTeams = createFeatureSelector<TeamState>('team');

export const selectAllTeams = createSelector(
  selectTeams,
  (state: TeamState) => state.teams
);

export const selectCurrentTeam = createSelector(
  selectAllTeams,
  selectRoute,
  selectCurrentSpace,
  (teams: Team[], route: RouteState, space: Space) => {
    const teamSlugOrId = route.params['team_slug_or_id'];
    if (teamSlugOrId) {
      return (
        teams.find((x) => x.id === teamSlugOrId) ||
        teams.find((x) => x.slug === teamSlugOrId) ||
        null
      );
    }

    if (space) {
      return teams.find((x) => x.id === space.teamId);
    }

    return null;
  }
);

export const selectTeamById = (props: { id: string }) =>
  createSelector(selectAllTeams, (teams) =>
    teams.find((team) => team.id === props.id)
  );

export const selectTeamBySlug = (props: { slug: string }) =>
  createSelector(selectAllTeams, (teams) =>
    teams.find((team) => team.slug === props.slug)
  );

export const selectCurrentTeamUsage = createSelector(
  selectTeams,
  selectCurrentTeam,
  (state: TeamState, team: Team) => state.usageByTeam[team?.id] || []
);

export const selectCurrentTeamUsageByBillingPeriod = (props: {
  billingPeriod: string;
}) =>
  createSelector(
    selectCurrentTeamUsage,
    (currentTeamUsage: TeamProductUsage[]) =>
      currentTeamUsage.filter((tu) => tu.billingPeriod === props.billingPeriod)
  );

export const selectCurrentTeamUsagePrice = createSelector(
  selectCurrentTeamUsage,
  (teamProductUsage: TeamProductUsage[]) => {
    const billingPeriodMoment = moment().utc().endOf('M');
    const billingPeriod = billingPeriodMoment.format('YYYY-MM');

    let price = 0;
    teamProductUsage.forEach((teamProductUsage: TeamProductUsage) => {
      if (teamProductUsage.billingPeriod !== billingPeriod) return;

      price = price + Number(teamProductUsage.price || 0);
    });

    return price;
  }
);

export const selectCurrentTeamInvites = createSelector(
  selectCurrentTeam,
  selectTeams,
  (team: Team, state: TeamState) => {
    if (!team) return [];
    return state.invitesByTeam[team.id];
  }
);

export const selectCurrentTeamSpaces = createSelector(
  selectCurrentTeam,
  selectAllSpaces,
  (team: Team, spaces: Space[]) => spaces.filter((x) => x.teamId === team?.id)
);

export const selectCurrentTeamCapsules = createSelector(
  selectCurrentTeamSpaces,
  selectAllCapsules,
  (spaces: Space[], capsules: Capsule[]) => {
    const namespaceKeys = spaces.reduce((keys, space) => {
      keys[space.namespaceKey] = true;
      return keys;
    }, {});
    return capsules.filter((x) => namespaceKeys[x.namespace.key]);
  }
);

export const selectCurrentTeamPaymentStatus = createSelector(
  selectTeams,
  selectCurrentTeam,
  (state: TeamState, team: Team) => state.paymentStatusByTeam[team?.id] || null
);

export const selectPaymentStatusByTeamId = (props: { teamId: string }) =>
  createSelector(
    selectTeams,
    (state: TeamState) => state.paymentStatusByTeam[props.teamId] || null
  );
