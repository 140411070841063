import { Injectable } from '@angular/core';

import { TeamService } from '../team/team.service';
import { UtilService } from '../../services/util.service';
import { CapsuleService } from '../capsule/capsule.service';
import { ClusterService } from '../cluster/cluster.service';
import { AlertService } from '../../services/alert.service';

import { DemoRepo } from '../../models/demo-repo';
import { CapsuleRequest, Cluster, Space, Team } from '../../models';

@Injectable()
export class DemoCapsuleService {
  // public capsule: CapsuleRequest = {
  //   name: '',
  //   description: 'Demo',
  //   productKey: 'capsule:frontend:sandbox',
  //   // sourceSubpath: '',
  //   // userBuildCommand: '',
  //   // entrypointCommand: '',
  //   // userBuildOutputDirectory: '',
  //   type: 'frontend',
  //   // repo: {
  //   //   url: '',
  //   //   branch: 'main',
  //   //   isPrivate: false,
  //   //   provider: 'github',
  //   // },
  // };

  public clusters: Array<Cluster> = [];

  public team: Team = new Team();
  public space: Space = new Space();

  public errorMessage: string;
  pollSlugValidityTimer = null;

  constructor(
    private teamService: TeamService,
    private utilService: UtilService,
    private alert: AlertService,
    private capsuleService: CapsuleService,
    private clusterService: ClusterService,
  ) {
    this.pollSlugValidityTimer = setInterval(() => {});
  }

  // -> data-capsule changes
  // async createDemoCapsule(clusterId: string, demoRepo: DemoRepo) {
  //   try {
  //     this.capsule.repo.url = demoRepo.url;
  //     this.capsule.userBuildCommand = demoRepo.userBuildCommand;
  //     this.capsule.userBuildOutputDirectory = demoRepo.userBuildOutputDirectory;
  //     this.capsule.name = this.utilService.createSlugFrom(
  //       this.capsule.description
  //     );
  //
  //     const quickStartRequest = {
  //       clusterId,
  //       capsule: this.capsule,
  //     };
  //     const capsuleResp = await this.capsuleService.createDemoCapsule(quickStartRequest);
  //     return capsuleResp;
  //   } catch (err) {
  //     this.alert.alertError(err);
  //   }
  // }

  async fetchClusters() {
    try {
      const clusters = await this.clusterService.getClusters();
      this.clusters = (clusters || []).filter(cluster => cluster.isPrivate === false);
      if (this.clusters.length === 0) {
        return;
      }
      this.space.clusterId = this.clusters[0].id;
      this.space.teamId = this.team.id;
    } catch (err) {
      console.log(err);
      this.alert.error('Failed to fetch clusters.');
    }
  }
}
