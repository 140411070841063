import { BaseState } from '../base.state';

export class RecaptchaState extends BaseState {
  error?: any;
  score: number;
}

export const initialState: RecaptchaState = {
  error: null,
  score: null,
};
