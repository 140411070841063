import { AuthGuard } from './core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SplashComponent } from './components/splash.component';
import { NotFoundPage } from './core/pages/not-found/not-found.page';

const routes: Routes = [
  {
    path: '',
    component: SplashComponent,
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/auth.module').then((mod) => mod.AuthModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((mod) => mod.AdminModule),
  },
  {
    path: 'dash',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((mod) => mod.DashboardModule),
  },
  {
    path: 'new',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./create-content/create-content.module').then(
        (mod) => mod.CreateContentModule
      ),
  },
  {
    path: 'marketplace',
    loadChildren: () =>
      import('./marketplace/marketplace.module').then(
        (mod) => mod.MarketplaceModule
      ),
  },
  {
    path: 'pricing-calculator',
    loadChildren: () =>
      import('./pricing-calculator/pricing-calculator.module').then(
        (mod) => mod.PricingCalculatorModule
      ),
  },
  {
    path: '404',
    component: NotFoundPage,
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
