import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-base',
  template: `<div></div>`,
})
export class BaseComponent implements OnDestroy {
  destroyed$ = new Subject<boolean>();
  protected subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
