import { BaseState } from '../base.state';
import { LandingPage } from '../../models/landing-page';
import { LandingPageSnippet } from '../../models/landing-page-snippet';

export interface LandingPageState extends BaseState {
  landingPage: LandingPage;
  landingPageSnippets: LandingPageSnippet[];
  error?: any;
}

export const initialState: LandingPageState = {
  landingPage: null,
  landingPageSnippets: null,
  error: null,
};
