// angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError, concatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

// ngrx
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as DomainActions from './domain.actions';
import * as CapsuleActions from '../capsule/capsule.actions';

import { APIResponse } from '../../api/models/api-response';
import { environment } from '../../../../environments/environment';
import { Domain } from '../../models/domain';

@Injectable()
export class DomainsEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  fetchDomains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.fetchDomains),

      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Domain[]>>(`${environment.apiBaseUrl}/domain`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return DomainActions.fetchDomainsFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return DomainActions.fetchDomainsSuccess({
                domains: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(DomainActions.fetchDomainsFailed({ error }))
            )
          );
      })
    )
  );

  addDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.addDomain),

      concatMap((action) => {
        const clusterApiEndpoint = action.space.cluster.clusterApiEndpoint;
        const capsuleId = action.capsule.id;

        return this.httpClient
          .post<any>(
            `${clusterApiEndpoint}/capsules/${capsuleId}/domains`,
            action.domain
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse) {
                return DomainActions.addDomainFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return DomainActions.addDomainSuccess({ domain: apiResponse });
            }),
            catchError((error) => of(DomainActions.addDomainFailed({ error })))
          );
      })
    )
  );

  fetchCapsuleDomains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CapsuleActions.setCapsuleIngressEnabledSuccess,
        DomainActions.fetchCapsuleDomains
      ),

      switchMap((action) => {
        return this.httpClient
          .get<{
            domains: Domain[];
            ipAddress: string;
            capsuleHostName: string;
          }>(
            `${action.space.cluster.clusterApiEndpoint}/capsules/${action.capsule.id}/domains`
          )
          .pipe(
            map((apiResponse) => {
              return DomainActions.fetchCapsuleDomainsSuccess({
                capsuleId: action.capsule.id,
                ipAddress: apiResponse.ipAddress,
                hostName: apiResponse.capsuleHostName,
                domains: apiResponse.domains || [],
              });
            }),
            catchError((error) =>
              of(DomainActions.fetchCapsuleDomainsFailed({ error }))
            )
          );
      })
    )
  );

  removeCapsuleDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.removeCapsuleDomain),
      switchMap((action) => {
        return this.httpClient
          .delete<any>(
            `${action.space.cluster.clusterApiEndpoint}/capsules/${action.capsule.id}/domains/${action.domain.id}`
          )
          .pipe(
            map(() => {
              return DomainActions.removeCapsuleDomainSuccess({
                domain: action.domain,
                capsule: action.capsule,
                space: action.space,
              });
            }),
            catchError((error) =>
              of(DomainActions.removeCapsuleDomainFailed({ error }))
            )
          );
      })
    )
  );


  renewCapsuleDomainCertificate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DomainActions.renewDomainCertificate),
      switchMap((action) => {
        return this.httpClient
          .post<any>(
            `${
              action.space.cluster.clusterApiEndpoint
            }/domains/renew?domains={"domainIds":["${action.domain.id}"]}`,
            {})
          .pipe(
            map(() => {
              return DomainActions.renewDomainCertificateSuccess({
                domain: action.domain,
                space: action.space,
              });
            }),
            catchError((error) =>
              of(DomainActions.renewDomainCertificateFailed({ error }))
            )
          );
      })
    )
  );
}
