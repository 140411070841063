// angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map, catchError, concatMap } from 'rxjs/operators';

// ngrx
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RegionActions from './region.actions';
import * as UserActions from '../user/user.actions';

import { environment } from '../../../../environments/environment';
import { APIResponse } from '../../api';
import { Region } from '../../models/region';

@Injectable()
export class RegionsEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  fetchRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess, RegionActions.fetchRegions),

      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Region[]>>(`${environment.apiBaseUrl}/regions`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return RegionActions.fetchRegionsFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return RegionActions.fetchRegionsSuccess({
                regions: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(RegionActions.fetchRegionsFailed({ error }))
            )
          );
      })
    )
  );
}
