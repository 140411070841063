import { BillingPaymentMethod } from '../../models/billing-payment-method';
import { BaseState } from '../base.state';

export class PaymentMethodState extends BaseState {
  primaryPaymentMethod?: BillingPaymentMethod;
  paymentMethods: Array<BillingPaymentMethod>;
  error?: any;
}

export const initialState: PaymentMethodState = {
  primaryPaymentMethod: null,
  paymentMethods: [],
  loadedAt: null,
  loading: false,
  saving: false,
  error: null,
};
