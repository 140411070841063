import { Action, createReducer, on } from '@ngrx/store';
import moment from 'moment';

import { UserProfile } from '../../models';
import { Invoice } from '../../models/invoice';

import * as UserActions from '../user/user.actions';
import * as AdminActions from './admin.actions';
import { AdminState, initialState } from './admin.state';
import { CustomerUsage } from '../../models/customer-usage';

const adminReducer = createReducer(
  initialState,

  on(AdminActions.fetchUsers, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(AdminActions.fetchUsersSuccess, (state, { users }) => {
    let usersById = { ...state.usersById };

    users.forEach((user) => {
      usersById[user.id] = user;
    });

    return {
      ...state,
      users,
      usersById,
      loading: false,
    };
  }),

  on(
    AdminActions.fetchBillingCustomersSuccess,
    (state, { billingCustomers }) => {
      const billingCustomersWithProviders = billingCustomers.map((bc) => {
        const zohoBooksProvider = bc.providers?.find(
          (provider) =>
            provider.provider === 'Zoho_books' &&
            provider.provider_type === 'Accounting'
        );
        const stripeProvider = bc.providers?.find(
          (provider) =>
            provider.provider === 'Stripe' &&
            provider.provider_type === 'Payments'
        );
        return {
          ...bc,
          zohoBooksProvider,
          stripeProvider,
        };
      });

      let billingCustomersById = { ...state.billingCustomersById };

      billingCustomersWithProviders.forEach((user) => {
        billingCustomersById[user.id] = user;
      });
      return {
        ...state,
        billingCustomers: billingCustomersWithProviders,
        billingCustomersById,
        loading: false,
      };
    }
  ),

  on(AdminActions.fetchUsageSuccess, (state, { usage }) => {
    // Limit the count of users to loop through where possible
    const customerUsages: Array<CustomerUsage> = state.users.map(
      (user: UserProfile) => {
        const customerUsage = {
          userId: user.id,
          customerId: user.customerKey,
          clusters: [],
          teams: [],
          clustersTotal: 0,
          teamsTotal: 0,
          total: 0,
        };

        if (user.customerKey) {
          // Limit clusters to search through where possible
          const clusters = usage.clusters.filter(
            (cl) => cl.cluster.customerKey === user.customerKey
          );
          clusters.forEach((clusterUsage) => {
            if (clusterUsage.cluster?.customerKey === user.customerKey) {
              customerUsage.clusters.push(clusterUsage);
              customerUsage.clustersTotal =
                customerUsage.clustersTotal + Number(clusterUsage.price);
              customerUsage.total =
                customerUsage.total + Number(clusterUsage.price);
            }
          });
          const teams = usage.teams.filter(
            (cl) => cl.team?.customerKey === user.customerKey
          );
          teams.forEach((teamUsage) => {
            if (teamUsage.team?.customerKey === user.customerKey) {
              customerUsage.teams.push(teamUsage);
              customerUsage.teamsTotal =
                customerUsage.teamsTotal + Number(teamUsage.price);
              customerUsage.total =
                customerUsage.total + Number(teamUsage.price);
            }
          });
        }
        return customerUsage;
      }
    );

    const allUsage = {
      userId: null,
      clusters: [],
      teams: [],
      clustersTotal: 0,
      teamsTotal: 0,
      total: 0,
    };
    usage.clusters.forEach((clusterUsage) => {
      allUsage.clusters.push(clusterUsage);
      allUsage.clustersTotal =
        allUsage.clustersTotal + Number(clusterUsage.price);
      allUsage.total = allUsage.total + Number(clusterUsage.price);
    });
    usage.teams.forEach((teamUsage) => {
      allUsage.teams.push(teamUsage);
      allUsage.teamsTotal = allUsage.teamsTotal + Number(teamUsage.price);
      allUsage.total = allUsage.total + Number(teamUsage.price);
    });

    return {
      ...state,
      usage: allUsage,
      customerUsage: customerUsages,
      loading: false,
    };
  }),

  on(AdminActions.fetchCustomerUsageSuccess, (state, { userId, usage }) => {
    const customers: Array<UserProfile> = state.users.map((c: UserProfile) => {
      if (c.id === userId) {
        return {
          ...c,
          usage,
        };
      }

      return {
        ...c,
      };
    });

    return {
      ...state,
      customers,
      loading: false,
    };
  }),

  on(AdminActions.fetchInvoicesSuccess, (state, { invoices }) => {
    const customers: Array<UserProfile> = state.users.map((c: UserProfile) => {
      const customerInvoices = invoices.filter(
        (i) => i.customerKey === c.customerKey
      );

      return {
        ...c,
        invoices: customerInvoices,
      };
    });

    return {
      ...state,
      customers,
      invoices,
      loading: false,
    };
  }),

  on(AdminActions.fetchBillingTasksSuccess, (state, { billingTasks }) => {
    return {
      ...state,
      billingTasks,
      loading: false,
    };
  }),

  on(AdminActions.fetchBillingLinesSuccess, (state, { billingLines }) => {
    const invoices = state.invoices.map((i: Invoice) => {
      const billingApiBillingPeriod = moment(i.billingPeriod, 'YYYY-MM')
        .endOf('month')
        .format('YYYY-MM-DD');

      const invoiceBillingLines = billingLines.filter(
        (bl) => bl.billing_period === billingApiBillingPeriod
      );
      return {
        ...i,
        billingLines: invoiceBillingLines,
      };
    });
    return {
      ...state,
      invoices,
      loading: false,
    };
  }),
  on(AdminActions.fetchBillingInvoiceSuccess, (state, { billingInvoice }) => {
    return {
      ...state,
      currentBillingInvoice: billingInvoice,
      loading: false,
    };
  }),
  on(AdminActions.fetchBillingInvoicesSuccess, (state, { billingInvoices }) => {
    const invoices = state.invoices.map((i: Invoice) => {
      const billingApiBillingPeriod = moment(i.billingPeriod, 'YYYY-MM')
        .endOf('month')
        .format('YYYY-MM-DD');

      const invoiceBillingInvoice = billingInvoices.find(
        (bi) => bi.billing_period === billingApiBillingPeriod
      );
      return {
        ...i,
        billingInvoice: invoiceBillingInvoice,
      };
    });

    return {
      ...state,
      invoices,
      loading: false,
    };
  }),
  on(AdminActions.fetchTeamsSuccess, (state, { teams }) => {
    return {
      ...state,
      teams,
      loading: false,
    };
  }),

  on(AdminActions.fetchSpacesSuccess, (state, { spaces }) => {
    return {
      ...state,
      spaces,
      loading: false,
    };
  }),

  on(AdminActions.fetchCapsulesSuccess, (state, { capsules }) => {
    return {
      ...state,
      capsules,
      loading: false,
    };
  }),

  on(AdminActions.fetchProductsSuccess, (state, { products }) => {
    return {
      ...state,
      products,
      loading: false,
    };
  }),

  on(AdminActions.fetchPlansSuccess, (state, { plans }) => {
    return {
      ...state,
      plans,
      loading: false,
    };
  }),

  on(AdminActions.fetchPhoneNumbersSuccess, (state, { phoneNumbers }) => {
    return {
      ...state,
      // customers,
      phoneNumbers,
      loading: false,
    };
  }),

  on(AdminActions.fetchClustersSuccess, (state, { clusters }) => {
    return {
      ...state,
      clusters,
      loading: false,
    };
  }),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    billingCustomers: [],
    billingCustomersById: {},
    currentBillingInvoice: null,
    billingTasks: [],

    users: [],
    usersById: {},

    teams: [],
    spaces: [],
    capsules: [],
    invoices: [],
    products: [],
    phoneNumbers: [],

    usage: {
      userId: null,
      clusters: [],
      teams: [],
      clustersTotal: 0,
      teamsTotal: 0,
      total: 0,
    },
    customerUsage: [],

    plans: [],
    clusters: [],

    loadedAt: null,
    loading: false,
    saving: false,
  }))

  // on(AdminActions.fetchUserTeamsSuccess, (state, { userTeams }) => {
  //   const customers: Array<Customer> = state.customers.map((c: Customer) => {
  //     if (c.id === customer.app_key) {
  //       return {
  //         ...c,
  //         billingCustomer: customer,
  //       };
  //     }

  //     return {
  //       ...c,
  //     };
  //   });

  //   return {
  //     ...state,
  //     customers,
  //     loading: false,
  //   };
  // })

  // on(AdminActions.fetchAuthUserSuccess, (state, { userId }) => ({
  //   ...state,
  //   authUser: state.users.find((x) => x.id === userId),
  //   loading: true,
  // })),
  // on(AdminActions.fetchUserSuccess, (state, { users }) => ({
  //   ...state,
  //   currentUser: users[0],
  //   loading: true,
  // })),
  // on(AdminActions.fetchUserTeamsSuccess, (state, { userTeams }) => ({
  //   ...state,
  //   userTeams: userTeams,
  //   loading: true,
  // })),
  // on(AdminActions.fetchUserClustersSuccess, (state, { clusters }) => ({
  //   ...state,
  //   clusters: clusters,
  //   loading: true,
  // })),
  // on(AdminActions.fetchUserCustomerSuccess, (state, { customer }) => ({
  //   ...state,
  //   customer: customer,
  //   loading: true,
  // })),
  // on(
  //   AdminActions.fetchCustomerPaymentMethodsSuccess,
  //   (state, { paymentMethods }) => ({
  //     ...state,
  //     paymentMethods: paymentMethods,
  //     loading: true,
  //   })
  // )
);

export function reducer(state: AdminState | undefined, action: Action) {
  return adminReducer(state, action);
}
