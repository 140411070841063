import { Action, createReducer, on } from '@ngrx/store';
import { Cluster } from '../../models';

import * as UserActions from '../user/user.actions';
import * as ClusterActions from './cluster.actions';
import { initialState, ClusterState } from './cluster.state';

const clustersReducer = createReducer(
  initialState,

  // fetch clusters
  on(ClusterActions.fetchClusters, (state) => ({
    ...state,
    loading: true,
  })),
  on(ClusterActions.fetchClustersSuccess, (state, { clusters }) => ({
    ...state,
    clusters,
    loading: false,
    loadedAt: new Date(),
  })),
  on(ClusterActions.fetchClustersFailed, (state, { error }) => ({
    ...state,
    loading: false,
  })),

  // fetch cluster scale configs
  on(ClusterActions.fetchClusterScaleConfigs, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    ClusterActions.fetchClusterScaleConfigsSuccess,
    (state, { clusterScaleConfigs }) => {
      const newClusters = state.clusters.map((c: Cluster) => {
        const thisClusterScaleConfigs =
          clusterScaleConfigs.filter((csc) => csc.clusterId === c.id) || [];

        const scaleConfigByCapsuleType = {};
        thisClusterScaleConfigs.forEach((csc) => {
          scaleConfigByCapsuleType[csc.capsuleType] = csc;
        });

        return {
          ...c,
          scaleConfig: thisClusterScaleConfigs,
          scaleConfigByCapsuleType,
        };
      });

      return {
        ...state,
        clusters: [...newClusters],
        clusterScaleConfigs,
        loading: false,
        loadedAt: new Date(),
      };
    }
  ),
  on(ClusterActions.fetchClusterScaleConfigsFailed, (state, { error }) => ({
    ...state,
    loading: false,
  })),

  // fetch cluster pricing
  on(ClusterActions.fetchClusterPricing, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    ClusterActions.fetchClusterPricingSuccess,
    (state, { cluster, pricing }) => {
      const newClusters = state.clusters.map((c: Cluster) => {
        if (c.id === cluster.id) {
          return {
            ...c,
            pricing,
          };
        } else {
          return {
            ...c,
          };
        }
      });

      return {
        ...state,
        clusters: [...newClusters],
      };
    }
  ),
  on(ClusterActions.fetchClusterPricingFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    clusters: [],
    clusterScaleConfigs: [],
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: ClusterState | undefined, action: Action) {
  return clustersReducer(state, action);
}
