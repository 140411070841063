import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable()
export class ThemeService {
  private isDarkMode: boolean;
  private darkModeObservable: Observable<boolean>;
  private subscribers: Subscriber<boolean>[] = [];

  constructor(
    private storageService: StorageService
  ) {
    this.isDarkMode = this.storageService.getTheme();
    this.darkModeObservable = new Observable<boolean>(sub => {
      this.subscribers.push(sub);
      sub.next(this.isDarkMode);
    });
  }

  getIsDarkMode(): boolean {
    return this.isDarkMode;
  }

  subscribeToDarkModeState(callback: (isDarkMode: boolean) => void): Subscription {
    return this.darkModeObservable.subscribe(callback);
  }

  setDarkMode(isDarkMode): void {
    this.isDarkMode = isDarkMode;
    this.storageService.setTheme(isDarkMode);

    for (const subscriber of this.subscribers) {
      subscriber.next(isDarkMode);
    }
  }
}
