import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { HttpService } from '../http.service';

import { UserProfile } from '../../models';
import { PlatformNotification } from '../../models/platform-notification';
import { APIResponse } from '../../api';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.apiBaseUrl + '/users';
  public user: UserProfile;

  constructor(
    private httpService: HttpService
    ) {}

  async getUser(): Promise<UserProfile> {
    if (!this.user) {
      const url = `${this.baseUrl}`;
      const userApiResponse = await this.httpService.get(url);
      this.user = userApiResponse.data;
    }
    return this.user;
  }

  async updateUser(user: UserProfile): Promise<UserProfile> {
    const url = `${this.baseUrl}/update`;
    const userApiResponse = await this.httpService.patch(url, user);
    this.user =  userApiResponse.data;
    return this.user;
  }

  async uploadNewProfileImage(user: UserProfile, file: File) {
    const url = `${this.baseUrl}/profile-image`;
    const formData: FormData = new FormData();
    formData.append('avatar', file);
    await this.httpService.post(url, formData);
  }

  async getPlatformNotifications(): Promise<Array<PlatformNotification>> {
    const url = `${this.baseUrl}/platform-notifications`;
    const notificationsResponse = await this.httpService.get(url);
    return notificationsResponse.data as Array<PlatformNotification>;
  }

  async getUsers(offset: number, limit: number): Promise<APIResponse<UserProfile>> {
    return await this.httpService.get(`${this.baseUrl}/all-users?offset=${offset}&limit=${limit}`);
  }
}
