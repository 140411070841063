// angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

// ngrx
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CloudsActions from './cloud.actions';

import { environment } from '../../../../environments/environment';
import { APIResponse } from '../../api';
import { Cloud } from '../../models/cloud';

@Injectable()
export class CloudEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  fetchClouds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CloudsActions.fetchClouds),

      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Cloud[]>>(`${environment.apiBaseUrl}/clouds`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return CloudsActions.fetchCloudsFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return CloudsActions.fetchCloudsSuccess({
                clouds: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(CloudsActions.fetchCloudsFailed({ error }))
            )
          );
      })
    )
  );
}
