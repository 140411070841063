import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpService {
  constructor(private httpClient: HttpClient) {}

  public post(url: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(url, body, this.getHeaders()).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  public put(url: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.put(url, body, this.getHeaders()).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  public patch(url: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.patch(url, body, this.getHeaders()).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  public get(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(url, this.getHeaders()).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  public delete(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.delete(url, this.getHeaders()).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  public getText(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers: any = this.getHeaders();
      headers.responseType = 'text';

      this.httpClient.get(url, headers).subscribe({
        next: (response) => resolve(response),
        error: (err) => reject(err),
      });
    });
  }

  public getHeaders(): { headers: HttpHeaders } {
    let headers = new HttpHeaders();

    return { headers };
  }
}
