import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CapsuleState } from './capsule.state';

import { Capsule } from '../../models';
import { RouteState, selectRoute } from '../route';

export const selectCapsules = createFeatureSelector<CapsuleState>('capsule');

export const selectAllCapsules = createSelector(
  selectCapsules,
  (state: CapsuleState) => state.capsules
);

export const selectCapsuleById = (props: { id: string }) =>
  createSelector(selectAllCapsules, (capsules) =>
    capsules.find((capsule) => capsule.id === props.id)
  );

export const selectCurrentCapsule = createSelector(
  selectAllCapsules,
  selectRoute,
  (capsules: Capsule[], route: RouteState) => {
    const capsuleId = route.params['capsule_id'];
    return capsules.find((x) => x.id === capsuleId);
  }
);

export const selectCurrentCapsuleUsage = createSelector(
  selectCapsules,
  selectCurrentCapsule,
  (state: CapsuleState, capsule: Capsule) =>
    state.usageByCapsule[capsule?.id] || null
);

export const selectCurrentCapsuleConfig = createSelector(
  selectCapsules,
  selectCurrentCapsule,
  (state: CapsuleState, capsule: Capsule) =>
    state.configByCapsule[capsule?.id] || null
);

export const selectCurrentCapsuleBuilds = createSelector(
  selectCapsules,
  selectCurrentCapsule,
  (state: CapsuleState, capsule: Capsule) =>
    state.buildsByCapsule[capsule?.id] || []
);

export const selectCurrentCapsuleBackups = createSelector(
  selectCapsules,
  selectCurrentCapsule,
  (state: CapsuleState, capsule: Capsule) =>
    state.backupsByCapsule[capsule?.id] || []
);

export const selectLoading = createSelector(
  selectCapsules,
  (state: CapsuleState) => state.loading
);

export const selectBackupDownloadUrl = createSelector(
  selectCapsules,
  (state) => state.backupDownloadUrl
);
