import { createAction, props } from '@ngrx/store';
import { Agency, Team, TeamAgency } from '../../models';

// fetch agency
export const fetchAgency = createAction('[Agency] Fetch Agency Initiated');
export const fetchAgencySuccess = createAction(
  '[Agency] Fetch Agency Success',
  props<{ agency: Agency }>()
);
export const fetchAgencyFailed = createAction(
  '[Agency] Fetch Agency Failed',
  props<{ error: Error }>()
);

// add agency
export const saveAgency = createAction(
  '[Agency] Save Agency Initiated',
  props<{ agency: Agency }>()
);
export const saveAgencySuccess = createAction(
  '[Agency] Save Agency Success',
  props<{ agency: Agency }>()
);
export const saveAgencyFailed = createAction(
  '[Agency] Save Agency Failed',
  props<{ error: Error }>()
);

// add team agency
export const addTeamAgency = createAction(
  '[Agency] Add Team Agency Initiated',
  props<{ agency: Agency; team: Team; markupPercent: number }>()
);
export const addTeamAgencySuccess = createAction(
  '[Agency] Add Team Agency Success',
  props<{ agency: Agency; team: Team }>()
);
export const addTeamAgencyFailed = createAction(
  '[Agency] Add Team Agency Failed',
  props<{ error: Error }>()
);

// remove agency team
export const removeTeamAgency = createAction(
  '[Agency] Remove Team Agency Initiated',
  props<{ agency: Agency; team: Team; teamAgency: TeamAgency }>()
);
export const removeTeamAgencySuccess = createAction(
  '[Agency] Remove Team Agency Success',
  props<{ agency: Agency; team: Team }>()
);
export const removeTeamAgencyFailed = createAction(
  '[Agency] Remove Team Agency Failed',
  props<{ error: Error }>()
);

// update agency image
export const updateAgencyImage = createAction(
  '[Agency] Update Agency Image Initiated',
  props<{ agencyId: string; file: File }>()
);
export const updateAgencyImageSuccess = createAction(
  '[Agency] Update Agency Image Success',
  props<{ agency: Agency }>()
);
export const updateAgencyImageFailed = createAction(
  '[Agency] Update Agency Image Failed',
  props<{ error: Error }>()
);
