import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { Observable, Subscription, take } from 'rxjs';
import { AuthErrors } from '@appstrax/services/auth';

import { TeamService } from '../clients/team/team.service';

interface Err {
  error: Error;
}

export interface KeyValue {
  key: string;
  value: string;
}

@Injectable()
export class UtilService {
  constructor(
    private router: Router,
    public teamService: TeamService,
    private activatedRoute: ActivatedRoute,
    private actions: Actions,
    private store: Store
  ) {}

  public createSlugFrom(text: string, appendRandom: boolean = true): string {
    // Slugs need to be:
    //  - 30 chars long
    //  - lowercase
    //  - Start && and end on an alphanumeric char
    if (!text) return '';

    let result = text
      .trim()
      .toLowerCase()
      .split(' ')
      .join('-')
      .replace(/[^a-z0-9,-]/gi, '')
      .slice(0, 25);

    if (!result) return '';
    while (result.includes('--')) result = result.replace('--', '-');
    const firstChar = result[0].replace(/[^a-z]/gi, this.randomString(1));
    result = firstChar + result.substring(1);
    if (appendRandom) result = result + '-' + this.randomString(4);
    result = result.replace('--', '-');
    return result;
  }

  public isValidDomain(input: string): boolean {
    const re = new RegExp(
      /(?=^.{1,254}$)(^(?:(?!\d+\.)[a-zA-Z0-9_\-]{1,63}\.?)+(?:[a-zA-Z]{2,})$)/gm
    );
    // https://newbedev.com/fully-qualified-domain-name-validation
    return re.test(input);
  }

  public randomString(length): string {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public getDate(date): Date {
    if (!date) {
      return null;
    }
    if (date instanceof Date) {
      return date;
    }
    return new Date(date);
  }

  public twoDigitString(digit: number): string {
    if (digit < 10) {
      return '0' + digit;
    }
    return digit + '';
  }

  public formatDate(dateInput): string {
    if (!dateInput) {
      return '-';
    }
    const date = this.getDate(dateInput);
    return (
      this.twoDigitString(date.getDate()) +
      '/' +
      this.twoDigitString(date.getMonth() + 1) +
      '/' +
      date.getFullYear()
    );
  }

  public formatCurrency(num: number, decimals: number = 2): string {
    let sign = '';
    const currency = '$';

    if (num < 0) {
      num = Math.abs(num);
      sign = '-';
    }

    if (num) {
      return (
        sign +
        currency +
        num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      );
    }
    return currency + '0.00';
  }

  public setParams(data: any, mergeParams = true): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: data,
      queryParamsHandling: mergeParams ? 'merge' : null,
    });
  }

  public actionsToPromise<T>(
    action: Action,
    successAction: ActionCreator<any, (props: T) => T & TypedAction<any>>,
    failedAction: ActionCreator<any, (props: Err) => Err & TypedAction<any>>
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const subs: Subscription[] = [];

      const successSub = this.actions
        .pipe(ofType(successAction))
        .subscribe((result: T) => {
          for (const sub of subs) sub.unsubscribe();
          resolve(result);
        });

      const failedSub = this.actions
        .pipe(ofType(failedAction))
        .subscribe((err) => {
          for (const sub of subs) sub.unsubscribe();
          reject(err.error);
        });

      subs.push(successSub);
      subs.push(failedSub);
      this.store.dispatch(action);
    });
  }

  public once<T>(observable: Observable<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      observable.pipe(take(1)).subscribe((result: T) => {
        resolve(result);
      });
    });
  }

  public sleep(milliseconds: number): Promise<void> {
    return new Promise((resolve, _reject) => {
      setTimeout(resolve, milliseconds);
    });
  }

  public getErrorMessage(err) {
    return UtilService.getErrorMessage(err);
  }

  public static getErrorMessage(err) {
    const message = this.getMessageFromError(err);
    switch (message) {
      case AuthErrors.emailAddressAlreadyExists:
        return 'Email Address Already Exists';
      case AuthErrors.badlyFormattedEmailAddress:
        return 'Email Address Badly Formatted';
      case AuthErrors.noPasswordSupplied:
        return 'No Password Supplied';
      case AuthErrors.invalidEmailOrPassword:
        return 'Invalid Email Or Password';
      case AuthErrors.userBlocked:
        return 'User blocked, please reset your password';
      case AuthErrors.invalidTwoFactorAuthCode:
        return 'Invalid Two Factor Authentication Code';
      case AuthErrors.emailAddressDoesNotExist:
        return 'Email Address Does Not Exist';
      case AuthErrors.invalidResetCode:
        return 'Invalid Reset Code';
      case AuthErrors.unexpectedError:
        return 'Unexpected error';
      default:
        return message;
    }
  }

  private static getMessageFromError(err) {
    if (typeof err === 'string') return err;
    if (err.error && typeof err.error === 'string') return err.error;
    if (err.error?.message) return err.error.message;
    if (err.error?.error?.message) return err.error.error.message;
    if (err.message) return err.message;
    return 'Something went wrong, please try again later';
  }

  public filterUnique<T>(array: T[], key?: string): T[] {
    const found = {};
    const result = [];
    for (const item of array) {
      const index = key ? item[key] : item;
      if (found[index]) continue;
      found[index] = true;
      result.push(item);
    }
    return result;
  }
}
