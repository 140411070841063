import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponse } from '../../api';
import { BillingPaymentMethod } from '../../models/billing-payment-method';

import * as PaymentMethodActions from '../payment-method/payment-method.actions';

@Injectable()
export class PaymentMethodEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  fetchPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentMethodActions.fetchPaymentMethods),

      switchMap((action) => {
        return this.httpClient
          .get<APIResponse<BillingPaymentMethod[]>>(
            `${environment.billingApi}/customers/${action.customer.id}/payment-methods` //?status=Failed&status=Active&status=Pending_Verification`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.success) {
                return PaymentMethodActions.fetchPaymentMethodsFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              const paymentMethods = apiResponse.data;
              const primaryPaymentMethod = paymentMethods.find(
                (paymentMethod: BillingPaymentMethod) => paymentMethod.primary
              );

              return PaymentMethodActions.fetchPaymentMethodsSuccess({
                paymentMethods,
                primaryPaymentMethod,
              });
            }),
            catchError((error) =>
              of(PaymentMethodActions.fetchPaymentMethodsFailed({ error }))
            )
          );
      })
    )
  );
}
