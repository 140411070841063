import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpService } from '../http.service';
import { Team, UserProfile } from '../../models';

@Injectable()
export class TeamService {
  private baseUrl = environment.apiBaseUrl + '/teams';

  public team: Team;
  private teams: Team[] = [];

  constructor(private httpService: HttpService) {}

  async getAllTeams(): Promise<Team[]> {
    if (this.teams.length) {
      return this.teams;
    }
    const teamsApiResponse = await this.httpService.get(`${this.baseUrl}`);
    this.teams = teamsApiResponse.data;
    return this.teams;
  }

  async invalidateTeams(): Promise<void> {
    const teamsApiResponse = await this.httpService.get(`${this.baseUrl}`);
    this.teams = teamsApiResponse.data;
  }

  async doesSlugExist(slug: string): Promise<boolean> {
    const res = await this.httpService.get(
      `${this.baseUrl}/slug/exists/${slug}`
    );
    return res.data;
  }

  async getTeamBySlug(slug: string): Promise<Team | null> {
    const team = this.teams.find((x) => x.slug === slug);
    if (team) {
      return team;
    }
    const teamResp = await this.httpService.get(`${this.baseUrl}/slug/${slug}`);
    return teamResp.data;
  }

  async createTeam(team: Team): Promise<Team> {
    const res = await this.httpService.post(`${this.baseUrl}`, team);
    // this.teams.push(res.data);
    await this.invalidateTeams();
    return res.data;
  }

  // async createDemoTeamAndSpace(clusterId: string): Promise<any> {
  //   const baseUrl = environment.apiBaseUrl;
  //   const resp = await this.httpService.post(
  //     `${baseUrl}/team/quick-start`,
  //     { clusterId }
  //   );
  //   return resp.data;
  // }

  async createPersonalTeam(): Promise<any> {
    const baseUrl = environment.apiBaseUrl;
    const resp = await this.httpService.post(`${baseUrl}/teams/personal`, {});
    await this.invalidateTeams();
    return resp.data;
  }

  async sendTeamInvitations(team: Team, emails: string[]): Promise<any> {
    const reqData = {
      emails,
    };
    const res = await this.httpService.put(
      `${this.baseUrl}/slug/${team.slug}/invites`,
      reqData
    );
    return res;
  }

  async getTeamInvitations(team: Team): Promise<any> {
    const res = await this.httpService.get(
      `${this.baseUrl}/slug/${team.slug}/invites`
    );
    return res.data;
  }

  async cancelTeamInvitation(team: Team, inviteId): Promise<any> {
    const res = await this.httpService.put(
      `${this.baseUrl}/slug/${team.slug}/invites/${inviteId}/cancel`,
      {}
    );
    return res.data;
  }

  async getTeamMembers(team: Team): Promise<UserProfile[] | null> {
    const teamUsers = await this.httpService.get(
      `${this.baseUrl}/slug/${team.slug}/team-members`
    );
    return teamUsers.data;
  }

  async acceptInvitation(inviteId): Promise<any> {
    const res = await this.httpService.put(
      `${environment.apiBaseUrl}/users/invitations/${inviteId}/accept`,
      {}
    );
    return res.data;
  }

  async rejectInvitation(inviteId): Promise<any> {
    const res = await this.httpService.put(
      `${environment.apiBaseUrl}/users/invitations/${inviteId}/reject`,
      {}
    );
    return res.data;
  }

  async getUserInvitations(): Promise<any> {
    const res = await this.httpService.get(
      `${environment.apiBaseUrl}/users/invitations`
    );
    return res.data;
  }

  async updateTeam(team: Team): Promise<any> {
    const baseUrl = environment.apiBaseUrl;
    const res = await this.httpService.patch(`${baseUrl}/teams/update`, team);
    await this.invalidateTeams();
    return res.data;
  }

  async updateTeamMemberRole(user, team, teamUser): Promise<any> {
    const baseUrl = environment.apiBaseUrl;
    const res = await this.httpService.patch(`${baseUrl}/teams/update-role`, {
      user,
      team,
      teamUser,
    });
    return res.data;
  }

  async uploadNewTeamImage(team, file): Promise<any> {
    const baseUrl = environment.apiBaseUrl;
    const formData: FormData = new FormData();
    formData.append('teamImage', file);
    formData.append('team', team);
    await this.httpService.post(`${baseUrl}/teams/upload-image`, formData);
  }

  async getTeamUser(user, team): Promise<any> {
    const baseUrl = environment.apiBaseUrl;
    const res = await this.httpService.patch(`${baseUrl}/teams/team-user`, {
      user,
      team,
    });
    return res.data;
  }

  async deleteTeam(team): Promise<any> {
    const baseUrl = environment.apiBaseUrl;
    const res = await this.httpService.delete(`${baseUrl}/teams/${team.id}`);
    await this.invalidateTeams();
    return res.data;
  }

  async getTeamClusters(team): Promise<any> {
    const baseUrl = environment.apiBaseUrl;
    const res = await this.httpService.get(
      `${baseUrl}/teams/${team.id}/clusters`
    );
    return res.data;
  }
}
