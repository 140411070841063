import { createAction, props } from '@ngrx/store';

import { TeamInvitation } from '../../models/team-invitation';

export const fetchNotifications = createAction(
  '[Notification API] Fetch Notifications Initiated'
);
export const fetchNotificationsSuccess = createAction(
  '[Notification API] Fetch Notifications Success',
  props<{ notifications: TeamInvitation[] }>()
);
export const fetchNotificationsFailed = createAction(
  '[Notification API] Fetch Notifications Failed',
  props<{ error: Error }>()
);

// accept team invite
export const acceptTeamInvite = createAction(
  '[Team] Accept Team Invite',
  props<{ inviteId: string }>()
);
export const acceptTeamInviteSuccess = createAction(
  '[Team] Accept Team Invite Success',
  props<{ inviteId: string }>()
);
export const acceptTeamInviteFailed = createAction(
  '[Team] Accept Team Invite Failed',
  props<{ error: Error }>()
);

// reject team invite
export const rejectTeamInvite = createAction(
  '[Team] Reject Team Invite',
  props<{ inviteId: string }>()
);
export const rejectTeamInviteSuccess = createAction(
  '[Team] Reject Team Invite Success',
  props<{ inviteId: string }>()
);
export const rejectTeamInviteFailed = createAction(
  '[Team] Reject Team Invite Failed',
  props<{ error: Error }>()
);
