// angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, concatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { APIResponse } from './../../api/models/api-response';

// models
import { Space, TeamProductUsage } from '../../models';

// ngrx
import * as SpaceActions from './space.actions';
import * as UserActions from '../user/user.actions';

@Injectable()
export class SpacesEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  // fetch spaces
  fetchSpaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActions.loginSuccess,
        SpaceActions.fetchSpaces,
        SpaceActions.addSpaceSuccess
      ),

      switchMap((action) => {
        return this.httpClient
          .get<APIResponse<Space[]>>(`${environment.apiBaseUrl}/spaces`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return SpaceActions.fetchSpacesFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              const result = { spaces: apiResponse.data };

              if (action.type === UserActions.loginSuccess.type) {
                return SpaceActions.initialFetchSpacesSuccess(result);
              } else {
                return SpaceActions.fetchSpacesSuccess(result);
              }
            }),
            catchError((error) => of(SpaceActions.fetchSpacesFailed({ error })))
          );
      })
    )
  );

  // fetch spaces by team
  fetchSpacesByTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpaceActions.fetchSpacesByTeam),

      switchMap((actionPayload) => {
        const teamSlugOrId = actionPayload.teamSlugOrId;
        return this.httpClient
          .get<APIResponse<Space[]>>(
            `${environment.apiBaseUrl}/spaces/team/${teamSlugOrId}`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return SpaceActions.fetchSpacesByTeamFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return SpaceActions.fetchSpacesByTeamSuccess({
                spaces: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(SpaceActions.fetchSpacesByTeamFailed({ error }))
            )
          );
      })
    )
  );

  // space usage
  fetchSpaceUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpaceActions.fetchSpaceUsage),

      switchMap((action) => {
        if (!action.space) {
          return of(
            SpaceActions.fetchSpaceUsageSuccess({
              space: null,
              teamProductUsage: [],
            })
          );
        }

        return this.httpClient
          .get<APIResponse<TeamProductUsage[]>>(
            `${environment.apiBaseUrl}/spaces/${action.space.id}/usage`
          )
          .pipe(
            map((apiResponse) => {
              return SpaceActions.fetchSpaceUsageSuccess({
                space: action.space,
                teamProductUsage: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(SpaceActions.fetchSpaceUsageFailed({ error }))
            )
          );
      })
    )
  );

  // add space
  addSpace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpaceActions.addSpace),

      concatMap((action) => {
        return this.httpClient
          .post<APIResponse<Space>>(
            `${environment.apiBaseUrl}/spaces`,
            action.space
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return SpaceActions.addSpaceFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return SpaceActions.addSpaceSuccess({ space: apiResponse.data });
            }),
            catchError((error) => {
              return of(SpaceActions.addSpaceFailed({ error }));
            })
          );
      })
    )
  );

  // update space
  updateSpace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpaceActions.updateSpace),
      switchMap((action) => {
        return this.httpClient
          .patch<APIResponse<Space>>(
            `${environment.apiBaseUrl}/spaces/${action.space.id}/name`,
            action.space
          )
          .pipe(
            map((apiResponse) => {
              return SpaceActions.updateSpaceSuccess({
                space: apiResponse.data,
              });
            }),
            catchError((error) => of(SpaceActions.updateSpaceFailed({ error })))
          );
      })
    )
  );

  // remove space
  removeSpace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpaceActions.removeSpace),
      switchMap((action) => {
        return this.httpClient
          .delete<any>(
            `${environment.apiBaseUrl}/spaces/${action.spaceId}/delete`
          )
          .pipe(
            map(() => {
              return SpaceActions.removeSpaceSuccess({
                spaceId: action.spaceId,
              });
            }),
            catchError((error) => of(SpaceActions.removeSpaceFailed({ error })))
          );
      })
    )
  );
}
