import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponse } from '../../api';
import { BillingPaymentMethod } from '../../models/billing-payment-method';
import { BillingTask } from '../../models/billing-task';

import * as InvoiceActions from './invoice.actions';

@Injectable()
export class InvoiceEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  fetchInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.fetchInvoices),

      switchMap((action) => {
        return this.httpClient
          .get<APIResponse<BillingPaymentMethod[]>>(
            `${environment.billingApi}/customers/${action.customer.id}/invoices`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.success) {
                return InvoiceActions.fetchInvoicesFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              const invoices = apiResponse.data;

              return InvoiceActions.fetchInvoicesSuccess({
                invoices,
              });
            }),
            catchError((error) =>
              of(InvoiceActions.fetchInvoicesFailed({ error }))
            )
          );
      })
    )
  );

  payInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoiceActions.payInvoice),
      switchMap((action) => {
        const customerId = action.customer.id;
        const invoiceId = action.invoice.id;
        const url = `${environment.billingApi}/customers/${customerId}/invoices/${invoiceId}/payments`;
        return this.httpClient.post<APIResponse<BillingTask>>(url, {}).pipe(
          map((apiResponse) => {
            if (!apiResponse.success) {
              const error = new Error('Something went wrong.');
              return InvoiceActions.payInvoiceFailed({ error });
            }

            return InvoiceActions.payInvoiceSuccess({
              invoice: action.invoice,
              billingTask: apiResponse.data,
            });
          }),
          catchError((error) => of(InvoiceActions.payInvoiceFailed({ error })))
        );
      })
    )
  );
}
