import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import {
  ConfirmModalComponent,
  ConfirmModalOptions,
} from '../confirm-modal/confirm-modal.component';
import {
  TextConfirmModalComponent,
  TextConfirmModalOptions,
} from '../text-confirm-modal/text-confirm-modal.component';
import {
  InfoModalComponent,
  InfoModalOptions,
} from './../info-modal/info-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private bsModalService: BsModalService) {}

  public show(modal: any, options: ModalOptions = null): BsModalRef {
    if (!options) {
      options = { class: 'modal-lg' };
    }
    return this.bsModalService.show(modal, options);
  }

  public showConfirmModal(options: ConfirmModalOptions): BsModalRef {
    return this.bsModalService.show(
      ConfirmModalComponent,
      this.getModalOptions(options, 'modal-md')
    );
  }

  public showTextConfirmModal(options: TextConfirmModalOptions): BsModalRef {
    return this.bsModalService.show(
      TextConfirmModalComponent,
      this.getModalOptions(options, 'modal-md')
    );
  }

  public showInfoModal(options: InfoModalOptions): BsModalRef {
    return this.bsModalService.show(InfoModalComponent, {
      ...this.getModalOptions(options, 'modal-md'),
      ignoreBackdropClick: true,
    });
  }

  private getModalOptions(
    options,
    modalClassSize: string = 'modal-lg'
  ): ModalOptions {
    const modalOptions: ModalOptions = new ModalOptions();
    modalOptions.initialState = { options } as any;
    if (modalClassSize) {
      modalOptions.class = modalClassSize;
    }
    return modalOptions;
  }
}
