import { BaseState } from '../base.state';
import { CreateNewCapsuleState } from '../../models/create-capsule-state';

export interface CreateCapsuleState extends BaseState {
  createCapsuleState: CreateNewCapsuleState;
}

export const initialState: CreateCapsuleState = {
  createCapsuleState: null,
};
