import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User, appstraxAuth } from '@appstrax/services/auth';
import { Store } from '@ngrx/store';
import { from, switchMap } from 'rxjs';

import { UtilService } from 'src/app/core/services/util.service';
import * as UserSelectors from '../state/user/user.selector';
import * as UserActions from '../state/user/user.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private user$ = this.store.select(UserSelectors.selectCurrentUser);
  private user: User;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilService
  ) {
    this.user$.subscribe((user) => (this.user = user));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return from(appstraxAuth.getAuthToken()).pipe(
      switchMap((token) => {
        if (this.user && !token) {
          this.logout();
          throw new Error('Your token has expired');
        }

        if (!token) {
          return next.handle(request);
        }

        if (request.url.includes('crm.zoho.com/crm/WebToLeadForm')) {
          return next.handle(request);
        }

        const newRequest = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token),
        });

        return next.handle(newRequest);
      })
    );
  }

  private async logout() {
    await this.utils.actionsToPromise(
      UserActions.logout(),
      UserActions.logoutSuccess,
      UserActions.logoutFailed
    );

    const url = this.router.routerState.snapshot.url;
    const queryParams = this.route.snapshot.queryParams;

    let redirectUrl = url;
    let params = null;
    if (url.includes('?')) {
      redirectUrl = url.substring(0, url.indexOf('?'));
      params = JSON.stringify(queryParams);
    }

    this.router.navigate(['/auth/login'], {
      queryParams: { redirectUrl, params },
    });
  }
}
