import { createFeatureSelector, createSelector } from '@ngrx/store';
import moment from 'moment';

import { UtilService } from '../../services/util.service';
import { UserState } from './user.state';
import { CustomerUsage } from '../../models/customer-usage';
import { ClusterProductUsage } from '../../models/cluster-product-usage';
import { TeamProductUsage } from '../../models/team-product-usage';

const formatError = (err) => {
  if (
    err &&
    err?.error?.data?.message ===
      'Invalid Billing Customer, please ensure billing details submitted.'
  ) {
    return 'Invalid Billing Customer, please ensure billing details submitted.';
  }

  if (!err) return '';
  return UtilService.getErrorMessage(err);
};

export const selectUser = createFeatureSelector<UserState>('user');

export const selectCurrentUser = createSelector(
  selectUser,
  (state: UserState) => state.currentUser
);

export const selectLoading = createSelector(
  selectUser,
  (state: UserState) => state.loading
);

export const selectError = createSelector(selectUser, (state: UserState) =>
  formatError(state.error)
);

export const selectIsLoggedIn = createSelector(
  selectCurrentUser,
  (currentUser) => !!currentUser
);

export const selectPasswordResetInitiated = createSelector(
  selectUser,
  (state: UserState) => !!state.passwordResetInitiated
);

export const selectPasswordResetCompleted = createSelector(
  selectUser,
  (state: UserState) => !!state.passwordResetCompleted
);

export const selectCustomerLoadedAt = createSelector(
  selectUser,
  (state: UserState) => state.customerLoadedAt
);

export const selectCustomer = createSelector(
  selectUser,
  (state: UserState) => state.customer
);

export const selectCustomerLoading = createSelector(
  selectUser,
  (state: UserState) => state.customerLoading
);

export const selectCustomerUsage = createSelector(
  selectUser,
  (state: UserState) => state.customerUsage
);

export const selectCustomerTeamsUsageByBillingPeriod = createSelector(
  selectUser,
  (state: UserState) => state.customerTeamUsageByBillingPeriod
);

export const selectCustomerClustersUsageByBillingPeriod = createSelector(
  selectUser,
  (state: UserState) => state.customerClusterUsageByBillingPeriod
);

export const selectCustomerCurrentUsagePrice = createSelector(
  selectCustomerUsage,
  (customerUsage?: CustomerUsage) => {
    if (!customerUsage) return 0;

    const billingPeriodMoment = moment().utc().endOf('M');
    const billingPeriod = billingPeriodMoment.format('YYYY-MM');

    let price = 0;
    customerUsage.clusters.forEach(
      (clusterProductUsage: ClusterProductUsage) => {
        if (clusterProductUsage.billingPeriod !== billingPeriod) return;

        price = price + Number(clusterProductUsage.price || 0);
      }
    );

    customerUsage.teams.forEach((teamProductUsage: TeamProductUsage) => {
      if (teamProductUsage.billingPeriod !== billingPeriod) return;

      price = price + Number(teamProductUsage.price || 0);
    });

    return price;
  }
);

export const selectCustomerCredit = createSelector(
  selectUser,
  (state: UserState) => state.credit
);
