import { Actions, createEffect, ofType } from '@ngrx/effects';

// angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { of } from 'rxjs';
import { switchMap, map, catchError, concatMap, tap } from 'rxjs/operators';

// ngrx
import * as SpaceActions from '../space/space.actions';
import * as TeamActions from './team.actions';
import * as UserActions from '../user/user.actions';

import { AlertService } from '../../../core/services/alert.service';
import { environment } from '../../../../environments/environment';
import { Team, TeamProductUsage } from '../../models';
import { APIResponse } from '../../api';

@Injectable()
export class TeamsEffects {
  constructor(
    private alert: AlertService,
    private actions$: Actions<any>,
    private httpClient: HttpClient
  ) {}

  fetchTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TeamActions.fetchAllTeams,
        UserActions.loginSuccess,
        SpaceActions.addSpaceSuccess
      ),

      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Team[]>>(`${environment.apiBaseUrl}/teams`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return TeamActions.fetchAllTeamsFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return TeamActions.fetchAllTeamsSuccess({
                teams: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(TeamActions.fetchAllTeamsFailed({ error }))
            )
          );
      })
    )
  );

  addTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.addTeamSubmitted),

      concatMap((action) => {
        return this.httpClient
          .post<APIResponse<Team>>(
            `${environment.apiBaseUrl}/teams`,
            action.team
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return TeamActions.addTeamFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              const team = apiResponse.data;
              return TeamActions.addTeamSuccess({ team });
            }),
            catchError((error) => of(TeamActions.addTeamFailed({ error })))
          );
      })
    )
  );

  removeTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.removeTeam),
      switchMap((action) => {
        return this.httpClient
          .delete<any>(`${environment.apiBaseUrl}/teams/${action.teamId}`)
          .pipe(
            map((apiResponse) => {
              return TeamActions.removeTeamSuccess({ team: apiResponse.data });
            }),
            catchError((error) => of(TeamActions.removeTeamFailed({ error })))
          );
      })
    )
  );

  updateTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.updateTeam),
      switchMap((action) => {
        return this.httpClient
          .patch<APIResponse<Team>>(
            `${environment.apiBaseUrl}/teams/update`,
            action.team
          )
          .pipe(
            map((apiResponse) => {
              return TeamActions.updateTeamSuccess({ team: apiResponse.data });
            }),
            catchError((error) => of(TeamActions.updateTeamFailed({ error })))
          );
      })
    )
  );

  uploadTeamImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.updateTeamImage),
      switchMap((action) => {
        const formData: FormData = new FormData();
        formData.append('teamImage', action.file);
        formData.append('team', action.teamId);

        return this.httpClient
          .post<APIResponse<Team>>(
            `${environment.apiBaseUrl}/teams/upload-image`,
            formData
          )
          .pipe(
            map((apiResponse) => {
              return TeamActions.updateTeamImageSuccess({
                team: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(TeamActions.updateTeamImageFailed({ error }))
            )
          );
      })
    )
  );

  removeTeamImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.removeTeamImage),
      switchMap((action) => {
        return this.httpClient
          .delete<any>(
            `${environment.apiBaseUrl}/teams/remove-image/${action.teamId}`
          )
          .pipe(
            map((apiResponse) => {
              return TeamActions.removeTeamImageSuccess({
                team: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(TeamActions.removeTeamImageFailed({ error }))
            )
          );
      })
    )
  );

  fetchTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.fetchTeam),
      switchMap((action) => {
        return this.httpClient
          .get<APIResponse<Team>>(
            `${environment.apiBaseUrl}/teams/${action.teamIdOrSlug}`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return TeamActions.fetchTeamFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              const team = apiResponse.data;
              return TeamActions.fetchTeamSuccess({ team });
            }),
            catchError((error) => of(TeamActions.fetchTeamFailed({ error })))
          );
      })
    )
  );

  fetchTeamUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.fetchTeamUsage),

      switchMap((action) => {
        const team = action.team;
        const billingPeriod = action.billingPeriod;
        if (!team) {
          return of(
            TeamActions.fetchTeamUsageSuccess({
              team: null,
              teamProductUsage: [],
            })
          );
        }

        let query = '';
        if (billingPeriod) {
          query = `?billing_period=${billingPeriod}`;
        }

        return this.httpClient
          .get<APIResponse<TeamProductUsage[]>>(
            `${environment.apiBaseUrl}/teams/${team.id}/usage${query}`
          )
          .pipe(
            map((apiResponse) => {
              return TeamActions.fetchTeamUsageSuccess({
                team,
                teamProductUsage: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(TeamActions.fetchTeamUsageFailed({ error }))
            )
          );
      })
    )
  );

  removeTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.removeTeamMember),
      switchMap((action) => {
        return this.httpClient
          .post<any>(`${environment.apiBaseUrl}/teams/remove-member`, {
            userId: action.member.id,
            teamId: action.team.id,
          })
          .pipe(
            map(() => TeamActions.removeTeamMemberSuccess(action)),
            catchError((error) =>
              of(TeamActions.removeTeamMemberFailed({ error }))
            )
          );
      })
    )
  );

  fetchAllTeamInvites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.fetchAllTeamsSuccess, TeamActions.fetchAllTeamInvites),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<any>[]>(`${environment.apiBaseUrl}/teams/invites`)
          .pipe(
            map((apiResponse: any) => {
              return TeamActions.fetchAllTeamInvitesSuccess({
                invites: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(TeamActions.fetchAllTeamInvitesFailed({ error }))
            )
          );
      })
    )
  );

  fetchTeamInvites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.fetchTeamInvites),
      switchMap((action) => {
        const endpoint = `${environment.apiBaseUrl}/teams/${action.team.slug}/invites`;
        return this.httpClient.get<APIResponse<any>[]>(endpoint).pipe(
          map((apiResponse: any) => {
            return TeamActions.fetchTeamInvitesSuccess({
              invites: apiResponse.data,
              team: action.team,
            });
          }),
          catchError((error) =>
            of(TeamActions.fetchTeamInvitesFailed({ error }))
          )
        );
      })
    )
  );

  sendTeamMemberInvites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.sendTeamInvites),

      concatMap((action) => {
        const url = `${environment.apiBaseUrl}/teams/${action.teamId}/invites`;
        const payload = { emails: action.emailAddresses };
        return this.httpClient.post<APIResponse<string[]>>(url, payload).pipe(
          map((apiResponse: any) => {
            return TeamActions.sendTeamInvitesSuccess({
              invites: apiResponse.data,
              teamId: action.teamId,
            });
          }),
          catchError((error) =>
            of(TeamActions.sendTeamInvitesFailed({ error }))
          )
        );
      })
    )
  );

  cancelTeamInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.cancelTeamInvite),
      switchMap((action) => {
        const teamId = action.teamId;
        const inviteId = action.inviteId;
        const url = `${environment.apiBaseUrl}/teams/${teamId}/invites/${inviteId}/cancel`;
        return this.httpClient.post(url, {}).pipe(
          map(() => {
            return TeamActions.cancelTeamInviteSuccess({ inviteId, teamId });
          }),
          catchError((error) =>
            of(TeamActions.cancelTeamInviteFailed({ error }))
          )
        );
      })
    )
  );

  updateTeamOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.updateTeamOwner),

      concatMap((action) => {
        return this.httpClient
          .post<APIResponse<string[]>>(
            `${environment.apiBaseUrl}/teams/${action.teamId}/team-owner`,
            {
              email: action.emailAddress,
            }
          )
          .pipe(
            map((apiResponse: any) => {
              return TeamActions.updateTeamOwnerSuccess({
                invites: apiResponse.data,
                teamId: action.teamId,
              });
            }),
            catchError((error) =>
              of(TeamActions.updateTeamOwnerFailed({ error }))
            )
          );
      })
    )
  );

  fetchTeamPaymentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.fetchTeamPaymentStatus),
      switchMap((actionPayload) => {
        const teamId = actionPayload.teamId;

        return this.httpClient
          .get<any>(`${environment.apiBaseUrl}/teams/${teamId}/payment-status`)
          .pipe(
            map((apiResponse) => {
              return TeamActions.fetchTeamPaymentStatusSuccess({
                teamPaymentStatus: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(TeamActions.fetchTeamPaymentStatusFailed({ error }))
            )
          );
      })
    )
  );

  showFailAlerts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          TeamActions.fetchAllTeamInvitesFailed,
          TeamActions.fetchAllTeamsFailed,
          TeamActions.fetchTeamFailed,
          TeamActions.fetchTeamUsageFailed
        ),
        tap((action) => {
          this.alert.handle(action.error);
        })
      ),
    { dispatch: false }
  );
}
