import { User } from '@appstrax/services/auth';
import { BillingCustomer } from '../../models/billing-customer';
import { ClusterProductUsage } from '../../models/cluster-product-usage';
import { CustomerUsage } from '../../models/customer-usage';
import { TeamProductUsage } from '../../models/team-product-usage';
import { BaseState } from '../base.state';

export class UserState extends BaseState {
  currentUser?: User;

  error?: any;

  pending2FA: boolean;

  passwordResetInitiated: boolean;
  passwordResetCompleted: boolean;

  subscribeToNewsletter: boolean;

  customerLoading: boolean;
  customerLoadedAt?: Date;
  customerSavedAt?: Date;
  customer?: BillingCustomer;

  customerUsage?: CustomerUsage;

  customerTeamUsageByBillingPeriod?: {
    [billingPeriod: string]: TeamProductUsage[];
  };
  customerClusterUsageByBillingPeriod?: {
    [billingPeriod: string]: ClusterProductUsage[];
  };

  approved?: boolean;

  credit: number;
  canScaleCapsulesWithoutCredit: boolean;
  capsuleScaleWithoutCreditLimit: number;
}

export const initialState: UserState = {
  currentUser: null,
  pending2FA: false,
  error: null,
  passwordResetInitiated: false,
  passwordResetCompleted: false,
  subscribeToNewsletter: false,
  customerLoading: false,
  customerLoadedAt: null,
  customerSavedAt: null,
  customer: null,
  customerUsage: null,
  customerTeamUsageByBillingPeriod: null,
  customerClusterUsageByBillingPeriod: null,
  approved: true,
  credit: 0,
  canScaleCapsulesWithoutCredit: false,
  capsuleScaleWithoutCreditLimit: 20,
};
