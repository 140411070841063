export class MarketplaceCategory {
  key: string;
  name: string;
  selected: boolean;

  constructor(key: string, name: string) {
    this.key = key;
    this.name = name;
    this.selected = false;
  }
}
