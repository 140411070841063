import { LandingPageState } from './landing-page.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectLanding =
  createFeatureSelector<LandingPageState>('landingPage');

export const selectLandingPage = createSelector(
  selectLanding,
  (state) => state.landingPage
);

export const selectLandingPageSnippets = createSelector(
  selectLanding,
  (state) => state.landingPageSnippets
);
