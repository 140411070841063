<div class="background">
  <div class="color-opacity-overlay">
    <div class="container">
      <div class="row mt-5">
        <div class="col-md-6 p-5">
          <h1 class="text-white" style="font-size: 8vw">4Oh4!</h1>
          <h2 class="text-white">
            That page hasn't been around these parts in years.
          </h2>
          <h3 class="my-4 text-white">Error code: 404</h3>
          <div>
            <a href="/" class="btn btn-primary" type="button">Take me home</a>
          </div>
        </div>
        <div
          class="col-md-6 p-5 d-flex justify-content-center align-items-center">
          <i
            style="font-size: 15vw"
            class="text-white fas fa-5x fa-cloud-moon-rain"></i>
        </div>
      </div>
    </div>
  </div>
</div>
