import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Plan } from '../../models';

import { PlanState } from './plan.state';

export const selectPlans = createFeatureSelector<PlanState>('plan');

export const selectLoading = createSelector(
  selectPlans,
  (state) => state.loading
);

export const selectError = createSelector(selectPlans, (state) => state.error);

export const selectAllPlans = createSelector(selectPlans, (state) => state.plans);

export const selectPlansByCapsuleType = (props: { capsuleType: string }) =>
  createSelector(selectAllPlans, (plans: Array<Plan>) =>
    plans
      .filter((plan) => plan.capsuleType === props.capsuleType)
      .sort((a, b) => a.sortKey - b.sortKey)
  );

export const selectPlansByClusterAndCapsuleType = (props: {
  clusterId: string;
  capsuleType: string;
}) =>
  createSelector(selectAllPlans, (plans: Array<Plan>) =>
    plans.filter((plan) =>
      plan.clusterId === props.clusterId &&
      plan.capsuleType === props.capsuleType
    ).sort((a, b) => a.sortKey - b.sortKey)
  );
