import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AgencyState } from './agency.state';

export const selectAgencyState = createFeatureSelector<AgencyState>('agency');

export const selectAgency = createSelector(
  selectAgencyState,
  (state: AgencyState) => state.agency
);

export const selectApprovedAgency = createSelector(
  selectAgencyState,
  (state: AgencyState) =>
    state.agency?.status == 'approved' ? state.agency : null
);
