// angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

// ngrx
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  fetchRepos,
  fetchReposSuccess,
  fetchReposFailed,
  fetchTeamRepos,
  fetchTeamReposSuccess,
  fetchTeamReposFailed,
  addTeamRepo,
  addTeamRepoSuccess,
  addTeamRepoFailed,
  removeTeamRepo,
  removeTeamRepoSuccess,
  removeTeamRepoFailed,
} from './repo.actions';
import { loginSuccess } from '../user/user.actions';

import { APIResponse } from '../../api/models/api-response';
import { environment } from '../../../../environments/environment';
import { Repo } from '../../models/repo';

@Injectable()
export class ReposEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  fetchRepos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess, fetchRepos),
      switchMap(() => {
        const url = `${environment.apiBaseUrl}/git-repo`;
        return this.httpClient.get<APIResponse<Repo[]>>(url).pipe(
          map((response) => fetchReposSuccess({ repos: response.data })),
          catchError((error) => of(fetchReposFailed({ error })))
        );
      })
    )
  );

  fetchTeamRepos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTeamRepos),
      switchMap(({ team }) => {
        const url = `${environment.apiBaseUrl}/github-team-repo/${team.id}`;
        return this.httpClient.get<APIResponse<Repo[]>>(url).pipe(
          map((res) => fetchTeamReposSuccess({ repos: res.data, team })),
          catchError((error) => of(fetchTeamReposFailed({ error })))
        );
      })
    )
  );

  addTeamRepo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTeamRepo),
      switchMap(({ team, repo }) => {
        const url = `${environment.apiBaseUrl}/github-team-repo`;
        const body = { teamId: team.id, repoId: repo.id };
        return this.httpClient.post<APIResponse<Repo>>(url, body).pipe(
          map(() => addTeamRepoSuccess({ repo, team })),
          catchError((error) => of(addTeamRepoFailed({ error })))
        );
      })
    )
  );

  removeTeamRepo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTeamRepo),
      switchMap(({ team, repo }) => {
        const url = `${environment.apiBaseUrl}/github-team-repo/${team.id}/${repo.id}`;
        return this.httpClient.delete<APIResponse<void>>(url).pipe(
          map(() => removeTeamRepoSuccess({ repo, team })),
          catchError((error) => of(removeTeamRepoFailed({ error })))
        );
      })
    )
  );
}
