import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appstraxAuth } from '@appstrax/services/auth';

import { AlertService } from 'src/app/core/services/alert.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
  constructor(
    private alert: AlertService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;

    if (!this.storageService.getHasAuthenticated()) {
      this.router.navigate(['auth/registration'], { queryParams });
      return;
    }

    try {
      const authenticated = await appstraxAuth.isAuthenticated();
      if (authenticated) {
        this.navToDash();
      } else {
        this.router.navigate(['auth/login'], { queryParams });
      }
    } catch (err) {
      this.alert.handle(err);
      this.router.navigate(['auth/login'], { queryParams });
    }
  }

  private navToDash() {
    const redirectUrl = this.route.snapshot.queryParams['redirectUrl'];
    const routeParams = this.route.snapshot.queryParams['params'];
    const queryParams = routeParams ? JSON.parse(routeParams) : null;

    if (redirectUrl) {
      this.router.navigate([redirectUrl], { queryParams });
    } else {
      this.router.navigate(['dash']);
    }
  }
}
