import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, from, map, mergeMap, of, switchMap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { APIResponse } from '../../api';
import * as AdminActions from '../admin/admin.actions';
import {
  UserProfile,
  Team,
  Space,
  Capsule,
  Cluster,
} from 'src/app/core/models';
import { PhoneNumber } from './../../models/phone-number';
import { environment } from 'src/environments/environment';
import { BillingCustomer } from '../../models/billing-customer';
import { BillingPaymentMethod } from 'src/app/core/models/billing-payment-method';
import { Invoice } from '../../models/invoice';
import { BillingLine } from '../../models/billing-line';
import { BillingTask } from '../../models/billing-task';

@Injectable()
export class AdminEffects {
  constructor(private actions$: Actions, private httpClient: HttpClient) {}

  fetchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchUsers),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<UserProfile[]>>(
            `${environment.apiBaseUrl}/users/all-users`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchUsersFailed({
                  error: new Error('Something went wrong.'),
                });
              }
              const users = apiResponse.data.sort(
                (a, b) =>
                  Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
              );
              return AdminActions.fetchUsersSuccess({
                users, //: apiResponse.data,
              });
            }),
            catchError((error) => of(AdminActions.fetchUsersFailed({ error })))
          );
      })
    )
  );

  fetchBillingCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchBillingCustomers, AdminActions.fetchUsers),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<UserProfile[]>>(
            `${environment.billingApi}/customers?limit=1000`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchBillingCustomersFailed({
                  error: new Error('Something went wrong.'),
                });
              }
              return AdminActions.fetchBillingCustomersSuccess({
                billingCustomers: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchBillingCustomersFailed({ error }))
            )
          );
      })
    )
  );

  fetchUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminActions.fetchUsage,
        AdminActions.fetchBillingCustomersSuccess
      ),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<PhoneNumber[]>>(
            `${environment.apiBaseUrl}/admin/usage`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchUsageFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchUsageSuccess({
                usage: apiResponse.data,
              });
            }),
            catchError((error) => of(AdminActions.fetchUsageFailed({ error })))
          );
      })
    )
  );

  fetchCustomerUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchCustomerUsage),
      switchMap((payload) => {
        return this.httpClient
          .get<APIResponse<PhoneNumber[]>>(
            `${environment.apiBaseUrl}/admin/users/${payload.userId}/usage`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchCustomerUsageFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchCustomerUsageSuccess({
                userId: payload.userId,
                usage: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchCustomerUsageFailed({ error }))
            )
          );
      })
    )
  );

  fetchInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchInvoices, AdminActions.fetchUsersSuccess),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Invoice[]>>(
            `${environment.apiBaseUrl}/admin/invoices`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchInvoicesFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchInvoicesSuccess({
                invoices: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchInvoicesFailed({ error }))
            )
          );
      })
    )
  );

  fetchBillingLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchInvoicesSuccess, AdminActions.fetchBillingLines),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<BillingLine[]>>(
            `${environment.billingApi}/billing-lines`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchBillingLinesFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchBillingLinesSuccess({
                billingLines: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchBillingLinesFailed({ error }))
            )
          );
      })
    )
  );

  fetchBillingInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminActions.fetchInvoicesSuccess,
        AdminActions.fetchBillingInvoices
      ),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<BillingLine[]>>(`${environment.billingApi}/invoices`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchBillingInvoicesFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchBillingInvoicesSuccess({
                billingInvoices: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchBillingInvoicesFailed({ error }))
            )
          );
      })
    )
  );

  fetchBillingTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchBillingTasks),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<BillingTask[]>>(
            `${environment.billingApi}/tasks?limit=1000000`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchBillingTasksFailed({
                  error: new Error('Something went wrong.'),
                });
              }
              return AdminActions.fetchBillingTasksSuccess({
                billingTasks: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchBillingTasksFailed({ error }))
            )
          );
      })
    )
  );

  fetchBillingInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AdminActions.fetchBillingInvoice,
        AdminActions.setCurrentBillingInvoice
      ),
      switchMap((props) => {
        if (!props.invoiceId) {
          return of(
            AdminActions.fetchBillingInvoiceSuccess({
              billingInvoice: null,
            })
          );
        }
        return this.httpClient
          .get<APIResponse<BillingLine[]>>(
            `${environment.billingApi}/invoices/${props.invoiceId}`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchBillingInvoiceFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchBillingInvoiceSuccess({
                billingInvoice: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchBillingInvoiceFailed({ error }))
            )
          );
      })
    )
  );

  fetchTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchTeams, AdminActions.fetchUsersSuccess),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Team[]>>(`${environment.apiBaseUrl}/admin/teams`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchTeamsFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchTeamsSuccess({
                teams: apiResponse.data,
              });
            }),
            catchError((error) => of(AdminActions.fetchTeamsFailed({ error })))
          );
      })
    )
  );

  fetchSpaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchSpaces, AdminActions.fetchUsersSuccess),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Team[]>>(`${environment.apiBaseUrl}/admin/spaces`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchSpacesFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchSpacesSuccess({
                spaces: apiResponse.data,
              });
            }),
            catchError((error) => of(AdminActions.fetchSpacesFailed({ error })))
          );
      })
    )
  );

  fetchCapsules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchCapsules, AdminActions.fetchUsersSuccess),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Capsule[]>>(
            `${environment.apiBaseUrl}/admin/capsules`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchCapsulesFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchCapsulesSuccess({
                capsules: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchCapsulesFailed({ error }))
            )
          );
      })
    )
  );

  fetchProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchProducts),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Invoice[]>>(`${environment.billingApi}/products`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchProductsFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchProductsSuccess({
                products: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchProductsFailed({ error }))
            )
          );
      })
    )
  );

  fetchPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchPlans),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Invoice[]>>(`${environment.apiBaseUrl}/admin/plans`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchPlansFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchPlansSuccess({
                plans: apiResponse.data,
              });
            }),
            catchError((error) => of(AdminActions.fetchPlansFailed({ error })))
          );
      })
    )
  );

  fetchPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchPhoneNumbers, AdminActions.fetchUsersSuccess),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<PhoneNumber[]>>(
            `${environment.apiBaseUrl}/admin/phone-numbers`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchPhoneNumbersFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              return AdminActions.fetchPhoneNumbersSuccess({
                phoneNumbers: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchPhoneNumbersFailed({ error }))
            )
          );
      })
    )
  );

  fetchClusters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.fetchClusters, AdminActions.fetchUsers),
      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Cluster[]>>(
            `${environment.apiBaseUrl}/admin/clusters`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return AdminActions.fetchClustersFailed({
                  error: new Error('Something went wrong.'),
                });
              }
              const clusters = apiResponse.data;
              return AdminActions.fetchClustersSuccess({
                clusters,
              });
            }),
            catchError((error) =>
              of(AdminActions.fetchClustersFailed({ error }))
            )
          );
      })
    )
  );

  // TODO: Maybe have 1 api endpoint that fetches all teams in one go
  // fetchUsersTeams$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AdminActions.fetchUsersSuccess),

  //     switchMap((action: Partial<{ users: UserProfile[] }>) => {
  //       return from(action.users);
  //     }),

  //     mergeMap((user: UserProfile) => {
  //       return this.httpClient
  //         .post<APIResponse<Team[]>>(
  //           `${environment.apiBaseUrl}/teams/user-teams`,
  //           { userId: user.id }
  //         )
  //         .pipe(
  //           map((apiResponse) => {
  //             if (!apiResponse.data) {
  //               return AdminActions.fetchUserTeamsFailed({
  //                 error: new Error('Something went wrong.')
  //               });
  //             }

  //             return AdminActions.fetchUserTeamsSuccess({
  //               userTeams: apiResponse.data,
  //             });
  //           }),
  //           catchError((error) =>
  //             of(AdminActions.fetchUserTeamsFailed({ error }))
  //           )
  //         );
  //     })
  //   )
  // );

  // fetchAuthUser$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AdminActions.fetchAuthUser),
  //     switchMap((payload) =>
  //       from(payload.userId).pipe(
  //         map(() => {
  //           return AdminActions.fetchAuthUserSuccess({
  //             userId: payload.userId,
  //           });
  //         }),
  //         catchError((error) => of(AdminActions.fetchUsersFailed({ error })))
  //       )
  //     )
  //   )
  // );

  // fetchUser$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AdminActions.fetchUser),
  //     switchMap((payload) =>
  //       from(users.find({ id: payload.userId })).pipe(
  //         map((users) => {
  //           return AdminActions.fetchUserSuccess({ users });
  //         }),
  //         catchError((error) => of(AdminActions.fetchUsersFailed({ error })))
  //       )
  //     )
  //   )
  // );

  // fetchUserTeams$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AdminActions.fetchUserTeams),
  //     switchMap((payload) => {
  //       return this.httpClient
  //         .post<APIResponse<Team[]>>(
  //           `${environment.apiBaseUrl}/teams/user-teams`,
  //           { userId: payload.userId }
  //         )
  //         .pipe(
  //           map((apiResponse) => {
  //             if (!apiResponse.data) {
  //               return AdminActions.fetchUserTeamsFailed({
  //                 error: new Error('Something went wrong.')
  //               });
  //             }

  //             return AdminActions.fetchUserTeamsSuccess({
  //               userTeams: apiResponse.data,
  //             });
  //           }),
  //           catchError((error) =>
  //             of(AdminActions.fetchUserTeamsFailed({ error }))
  //           )
  //         );
  //     })
  //   )
  // );

  // fetchUserClusters$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AdminActions.fetchUserClusters),
  //     switchMap((payload) => {
  //       return this.httpClient
  //         .post<APIResponse<Team[]>>(
  //           `${environment.apiBaseUrl}/clusters/user-clusters`,
  //           { userId: payload.userId }
  //         )
  //         .pipe(
  //           map((apiResponse) => {
  //             if (!apiResponse.data) {
  //               return AdminActions.fetchUserClustersFailed({
  //                 error: new Error('Something went wrong.')
  //               });
  //             }

  //             return AdminActions.fetchUserClustersSuccess({
  //               clusters: apiResponse.data,
  //             });
  //           }),
  //           catchError((error) =>
  //             of(AdminActions.fetchUserClustersFailed({ error }))
  //           )
  //         );
  //     })
  //   )
  // );

  // fetchUserCustomer$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AdminActions.fetchUserCustomer),

  //     switchMap((payload) => {
  //       return this.httpClient
  //         .post<APIResponse<BillingCustomer>>(
  //           `${environment.apiBaseUrl}/users/customer`,
  //           { userId: payload.userId }
  //         )
  //         .pipe(
  //           map((apiResponse) => {
  //             return AdminActions.fetchUserCustomerSuccess({
  //               customer: apiResponse.data,
  //             });
  //           }),
  //           catchError((error) =>
  //             of(AdminActions.fetchUserCustomerFailed({ error }))
  //           )
  //         );
  //     })
  //   )
  // );

  // fetchCustomerPaymentMethods$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AdminActions.fetchCustomerPaymentMethods),

  //     switchMap((payload) => {
  //       return this.httpClient
  //         .post<APIResponse<BillingPaymentMethod>>(
  //           `${environment.apiBaseUrl}/users/customer/payment-methods`,
  //           { customerId: payload.customerId }
  //         )
  //         .pipe(
  //           map((apiResponse) => {
  //             return AdminActions.fetchCustomerPaymentMethodsSuccess({
  //               paymentMethods: apiResponse.data,
  //             });
  //           }),
  //           catchError((error) =>
  //             of(AdminActions.fetchCustomerPaymentMethodsFailed({ error }))
  //           )
  //         );
  //     })
  //   )
  // );
}
