import { Action, createReducer, on } from '@ngrx/store';
import { AgencyState, initialState } from './agency.state';

import * as AgencyActions from './agency.actions';

const agenciesReducer = createReducer(
  initialState,

  on(
    AgencyActions.fetchAgency,
    AgencyActions.saveAgency,
    AgencyActions.updateAgencyImage,
    AgencyActions.addTeamAgency,
    AgencyActions.removeTeamAgency,
    (state) => ({
      ...state,
      loading: true,
    })
  ),

  on(
    AgencyActions.fetchAgencyFailed,
    AgencyActions.saveAgencyFailed,
    AgencyActions.updateAgencyImageFailed,
    AgencyActions.addTeamAgencyFailed,
    AgencyActions.removeTeamAgencyFailed,
    (state) => ({
      ...state,
      loading: false,
    })
  ),

  on(
    AgencyActions.fetchAgencySuccess,
    AgencyActions.saveAgencySuccess,
    AgencyActions.updateAgencyImageSuccess,
    AgencyActions.addTeamAgencySuccess,
    AgencyActions.removeTeamAgencySuccess,
    (state, { agency }) => ({
      ...state,
      agency,
      loading: false,
      loadedAt: new Date(),
    })
  )
);

export function reducer(state: AgencyState | undefined, action: Action) {
  return agenciesReducer(state, action);
}
