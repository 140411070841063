import { createAction, props } from '@ngrx/store';
import { Plan } from '../../models/plan';

function action(name: string): string {
  return '[Plan] ' + name;
}

export const setError = createAction(
  action('Set Error'),
  props<{ error: unknown }>()
);

// fetch plans
export const fetchPlans = createAction(action('Fetch Plans'));
export const fetchPlansSuccess = createAction(
  action('Fetch Plans Success'),
  props<{ plans: Plan[] }>()
);
export const fetchPlansFailed = createAction(
  action('Fetch Plans Failed'),
  props<{ error: unknown }>()
);
