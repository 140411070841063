import { BaseState } from '../base.state';
import { Region } from '../../models/region';

export interface RegionState extends BaseState {
  regions: Region[];
}

export const initialState: RegionState = {
  regions: [],
};
