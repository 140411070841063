import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import {
  RecaptchaAction,
  ReCaptchaService,
} from '../../services/recaptcha.service';

import * as RecaptchaActions from './recaptcha.actions';
import * as UserActions from '../user/user.actions';
import * as TeamActions from '../team/team.actions';
import * as SpaceActions from '../space/space.actions';
import * as CapsuleActions from '../capsule/capsule.actions';

@Injectable()
export class RecaptchaEffects {
  constructor(
    private actions$: Actions,
    private recaptchaService: ReCaptchaService
  ) {}

  loginRecaptcha$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess),
      switchMap(() =>
        from(this.recaptchaService.execute(RecaptchaAction.login)).pipe(
          map((res) => RecaptchaActions.recaptchaSuccess(res)),
          catchError((error) => of(RecaptchaActions.recaptchaFailed({ error })))
        )
      )
    )
  );

  registerRecaptcha$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.registerSuccess),
      switchMap(() =>
        from(this.recaptchaService.execute(RecaptchaAction.register)).pipe(
          map((res) => RecaptchaActions.recaptchaSuccess(res)),
          catchError((error) => of(RecaptchaActions.recaptchaFailed({ error })))
        )
      )
    )
  );

  createTeamRecaptcha$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamActions.addTeamSuccess),
      switchMap(() =>
        from(this.recaptchaService.execute(RecaptchaAction.createTeam)).pipe(
          map((res) => RecaptchaActions.recaptchaSuccess(res)),
          catchError((error) => of(RecaptchaActions.recaptchaFailed({ error })))
        )
      )
    )
  );

  createSpaceRecaptcha$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpaceActions.addSpaceSuccess),
      switchMap(() =>
        from(this.recaptchaService.execute(RecaptchaAction.createSpace)).pipe(
          map((res) => RecaptchaActions.recaptchaSuccess(res)),
          catchError((error) => of(RecaptchaActions.recaptchaFailed({ error })))
        )
      )
    )
  );

  createCapsuleRecaptcha$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.capsuleCreateSuccess),
      switchMap(() =>
        from(this.recaptchaService.execute(RecaptchaAction.createCapsule)).pipe(
          map((res) => RecaptchaActions.recaptchaSuccess(res)),
          catchError((error) => of(RecaptchaActions.recaptchaFailed({ error })))
        )
      )
    )
  );
}
