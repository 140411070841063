import { createAction, props } from '@ngrx/store';
import {Cloud} from '../../models/cloud';

// Fetch Cloud Action
export const fetchClouds = createAction(
  '[Cloud API] Fetch Clouds Initiated'
);

export const fetchCloudsSuccess = createAction(
  '[Cloud API] Fetch Clouds Success',
  props<{ clouds: Array<Cloud> }>()
);

export const fetchCloudsFailed = createAction(
  '[Cloud API] Fetch Clouds Failed',
  props<{ error: Error }>()
);

export const setCurrentCloud = createAction(
  '[Cloud] Set Current Cloud',
  props<{ cloud: Cloud }>()
);
