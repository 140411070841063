import { Action, createReducer, on } from '@ngrx/store';

import * as Actions from './recaptcha.actions';
import { initialState, RecaptchaState } from './recaptcha.state';

const recaptchaReducer = createReducer(
  initialState,

  on(
    Actions.recaptchaLogin,
    Actions.recaptchaRegister,
    Actions.recaptchaCreateTeam,
    Actions.recaptchaCreateSpace,
    Actions.recaptchaCreateCapsule,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(Actions.recaptchaSuccess, (state, { action, score }) => ({
    ...state,
    loadedAt: new Date(),
    loading: false,
    score,
    error: null,
  })),
  on(Actions.recaptchaFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

export function reducer(state: RecaptchaState | undefined, action: Action) {
  return recaptchaReducer(state, action);
}
