import { Injectable } from '@angular/core';

import { HttpService } from '../http.service';
import { Capsule, Domain, Space } from '../../models';

export enum DataCapsuleType {
  mysql = 'mysql',
  redis = 'Redis',
  mongo = 'MongoDb',
  postgres = 'PostgreSQL',
  persistentStorage = 'PersistentStorageGanesha',
}

@Injectable({ providedIn: 'root' })
export class DataCapsuleService {
  constructor(private httpService: HttpService) {}

  public getPrivateHost(capsule: Capsule): string {
    return capsule?.jsonManifest?.privateHostname ?? '';
  }
  public getPublicHost(domains: Domain[]): string {
    return domains?.find((domain) => domain.isMain)?.hostname;
  }
  public getPort(capsule: Capsule): string {
    return capsule?.jsonManifest?.privatePort;
  }
  public getDatabase(capsule: Capsule): string {
    const manifest = capsule?.jsonManifest ?? {};
    if (manifest.dbType == DataCapsuleType.redis) return '';
    return capsule?.jsonManifest?.databaseName;
  }
  public getUsername(capsule: Capsule): string {
    const manifest = capsule?.jsonManifest ?? {};
    if (manifest.dbType == DataCapsuleType.postgres) return 'postgres';
    if (manifest.dbType == DataCapsuleType.mongo) return manifest.username;
    if (manifest.dbType == DataCapsuleType.redis) return '';
    return 'root';
  }
  public getPassword(capsule: Capsule): string {
    const manifest = capsule?.jsonManifest ?? {};
    if (manifest.dbType == DataCapsuleType.postgres) return manifest.password;
    if (manifest.dbType == DataCapsuleType.mongo) return manifest.password;
    if (manifest.dbType == DataCapsuleType.redis) return '';
    return capsule.jsonManifest.rootPassword;
  }

  public getPrivateConnectionString(capsule: Capsule): string {
    if (!capsule) return '';

    const host = this.getPrivateHost(capsule);
    const port = this.getPort(capsule);
    const database = this.getDatabase(capsule);
    const username = this.getUsername(capsule);
    const password = this.getPassword(capsule);

    switch (capsule?.jsonManifest?.dbType) {
      case DataCapsuleType.mysql:
        return `mysql://${username}:${password}@${host}:${port}/${database}`;
      case DataCapsuleType.postgres:
        return `postgresql://${username}:${password}@${host}:${port}/${database}`;
      case DataCapsuleType.mongo:
        return `mongodb://${username}:${password}@${host}:${port}/${database}?authSource=admin`;
      case DataCapsuleType.redis:
        return `redis://${host}:${port}`;
    }
    return '';
  }

  getPublicConnectionString(capsule: Capsule, domains: Domain[]): string {
    const mainDomain = domains?.find((domain) => domain.isMain);

    if (!capsule || !mainDomain) return '';

    const host = this.getPublicHost(domains);
    const port = this.getPort(capsule);
    const database = this.getDatabase(capsule);
    const username = this.getUsername(capsule);
    const password = this.getPassword(capsule);

    switch (capsule?.jsonManifest?.dbType) {
      case DataCapsuleType.mysql:
        return `mysql://${username}:${password}@${host}:${port}/${database}`;
      case DataCapsuleType.postgres:
        return `postgresql://${username}:${password}@${host}:${port}/${database}`;
      case DataCapsuleType.mongo:
        return `mongodb://${username}:${password}@${host}:${port}/${database}?authSource=admin&ssl=true&directConnection=true`;
      case DataCapsuleType.redis:
        return `redis://${host}:${port}`;
    }
    return '';
  }

  async getDataCapsuleStatus(space: Space, capsuleId): Promise<Capsule> {
    return await this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/data-capsule/${capsuleId}/status`
    );
  }

  async createBackup(
    space: Space,
    capsuleId: string,
    backupName: string
  ): Promise<void> {
    const clusterEndpoint = space.cluster.clusterApiEndpoint;
    return this.httpService.post(
      `${clusterEndpoint}/data-capsule/${capsuleId}/backup`,
      { backupName }
    );
  }

  async restoreBackup(
    space: Space,
    capsuleId: string,
    backupId
  ): Promise<void> {
    return this.httpService.put(
      `${space.cluster.clusterApiEndpoint}/data-capsule/${capsuleId}/backup/${backupId}/restore`,
      { backupId }
    );
  }

  async deleteBackup(space: Space, capsuleId: string, backupId): Promise<void> {
    return this.httpService.delete(
      `${space.cluster.clusterApiEndpoint}/data-capsule/${capsuleId}/backup/${backupId}`
    );
  }

  async bindDataCapsuleToCapsule(
    space: Space,
    dataCapsule: Capsule,
    capsule: Capsule
  ): Promise<void> {
    return this.httpService.patch(
      `${space.cluster.clusterApiEndpoint}/data-capsule/${dataCapsule.id}/bind-to`,
      { capsuleId: capsule.id }
    );
  }

  async unBindDataCapsuleToCapsule(
    space: Space,
    dataCapsule: Capsule,
    capsule: Capsule
  ): Promise<void> {
    return this.httpService.patch(
      `${space.cluster.clusterApiEndpoint}/data-capsule/${dataCapsule.id}/unbind-from`,
      { capsuleId: capsule.id }
    );
  }
}
