import { createAction, props } from '@ngrx/store';

import { Region } from '../../models/region';

// fetch regions
export const fetchRegions = createAction('[Region] Fetch Regions');
export const fetchRegionsSuccess = createAction(
  '[Region] Fetch Regions Success',
  props<{ regions: Region[] }>()
);
export const fetchRegionsFailed = createAction(
  '[Region] Fetch Regions Failed',
  props<{ error: Error }>()
);
