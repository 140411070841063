import { Domain } from '../../models';
import { BaseState } from '../base.state';

export interface DomainRoute {
  ipAddress: string;
  hostName: string;
}

export interface DomainState extends BaseState {
  domains: Domain[];
  domainsByCapsuleId: { [capsuleId: string]: Domain[] };
  domainRouteByCapsuleId: { [capsuleId: string]: DomainRoute };
}

export const initialState: DomainState = {
  domains: [],
  domainsByCapsuleId: {},
  domainRouteByCapsuleId: {},
};
