import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, from, of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  appstraxAuth,
  AuthStatus,
  ForgotPasswordDto,
  MessageDto,
  RegisterDto,
  ResetPasswordDto,
  User,
} from '@appstrax/services/auth';
import Hotjar from '@hotjar/browser';

import * as CoreActions from '../core.actions';
import * as UserActions from './user.actions';

import { environment } from 'src/environments/environment';
import { APIResponse } from '../../api';
import { BillingCustomer } from '../../models/billing-customer';
import { CustomerUsage } from '../../models/customer-usage';
import { StorageService } from '../../services/storage.service';
import { AlertService } from '../../services/alert.service';

function clone(value) {
  return JSON.parse(JSON.stringify(value));
}

const identifyUserHotjar = (user: User) => {
  Hotjar.identify(user.id, {
    email: user.email,
    name: user.data.name,
    surname: user.data.surname,
    verified: user.verified,
  });
};

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private storageService: StorageService,
    private alert: AlertService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.appInit),
      switchMap(() =>
        forkJoin([appstraxAuth.getUser(), appstraxAuth.getAuthStatus()]).pipe(
          map((res) => {
            const user = res[0];
            const status = res[1];

            if (user && status === AuthStatus.authenticated) {
              identifyUserHotjar(user);
              return UserActions.loginSuccess({ user });
            }
            if (status === AuthStatus.pendingTwoFactorAuthCode) {
              return UserActions.loginPending2FA();
            }
            return UserActions.loginFailed({ error: null });
          }),
          catchError((error) =>
            of(UserActions.loginFailed({ error: clone(error) }))
          )
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),
      switchMap((loginDto) =>
        from(appstraxAuth.login(loginDto)).pipe(
          map((result) => {
            const user = result.user;
            const status = result.status;

            if (user && status === AuthStatus.authenticated) {
              identifyUserHotjar(user);
              return UserActions.loginSuccess({ user });
            }
            if (status === AuthStatus.pendingTwoFactorAuthCode) {
              return UserActions.loginPending2FA();
            }
            return UserActions.loginFailed({ error: null });
          }),
          catchError((error) =>
            of(UserActions.loginFailed({ error: clone(error) }))
          )
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      switchMap(() =>
        from(appstraxAuth.logout()).pipe(
          map(() => UserActions.logoutSuccess()),
          catchError((error) =>
            of(UserActions.logoutFailed({ error: clone(error) }))
          )
        )
      )
    )
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.register),
      switchMap((registerDto: RegisterDto) =>
        from(appstraxAuth.register(registerDto)).pipe(
          map((result) => UserActions.registerSuccess({ user: result.user })),
          catchError((error) =>
            of(UserActions.registerFailed({ error: clone(error) }))
          )
        )
      )
    )
  );

  markLoggedIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.registerSuccess),
      switchMap(({ user }) => {
        identifyUserHotjar(user);
        return of(UserActions.loginSuccess({ user }));
      })
    )
  );

  subscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.subscribe),
      switchMap((subscribePayload) => {
        return this.httpClient
          .patch<APIResponse<any>>(
            `${environment.apiBaseUrl}/users/me/subscribed`,
            { subscribed: subscribePayload.subscribed }
          )
          .pipe(
            map((apiResponse) => {
              return UserActions.subscribeSuccess({
                subscribed: apiResponse.data.subscribed,
              });
            }),
            catchError((error) =>
              of(UserActions.subscribeFailed({ error: clone(error) }))
            )
          );
      })
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.forgotPassword),
      switchMap((forgotPasswordDto: ForgotPasswordDto) =>
        from(appstraxAuth.forgotPassword(forgotPasswordDto)).pipe(
          map((_message: MessageDto) => UserActions.forgotPasswordSuccess()),
          catchError((error) =>
            of(UserActions.forgotPasswordFailed({ error: clone(error) }))
          )
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetPassword),
      switchMap((resetPasswordDto: ResetPasswordDto) =>
        from(appstraxAuth.resetPassword(resetPasswordDto)).pipe(
          map((_message: MessageDto) => UserActions.resetPasswordSuccess()),
          catchError((error) =>
            of(UserActions.resetPasswordFailed({ error: clone(error) }))
          )
        )
      )
    )
  );

  fetchCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActions.loginSuccess,
        UserActions.registerSuccess,
        UserActions.fetchCustomer
      ),

      switchMap(() => {
        return this.httpClient
          .get<APIResponse<BillingCustomer>>(
            `${environment.apiBaseUrl}/users/me/customer`
          )
          .pipe(
            map((apiResponse) => {
              const customer = apiResponse.data;
              const meta = apiResponse.meta;

              return UserActions.fetchCustomerSuccess({
                customer,
                approved: meta.approved,
              });
            }),
            catchError((error) =>
              of(UserActions.fetchCustomerFailed({ error }))
            )
          );
      })
    )
  );

  createCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createCustomer),

      switchMap((customerData: BillingCustomer) => {
        return this.httpClient
          .post<APIResponse<BillingCustomer>>(
            `${environment.apiBaseUrl}/users/me/customer`,
            customerData
          )
          .pipe(
            map((apiResponse) => {
              return UserActions.createCustomerSuccess(apiResponse.data);
            }),
            catchError((error) =>
              of(UserActions.createCustomerFailed({ error }))
            )
          );
      })
    )
  );

  updateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateCustomer),

      switchMap((customerData: BillingCustomer) => {
        return this.httpClient
          .put<APIResponse<BillingCustomer>>(
            `${environment.apiBaseUrl}/users/me/customer`,
            customerData
          )
          .pipe(
            map((apiResponse) => {
              return UserActions.updateCustomerSuccess(apiResponse.data);
            }),
            catchError((error) =>
              of(UserActions.updateCustomerFailed({ error }))
            )
          );
      })
    )
  );

  // update user
  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),

      switchMap(({ user }) =>
        from(appstraxAuth.saveUserData(user.data)).pipe(
          map((user) => UserActions.updateUserSuccess({ user })),
          catchError((error) =>
            of(UserActions.updateUserFailed({ error: clone(error) }))
          )
        )
      )
    )
  );

  sendVerifyUserEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendVerifyUserEmail),

      switchMap(() =>
        from(appstraxAuth.sendEmailVerificationCode()).pipe(
          map((res) => {
            return UserActions.sendVerifyUserEmailSuccess();
          }),
          catchError((error) =>
            of(UserActions.sendVerifyUserEmailFailed({ error: clone(error) }))
          )
        )
      )
    )
  );

  verifyUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.verifyUser),

      switchMap((body: { code: string }) =>
        from(appstraxAuth.verifyEmailAddress(body.code)).pipe(
          map((user) => {
            identifyUserHotjar(user);
            return UserActions.verifyUserSuccess({ user });
          }),
          catchError((error) =>
            of(UserActions.verifyUserFailed({ error: clone(error) }))
          )
        )
      )
    )
  );

  fetchCustomerUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchCustomerUsage),

      switchMap((action) => {
        let query = '';
        if (action.billingPeriod) {
          query = `?billing_period=${action.billingPeriod}`;
        }

        return this.httpClient
          .get<APIResponse<CustomerUsage>>(
            `${environment.apiBaseUrl}/users/me/customer/usage${query}`
          )
          .pipe(
            map((apiResponse) => {
              return UserActions.fetchCustomerUsageSuccess(apiResponse.data);
            }),
            catchError((error) =>
              of(UserActions.fetchCustomerUsageFailed({ error }))
            )
          );
      })
    )
  );

  fetchCustomerCredit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchCustomerCredit, UserActions.loginSuccess),

      switchMap((action) => {
        const endpoint = `${environment.apiBaseUrl}/users/me/customer/credit`;
        return this.httpClient
          .get<APIResponse<{ credit: number }>>(endpoint)
          .pipe(
            map((apiResponse) => {
              return UserActions.fetchCustomerCreditSuccess(apiResponse.data);
            }),
            catchError((error) =>
              of(UserActions.fetchCustomerCreditFailed({ error }))
            )
          );
      })
    )
  );

  requestCustomerCredit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.requestCustomerCredit),

      switchMap((action) => {
        const endpoint = `${environment.apiBaseUrl}/users/me/customer/credit`;
        return this.httpClient
          .post<APIResponse<{ credit: number }>>(endpoint, {
            amount: action.amount,
          })
          .pipe(
            map((apiResponse) => {
              return UserActions.requestCustomerCreditSuccess(apiResponse.data);
            }),
            catchError((error) =>
              of(UserActions.requestCustomerCreditFailed({ error }))
            )
          );
      })
    )
  );

  redeemCouponCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.redeemCouponCode, UserActions.createCustomerSuccess),
      switchMap(() => {
        const endpoint = `${environment.apiBaseUrl}/users/me/customer/coupon`;
        const couponCode = this.storageService.getCouponCode();
        if (!couponCode) {
          return null;
        }
        console.log('Redeeming coupon');
        return this.httpClient
          .post<APIResponse<{ credit: number }>>(endpoint, {
            couponCode: couponCode,
          })
          .pipe(
            map((apiResponse) => {
              this.alert.success('Coupon redeemed!');
              this.storageService.setCouponCode('');
              return UserActions.redeemCouponCodeSuccess({
                credit: apiResponse.data.amount,
              });
            }),
            catchError((error) => {
              this.storageService.setCouponCode('');
              return of(UserActions.redeemCouponCodeFailed({ error }));
            })
          );
      })
    )
  );

  showFailAlerts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.redeemCouponCodeFailed),
        tap((action) => {
          this.alert.handle(action.error);
        })
      ),
    { dispatch: false }
  );
}
