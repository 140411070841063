import { ProductRequestDto } from 'src/app/create-content/pages/create-capsule/models/product-request.dto';

export class Plan {
  id?: string;
  capsuleType?: string;
  clusterId?: string;
  sortKey?: number;
  name?: string;
  description?: string;
  pricePerMonth?: string;
  cpu?: number; // In full CPU units (0.1 == 100m)
  ram?: number; // In GB (0.512GB === 512MB) // TODO: memory instead of ram for consistency
  gpu?: number; // In full GPU units
  storage?: number; // In GB
  replicas?: number; // Integer

  // In future we can have keys like 'databaseRows' or 'databaseUsers' etc.

  static fromCapsuleProducts(
    capsuleProduct: ProductRequestDto,
    plans: Plan[] = []
  ): Plan {
    const defaultPlan = {
      cpu:
        capsuleProduct?.cpu?.unit === 'm'
          ? capsuleProduct?.cpu?.qty / 1000
          : capsuleProduct?.cpu?.qty,
      ram: capsuleProduct?.memory?.qty,
      storage: capsuleProduct?.storage?.qty,
      gpu: 0,
      replicas: capsuleProduct?.replicaScale?.qty,
    };
    if (!plans?.length) {
      return defaultPlan;
    }

    let plan = plans.find(
      (p: Plan) =>
        p.cpu === defaultPlan?.cpu &&
        p.ram === defaultPlan?.ram &&
        p.storage === defaultPlan?.storage &&
        p.gpu === defaultPlan?.gpu
    );

    return plan || defaultPlan;
  }
}
