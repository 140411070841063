<div class="modal-header align-items-center">
  <h4 class="modal-title">{{ options.title }}</h4>

  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close()"></button>
</div>

<div class="modal-body">
  <div [innerHTML]="options.message"></div>

  <div class="mt-3 p-3" [class]="'alert-' + options.alertType">
    <div>Please enter the following text to confirm this action.</div>
    <div class="user-no-select text-uppercase bold" data-testId="confirmation-text">
      {{ options.confirmationText }}
    </div>
  </div>

  <div class="mt-3">
    <form>
      <app-input
        class="m-0"
        name="confirmationText"
        type="text"
        [placeholder]="options.placeholder || ''"
        [form]="confirmationForm"
        [(model)]="confirmationText">
      </app-input>
    </form>
  </div>
</div>

<div class="modal-footer justify-content-end">
  <div class="d-flex">
    <button class="btn btn-primary me-2" (click)="onConfirm()" data-testId="modal-confirm-button">Confirm</button>
    <button class="btn btn-secondary" (click)="onReject()">Cancel</button>
  </div>
</div>
