import { Agency } from './agency';
import { Space } from './space';
import { UserProfile } from './user-profile';

interface TeamUser {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  teamId: string;
  userId: string;
  role: string;
}

export class Team {
  id?: string;
  updatedAt?: Date;
  createdAt?: Date;

  isPersonal: boolean = false;
  name: string;
  slug: string;
  abbreviation: string = 'cc';
  role: string = 'owner';
  imageUrl: string;
  customerKey: string;
  deleted: boolean = false;

  spaces: Space[];
  owner: UserProfile;
  members: any[];

  // admin only
  users?: UserProfile[];
  teamUsers?: TeamUser[];
  agency?: Agency;
}
