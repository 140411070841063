import { BaseState } from '../base.state';
import { Cluster, ClusterScaleConfig } from '../../models';

export interface ClusterState extends BaseState {
  clusters: Cluster[];
  clusterScaleConfigs: ClusterScaleConfig[];
}

export const initialState: ClusterState = {
  clusters: [],
  clusterScaleConfigs: [],
};
