import { Injectable } from '@angular/core';

import { Capsule, Domain, Space } from '../../models';
import { HttpService } from './../http.service';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  constructor(private httpService: HttpService) {}

  async getDomains(space: Space, capsuleId: string): Promise<Array<Domain>> {
    const result = await this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsuleId}/domains`
    );
    return result.domains;
  }

  async getCustomDomainDetails(space: Space, capsuleId: string): Promise<any> {
    const result = await this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsuleId}/domains`
    );
    return result;
  }

  async getDomainInformation(domain: Domain, space: Space): Promise<any> {
    const result = await this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/domains/${domain.id}`
    );
    return result;
  }

  // Redux'ified
  // async createDomain(
  //   space: Space,
  //   capsule: Capsule,
  //   data: any
  // ): Promise<Domain> {
  //   const result = await this.httpService.post(
  //     `${space.cluster.clusterApiEndpoint}/capsules/${capsule.id}/domains`,
  //     data
  //   );
  //   return result;
  // }

  async deleteDomain(
    domain: Domain,
    space: Space,
    capsule: Capsule,
  ): Promise<boolean> {
    const result = await this.httpService.delete(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsule.id}/domains/${domain.id}`,
    );
    return result;
  }

  // TODO: Deprecated
  // async setPubliclyAccessable(
  //   space: Space,
  //   capsuleId: string,
  //   isPubliclyAccessible
  // ) {
  //   return await this.httpService.post(
  //     `${space.cluster.clusterApiEndpoint}/backend-capsule/${capsuleId}/setpublicaccess/deprecated`,
  //     { enabled: isPubliclyAccessible }
  //   );
  // }
}
