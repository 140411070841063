/*
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { Action, ActionCreator, Store } from '@ngrx/store';
import {
  Observable,
  Subscription,
  catchError,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface Err {
  error: Error;
}

interface HttpData {
  endpoint: string;
  payload?: any;
}

interface Effect<T, R, O> {
  onActions: ActionCreator<string, (props: T) => T & TypedAction<string>>[];
  successAction: ActionCreator<string, (props: R) => R & TypedAction<string>>;
  failedAction: ActionCreator<
    string,
    (props: Err) => Err & TypedAction<string>
  >;
  reduce: (action: T & TypedAction<string>, response: O) => R;
  post?: (action: T & TypedAction<string>) => HttpData;
  get?: (action: T & TypedAction<string>) => HttpData;
  put?: (action: T & TypedAction<string>) => HttpData;
  patch?: (action: T & TypedAction<string>) => HttpData;
  delete?: (action: T & TypedAction<string>) => HttpData;
}

export abstract class BaseEffects {
  constructor(protected actions$: Actions, protected httpClient: HttpClient) {}

  effect<O, T, R>(effect: Effect<O, T, R>): any {
    return createEffect(() => {
      return this.actions$.pipe(
        ofType(...effect.onActions),
        switchMap((action) => {
          let httpCall: Observable<O>;

          if (effect.get) {
            const data = effect.get(action);
            httpCall = this.httpClient.get<O>(data.endpoint);
          }
          if (effect.post) {
            const data = effect.post(action);
            httpCall = this.httpClient.post<O>(data.endpoint, data.payload);
          }
          if (effect.put) {
            const data = effect.put(action);
            httpCall = this.httpClient.put<O>(data.endpoint, data.payload);
          }
          if (effect.patch) {
            const data = effect.patch(action);
            httpCall = this.httpClient.patch<O>(data.endpoint, data.payload);
          }
          if (effect.delete) {
            const data = effect.delete(action);
            httpCall = this.httpClient.delete<O>(data.endpoint);
          }

          return httpCall.pipe(
            map((response) =>
              effect.successAction(effect.reduce(action, response))
            ),
            catchError((error) => of(effect.failedAction({ error })))
          );
        })
      );
    });
  }
}
*/

interface Model {
  id?: string;
}

export class StateUtils {
  static combineStateArr<T extends Model>(
    existingItems: T[],
    newItems: T[]
  ): T[] {
    if (!newItems) return [...existingItems];

    let result: T[] = [];
    let newItemMap: { [id: string]: T } = {};
    let existingItemMap: { [id: string]: boolean } = {};

    for (const newItem of newItems) {
      if (!newItem) continue;
      newItemMap[newItem.id] = newItem;
    }

    for (const existingItem of existingItems) {
      const newItem = newItemMap[existingItem.id];
      if (newItem) {
        result.push(newItem);
      } else {
        result.push(existingItem);
      }
      existingItemMap[existingItem.id] = true;
    }

    for (const newItem of newItems) {
      if (!existingItemMap[newItem.id]) result.push(newItem);
    }

    return result;
  }

  static combineState<T extends Model>(existingItems: T[], newItem: T): T[] {
    if (!newItem) return [...existingItems];

    existingItems = [...existingItems];
    if (existingItems.find((x) => x.id === newItem.id)) {
      existingItems = existingItems.map((x) =>
        x.id === newItem.id ? newItem : x
      );
    } else {
      existingItems.push(newItem);
    }

    return existingItems;
  }
}
