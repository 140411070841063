import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { State } from './core.state';

import * as DomainsReducer from './domain/domain.reducer';
import * as InvoiceReducer from './invoice/invoice.reducer';
import * as PaymentMethodReducer from './payment-method/payment-method.reducer';
import * as ReposReducer from './repo/repo.reducer';
import * as SpacesReducer from './space/space.reducer';
import * as TeamsReducer from './team/team.reducer';
import * as UserReducer from './user/user.reducer';
import * as ClusterReducer from './cluster/cluster.reducer';
import * as RegionReducer from './region/region.reducer';
import * as CloudReducer from './cloud/cloud.reducer';
import * as PhoneNumberReducer from './phone-number/phone-number.reducer';
import * as PlanReducer from './plan/plan.reducer';
import * as AdminReducer from './admin/admin.reducer';
import * as CreateCapsuleReducer from './create-capsule/create-capsule.reducer';
import * as CapsuleReducer from './capsule/capsule.reducer';
import * as LandingPageReducer from './landing-page/landing-page.reducer';
import * as NotificationReducer from './notification/notification.reducer';
import * as RecaptchaReducer from './recaptcha/recaptcha.reducer';
import * as BackupAndRestoreIdsReducer from './backup-restore/backup-restore.reducer';
import * as AgenciesReducer from './agency/agency.reducer';


export const reducers: ActionReducerMap<State> = {
  cloud: CloudReducer.reducer,
  cluster: ClusterReducer.reducer,
  domain: DomainsReducer.reducer,
  invoice: InvoiceReducer.reducer,
  paymentMethod: PaymentMethodReducer.reducer,
  phoneNumber: PhoneNumberReducer.reducer,
  region: RegionReducer.reducer,
  repo: ReposReducer.reducer,
  space: SpacesReducer.reducer,
  team: TeamsReducer.reducer,
  capsule: CapsuleReducer.reducer,
  user: UserReducer.reducer,
  plan: PlanReducer.reducer,
  admin: AdminReducer.reducer,
  createCapsule: CreateCapsuleReducer.reducer,
  landingPage: LandingPageReducer.reducer,
  notification: NotificationReducer.reducer,
  recaptcha: RecaptchaReducer.reducer,
  backupAndRestoreIds: BackupAndRestoreIdsReducer.reducer,
  agency: AgenciesReducer.reducer,
};

export const metaReducers: MetaReducer<State>[] = [];
