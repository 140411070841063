// Angular
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import Hotjar from '@hotjar/browser';

// core
import { environment } from '../../environments/environment';
import { BaseComponent } from '../core/base.component';
import { appInit } from '../core/state';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-alert></app-alert>
  `,
})
export class AppComponent extends BaseComponent {
  constructor(private store: Store, private router: Router) {
    super();

    this.store.dispatch(appInit());

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        Hotjar.stateChange(event.urlAfterRedirects);
      }
    });

    if (!environment.production) {
      window['__store__'] = this.store;
    }
  }
}
