import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { AppFormGroup } from './../../utils/app-form-group/app-form-group';

@Component({
  templateUrl: 'text-confirm-modal.component.html',
  styleUrls: ['text-confirm-modal.component.scss'],
})
export class TextConfirmModalComponent {
  public options: TextConfirmModalOptions;
  public hide: boolean = false;
  confirmationText: string = '';

  confirmationForm = new AppFormGroup({
    confirmationText: new FormControl('', [Validators.required]),
  });

  constructor(private bsModalRef: BsModalRef) {}

  onConfirm(): void {
    this.confirmationForm.markAsSubmitted();

    if (
      this.confirmationText.toLowerCase() !==
      this.options.confirmationText.toLowerCase()
    ) {
      this.confirmationForm
        .get('confirmationText')
        .setErrors({ 'Confirmation input does not match text': true });
    }

    if (this.confirmationForm.invalid) {
      return;
    }

    this.options.confirm();
    this.close();
  }

  onReject(): void {
    if (this.options.reject) {
      this.options.reject();
    }
    this.close();
  }

  close(): void {
    this.bsModalRef.hide();
  }
}

export class TextConfirmModalOptions {
  title: string;
  message: string;
  confirmationText: string;
  placeholder?: string;
  alertType: AlertType;
  confirm: () => void;
  reject?: () => void;
}

export enum AlertType {
  INFO = 'info',
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}
