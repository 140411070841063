import { BaseState } from '../base.state';
import { Backup, Build, Capsule } from '../../models';
import { ConfigDto } from '../../models/config-dto';
import { CapsuleCostsResponse } from '../../models/capsule-costs-response';

export interface CapsuleState extends BaseState {
  capsules: Capsule[];
  usageByCapsule: { [capsuleId: string]: CapsuleCostsResponse };
  configByCapsule: { [capsuleId: string]: ConfigDto };
  buildsByCapsule: { [capsuleId: string]: Build[] };
  backupsByCapsule: { [capsuleId: string]: Backup[] };
  backupDownloadUrl: string
}

export const initialState: CapsuleState = {
  capsules: [],
  usageByCapsule: {},
  configByCapsule: {},
  buildsByCapsule: {},
  backupsByCapsule: {},
  backupDownloadUrl: ''
};
