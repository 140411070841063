import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { APIResponse } from '../../api';

import * as UserActions from '../user/user.actions';
import * as PhoneNumberActions from './phone-number.actions';

@Injectable()
export class PhoneNumberEffects {
  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
  ) { }

  private baseUrl = `${environment.apiBaseUrl}/users/phone-numbers`;

  // fetch phone numbers
  fetchPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActions.loginSuccess,
        PhoneNumberActions.fetchPhoneNumbers
      ),

      switchMap(() =>
        this.httpClient.get<APIResponse<any>>(this.baseUrl).pipe(
          map((response) =>
            PhoneNumberActions.fetchPhoneNumbersSuccess({
              phoneNumbers: response.data
            })
          ),
          catchError((error) =>
            of(PhoneNumberActions.fetchPhoneNumbersFailed({
              error: this.handleError(error)
            }))
          )
        )
      )
    )
  );

  // create phone number
  createPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneNumberActions.createPhoneNumber),

      switchMap(({ phoneNumber }) =>
        this.httpClient.post<APIResponse<any>>(this.baseUrl, { phoneNumber }).pipe(
          map((response) =>
            PhoneNumberActions.createPhoneNumberSuccess({
              phoneNumber: response.data
            })
          ),
          catchError((error) =>
            of(PhoneNumberActions.createPhoneNumberFailed({
              error: this.handleError(error)
            }))
          )
        )
      )
    )
  );

  // set phone number active
  setPhoneNumberActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneNumberActions.setPhoneNumberActive),

      switchMap(({ phoneNumber }) =>
        this.httpClient.post<APIResponse<any>>(
          `${this.baseUrl}/active`,
          { phoneNumberId: phoneNumber.id }
        ).pipe(
          map((response) =>
            PhoneNumberActions.setPhoneNumberActiveSuccess({
              phoneNumbers: response.data
            })
          ),
          catchError((error) =>
            of(PhoneNumberActions.setPhoneNumberActiveFailed({
              error: this.handleError(error)
            }))
          )
        )
      )
    )
  );

  // send code to phone number
  sendPhoneNumberCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneNumberActions.sendCodeToPhoneNumber),

      switchMap(({ phoneNumber }) =>
        this.httpClient.post<APIResponse<any>>(
          `${this.baseUrl}/send-code`,
          { phoneNumberId: phoneNumber.id }
        ).pipe(
          map((response) =>
            PhoneNumberActions.sendCodeToPhoneNumberSuccess()
          ),
          catchError((error) =>
            of(PhoneNumberActions.sendCodeToPhoneNumberFailed({
              error: this.handleError(error)
            }))
          )
        )
      )
    )
  );

  // verify phone number
  verifyPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PhoneNumberActions.verifyPhoneNumber),

      switchMap(({ phoneNumber, code }) =>
        this.httpClient.post<APIResponse<any>>(
          `${this.baseUrl}/verify`,
          {
            phoneNumberId: phoneNumber.id,
            code,
          }
        ).pipe(
          map((response) =>
            PhoneNumberActions.verifyPhoneNumberSuccess({
              phoneNumbers: response.data
            })
          ),
          catchError((error) =>
            of(PhoneNumberActions.verifyPhoneNumberFailed({
              error: this.handleError(error)
            }))
          )
        )
      )
    )
  );

  private handleError(error): string {
    if (error?.error?.error?.message) { return error?.error?.error?.message; }
    if (error?.error?.message) { return error?.error?.message; }
    if (error?.message) { return error?.message; }
    if (typeof error === 'string') { return error; }

    return 'An unexpected error occurred';
  }

}
