import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CreateCapsuleActions from './create-capsule.actions';

@Injectable()
export class CreateCapsuleEffects {
  constructor(private actions$: Actions<any>) {}

  setCreateCapsuleState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateCapsuleActions.setCreateCapsuleState),
      map((payload) => {
        return CreateCapsuleActions.setCreateCapsuleStateSuccess({
          createCapsuleState: payload.createCapsuleState,
        });
      }),
      catchError((error) =>
        of(CreateCapsuleActions.setCreateCapsuleStateFailed({ error }))
      )
    )
  );
}
