import { createFeatureSelector, createSelector } from '@ngrx/store';
import moment from 'moment';

import { Capsule, Space, Team, TeamProductUsage } from '../../models';
import { RouteState, selectRoute } from '../route';
import { SpaceState } from './space.state';
import { selectAllCapsules } from '../capsule';

export const selectSpaces = createFeatureSelector<SpaceState>('space');

export const selectAllSpaces = createSelector(
  selectSpaces,
  (state: SpaceState) => state.spaces
);

export const selectLoading = createSelector(
  selectSpaces,
  (state: SpaceState) => state.loading
);

export const selectSpaceById = (props: { id: string }) =>
  createSelector(selectAllSpaces, (spaces) =>
    spaces.find((space) => space.id === props.id)
  );

export const selectSpaceBySlug = (props: { slug: string }) =>
  createSelector(selectAllSpaces, (spaces) =>
    spaces.find((space) => space.slug === props.slug)
  );

export const selectSpacesByTeamId = (props: { teamId: string }) =>
  createSelector(selectAllSpaces, (spaces) => {
    return spaces.filter((space) => space.teamId === props.teamId);
  });

export const selectCurrentSpace = createSelector(
  selectRoute,
  selectAllSpaces,
  (route: RouteState, spaces: Space[]) => {
    const spaceSlugOrId = route.params['space_slug_or_id'];
    return (
      spaces.find((x) => x.id === spaceSlugOrId) ||
      spaces.find((x) => x.slug === spaceSlugOrId) ||
      null
    );
  }
);

export const selectCurrentSpaceCapsules = createSelector(
  selectAllCapsules,
  selectCurrentSpace,
  (capsules: Capsule[], space: Space) => {
    return capsules.filter((x) => x.namespace.key === space?.namespaceKey);
  }
);

export const selectCurrentSpaceDataCapsules = createSelector(
  selectCurrentSpaceCapsules,
  (capsules: Capsule[]) => capsules.filter((x) => x.type === 'data')
);

export const selectCurrentSpaceUsage = createSelector(
  selectSpaces,
  selectCurrentSpace,
  (state: SpaceState, space: Space) => state.usageBySpace[space?.id] || []
);

export const selectCurrentSpaceUsagePrice = createSelector(
  selectCurrentSpaceUsage,
  (teamProductUsage?: TeamProductUsage[]) => {
    if (!teamProductUsage) return 0;

    const billingPeriodMoment = moment().utc().endOf('M');
    const billingPeriod = billingPeriodMoment.format('YYYY-MM');

    let price = 0;
    teamProductUsage.forEach((teamProductUsage: TeamProductUsage) => {
      if (teamProductUsage.billingPeriod !== billingPeriod) return;

      price = price + Number(teamProductUsage.price || 0);
    });

    return price;
  }
);

export const selectSpaceByCapsule = (props: { capsule: Capsule }) =>
  createSelector(selectAllSpaces, (spaces) => {
    const spaceId = props.capsule.namespace.id;
    return spaces.find((s) => s.id === spaceId);
  });

export const getSelectorCapsulesByType = (props: { capsuleType: string }) => {
  return createSelector(selectSpaces, (spaceState: SpaceState) => {
    let capsules = [];
    spaceState.spaces.forEach((space) => {
      capsules.push(...(space.capsules || []));
    });
    return capsules.filter(
      (capsule: Capsule) => capsule.type === props.capsuleType
    );
  });
};

export const getSelectorCapsulesByTypeInNamespace = (props: {
  space: Space;
  capsuleType: string;
}) => {
  return createSelector(selectSpaces, (spaceState: SpaceState) => {
    const space = spaceState.spaces.find((s) => s.id === props.space.id);
    const capsules = space.capsules || [];
    return capsules.filter(
      (capsule: Capsule) => capsule.type === props.capsuleType
    );
  });
};

export const selectSpacesByTeam = (props: { team: Team }) =>
  createSelector(selectAllSpaces, (spaces: Space[]) =>
    spaces.filter((s) => s.teamId === props.team.id)
  );
