import { Injectable } from '@angular/core';

import { AlertListener } from './alert-listener';
import { UtilService } from './util.service';

@Injectable({ providedIn: 'root' })
export class AlertService {
  listener: AlertListener;

  constructor(private utils: UtilService) {}

  subscribeToAlerts(listener: AlertListener) {
    this.listener = listener;
  }

  success(text: String) {
    if (!this.listener) return;
    this.listener.onSuccessAlert(text);
  }

  error(text: String) {
    if (!this.listener) return;
    this.listener.onErrorAlert(text);
  }

  warning(text: String) {
    if (!this.listener) return;
    this.listener.onWarningAlert(text);
  }

  info(text: String) {
    if (!this.listener) return;
    this.listener.onInfoAlert(text);
  }

  handle(error) {
    const message = this.utils.getErrorMessage(error);
    this.error(message);
  }
}
