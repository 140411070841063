import { createAction, props } from '@ngrx/store';
import { CreateNewCapsuleState } from '../../models/create-capsule-state';

export const setCreateCapsuleState = createAction(
  '[Create Capsule State] Set Create Capsule State Initiated',
  props<{ createCapsuleState: CreateNewCapsuleState }>()
);

export const setCreateCapsuleStateSuccess = createAction(
  '[Create Capsule State] Set Create Capsule State Success',
  props<{ createCapsuleState: CreateNewCapsuleState }>()
);

export const setCreateCapsuleStateFailed = createAction(
  '[Create Capsule State] Set Create Capsule State Failed',
  props<{ error: Error }>()
);
