import { createAction, props } from '@ngrx/store';
import {
  Build,
  Capsule,
  Space,
  CapsuleCostsResponse,
  ConfigDto,
  CapsuleManifest,
  Backup,
  CapsuleApiRepo,
  Repo,
} from '../../models';

import { ProductRequestDto } from 'src/app/create-content/pages/create-capsule/models/product-request.dto';

// set capsule ingress enabled
export const setDeployBuild = createAction(
  '[Capsule] Set Deploy Build Initiated',
  props<{ space: Space; capsule: Capsule; build: Build }>()
);
export const setDeployBuildSuccess = createAction(
  '[Capsule] Set Deploy Build Success',
  props<{ space: Space; capsule: Capsule; build: Build }>()
);
export const setDeployBuildFailed = createAction(
  '[Capsule] set Deploy Build Failed',
  props<{ error: Error }>()
);

// start capsule manual backup
export const setCapsuleManualBackup = createAction(
  '[Capsule] Set Capsule Manual Backup Initiated',
  props<{ spaceId: string; capsuleId: string }>()
);
export const setCapsuleManualBackupSuccess = createAction(
  '[Capsule] Set Capsule Manual Backup Success',
  props<{ backup: Backup }>()
);
export const setCapsuleManualBackupFailed = createAction(
  '[Capsule] Set Capsule Manual Backup Failed',
  props<{ error: Error }>()
);

// set backup restore
export const setCapsuleBackupRestore = createAction(
  '[Capsule] Set Capsule Backup Restore Initiated',
  props<{ spaceId: string; capsuleId: string; backupId: string }>()
);
export const setCapsuleBackupRestoreSuccess = createAction(
  '[Capsule] Set Capsule Backup Restore Success',
  props<{ backup: any }>()
);
export const setCapsuleBackupRestoreFailed = createAction(
  '[Capsule] Set Capsule Backup Restore Failed',
  props<{ error: Error }>()
);

// fetch backup restore
export const fetchCapsuleBackupRestore = createAction(
  '[Capsule] Fetch Capsule Backup Restore Initiated',
  props<{ spaceId: string; capsuleId: string; restoreId: string }>()
);
export const fetchCapsuleBackupRestoreSuccess = createAction(
  '[Capsule] Fetch Capsule Backup Restore Success',
  props<{ backupRestore: Backup }>()
);
export const fetchCapsuleBackupRestoreFailed = createAction(
  '[Capsule] Fetch Capsule Backup Restore Failed',
  props<{ error: Error }>()
);

// fetch backup download link
export const fetchBackupDownloadLink = createAction(
  '[Capsule] Fetch Backup Download Link Initiated',
  props<{
    spaceId: string;
    capsuleId: string;
    backupId: string;
    callbackAction: any;
  }>()
);
export const fetchBackupDownloadLinkSuccess = createAction(
  '[Capsule] Fetch Backup Download Link Success',
  props<{ backupDownloadUrl: string }>()
);
export const fetchBackupDownloadLinkFailed = createAction(
  '[Capsule] Fetch Backup Download Link Failed',
  props<{ error: Error }>()
);

// fetch backups
export const fetchCapsuleBackups = createAction(
  '[Capsule] Fetch Capsule Backups',
  props<{ space: Space; capsule: Capsule }>()
);
export const fetchCapsuleBackupsSuccess = createAction(
  '[Capsule] Fetch Capsule Backups Success',
  props<{ backups: Backup[]; capsule: Capsule }>()
);
export const fetchCapsuleBackupsFailed = createAction(
  '[Capsule] Fetch Capsule Backups Failed',
  props<{ error: Error }>()
);
export const stopPolling = createAction('[Capsule] Stop Polling');

// set capsule auto backup
export const updateCapsuleAutoBackup = createAction(
  '[Capsule] Set Capsule Auto Backup',
  props<{ space: Space; capsule: Capsule }>()
);
export const updateCapsuleAutoBackupSuccess = createAction(
  '[Capsule] Set Capsule Auto Backup Success',
  props<{ capsule: Capsule }>()
);
export const updateCapsuleAutoBackupFailed = createAction(
  '[Capsule] Set Capsule Auto Backup Failed',
  props<{ error: Error }>()
);

// fetch capsule
export const fetchCapsule = createAction(
  '[Capsule] Fetch Capsule Initiated',
  props<{ space: Space; capsuleId: string }>()
);
export const fetchCapsuleSuccess = createAction(
  '[Capsule] Fetch Capsules Success',
  props<{ space: Space; capsule: Capsule }>()
);
export const fetchCapsuleFailed = createAction(
  '[Capsule] Fetch Capsules Failed',
  props<{ error: Error }>()
);

// fetch capsules
export const fetchSpaceCapsules = createAction(
  '[Capsule] Fetch Space Capsules',
  props<{ space: Space }>()
);
export const fetchSpacesCapsules = createAction(
  '[Capsule] Fetch Spaces Capsules',
  props<{ spaces: Space[] }>()
);
export const fetchCapsulesSuccess = createAction(
  '[Capsule] Fetch Capsules Success',
  props<{ capsules: Capsule[] }>()
);
export const fetchCapsulesFailed = createAction(
  '[Capsule] Fetch Capsules Failed',
  props<{ error: Error }>()
);

// capsule usage
export const fetchCapsuleUsage = createAction(
  '[Capsule] Fetch Capsule Usage',
  props<{ capsule: Capsule; space: Space; billingPeriod?: Date }>()
);
export const fetchCapsuleUsageSuccess = createAction(
  '[Capsule] Fetch Capsule Usage Success',
  props<{ capsuleUsage: CapsuleCostsResponse }>()
);
export const fetchCapsuleUsageFailed = createAction(
  '[Capsule] Fetch Capsule Usage Failed',
  props<{ error: Error }>()
);

// set capsule auto build
export const updateCapsuleAutoBuild = createAction(
  '[Capsule] Set Capsule Auto Build',
  props<{ space: Space; capsule: Capsule }>()
);
export const updateCapsuleAutoBuildSuccess = createAction(
  '[Capsule] Set Capsule Auto Build Success',
  props<{ capsule: Capsule }>()
);
export const updateCapsuleAutoBuildFailed = createAction(
  '[Capsule] Set Capsule Auto Build Failed',
  props<{ error: Error }>()
);

// fetch capsule config
export const fetchCapsuleConfig = createAction(
  '[Capsule] Fetch Capsule Config',
  props<{ space: Space; capsule: Capsule }>()
);
export const fetchCapsuleConfigSuccess = createAction(
  '[Capsule] Fetch Capsule Config Success',
  props<{ config: ConfigDto; capsule: Capsule }>()
);
export const fetchCapsuleConfigFailed = createAction(
  '[Capsule] Fetch Capsule Config Failed',
  props<{ error: Error }>()
);

// update capsule config
export const updateCapsuleConfig = createAction(
  '[Capsule] Set Capsule Config',
  props<{ space: Space; capsule: Capsule; config: ConfigDto }>()
);
export const updateCapsuleConfigSuccess = createAction(
  '[Capsule] Set Capsule Config Success',
  props<{ config: ConfigDto; capsule: Capsule }>()
);
export const updateCapsuleConfigFailed = createAction(
  '[Capsule] Set Capsule Config Failed',
  props<{ error: Error }>()
);

// update capsule manifest
export const updateCapsuleManifest = createAction(
  '[Capsule] Set Capsule Manifest',
  props<{ space: Space; capsule: Capsule; manifest: CapsuleManifest }>()
);
export const updateCapsuleManifestSuccess = createAction(
  '[Capsule] Set Capsule Manifest Success',
  props<{ capsule: Capsule }>()
);
export const updateCapsuleManifestFailed = createAction(
  '[Capsule] Set Capsule Manifest Failed',
  props<{ error: Error }>()
);

// fetch builds
export const fetchCapsuleBuilds = createAction(
  '[Capsule] Fetch Capsule Builds',
  props<{ space: Space; capsule: Capsule }>()
);
export const fetchCapsuleBuildsSuccess = createAction(
  '[Capsule] Fetch Capsule Builds Success',
  props<{ builds: Build[]; capsule: Capsule }>()
);
export const fetchCapsuleBuildsFailed = createAction(
  '[Capsule] Fetch Capsule Builds Failed',
  props<{ error: Error }>()
);

// update capsule description
export const updateCapsuleDescription = createAction(
  '[Capsule] Update Capsule Description',
  props<{ space: Space; capsule: Capsule; description: string }>()
);
export const updateCapsuleDescriptionSuccess = createAction(
  '[Capsule] Update Capsule Description Success',
  props<{ space: Space; capsule: Capsule }>()
);
export const updateCapsuleDescriptionFailed = createAction(
  '[Capsule] Update Capsule Description Failed',
  props<{ error: Error }>()
);

// update capsule products
export const updateCapsuleProducts = createAction(
  '[Capsule] Update Capsule Products',
  props<{ space: Space; capsule: Capsule; products: ProductRequestDto }>()
);
export const updateCapsuleProductsSuccess = createAction(
  '[Capsule] Update Capsule Products Success',
  props<{ space: Space; capsule: Capsule; products: ProductRequestDto }>()
);
export const updateCapsuleProductsFailed = createAction(
  '[Capsule] Update Capsule Products Failed',
  props<{ error: Error }>()
);

// set capsule repo and branch
export const setCapsuleRepo = createAction(
  '[Capsule] Set Capsule Repo Initiated',
  props<{ space: Space; capsuleId: string; branch: string; repo: Repo }>()
);
export const setCapsuleRepoSuccess = createAction(
  '[Capsule] Set Capsule Repo Success',
  props<{
    space: Space;
    capsuleId: string;
    repo: CapsuleApiRepo;
  }>()
);
export const setCapsuleRepoFailed = createAction(
  '[Capsule] Set Capsule Repo Failed',
  props<{ error: Error }>()
);

// remove capsule
export const removeCapsule = createAction(
  '[Capsule] Remove Capsule Submitted',
  props<{ capsule: Capsule; space: Space }>()
);
export const removeCapsuleSuccess = createAction(
  '[Capsule] Remove Capsule Successful',
  props<{ capsule: Capsule; space: Space }>()
);
export const removeCapsuleFailed = createAction(
  '[Capsule] Remove Capsule Failed',
  props<{ error: Error }>()
);

// capsule Create
export const capsuleCreateSuccess = createAction(
  '[Capsule] Capsule Create Success',
  props<{ capsuleId: string; space: Space }>()
);

// set capsule ingress enabled
export const setCapsuleIngressEnabled = createAction(
  '[Capsule] Set Capsule Ingress Enabled Initiated',
  props<{ space: Space; capsuleId: string; enabled: boolean }>()
);
export const setCapsuleIngressEnabledSuccess = createAction(
  '[Capsule] Set Capsule Ingress Enabled Success',
  props<{ space: Space; capsule: Capsule }>()
);
export const setCapsuleIngressEnabledFailed = createAction(
  '[Capsule] Set Capsule Ingress Enabled Failed',
  props<{ error: Error }>()
);

// set persistent storage capsule web dav enabled
export const setCapsuleWebDavEnabled = createAction(
  '[Capsule] Set Capsule Web Dav Enabled Initiated',
  props<{ space: Space; capsuleId: string; enabled: boolean }>()
);
export const setCapsuleWebDavEnabledSuccess = createAction(
  '[Capsule] Set Capsule Web Dav Enabled Success',
  props<{ capsule: Capsule }>()
);
export const setCapsuleWebDavEnabledFailed = createAction(
  '[Capsule] Set Capsule Web Dav Enabled Failed',
  props<{ error: Error }>()
);
