import { Actions, createEffect, ofType } from '@ngrx/effects';

// angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

// ngrx
import * as AgenciesActions from './agency.actions';
import * as UserActions from '../user/user.actions';

import { environment } from '../../../../environments/environment';
import { Agency } from '../../models';
import { APIResponse } from '../../api';

@Injectable()
export class AgencyEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  fetchAgency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AgenciesActions.fetchAgency, UserActions.loginSuccess),
      switchMap(() => {
        const url = `${environment.apiBaseUrl}/agencies`;
        return this.httpClient.get<APIResponse<Agency>>(url).pipe(
          map((response) => {
            return AgenciesActions.fetchAgencySuccess({
              agency: response.data,
            });
          }),
          catchError((error) =>
            of(AgenciesActions.fetchAgencyFailed({ error }))
          )
        );
      })
    )
  );

  saveAgency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AgenciesActions.saveAgency),
      switchMap(({ agency }) => {
        if (!agency.id) {
          return this.addAgency(agency);
        } else {
          return this.updateAgency(agency);
        }
      })
    )
  );

  private addAgency(agency: Agency) {
    const url = `${environment.apiBaseUrl}/agencies`;
    return this.httpClient.post<APIResponse<Agency>>(url, agency).pipe(
      map((res) => AgenciesActions.saveAgencySuccess({ agency: res.data })),
      catchError((error) => of(AgenciesActions.saveAgencyFailed({ error })))
    );
  }

  private updateAgency(agency: Agency) {
    const url = `${environment.apiBaseUrl}/agencies/update`;
    return this.httpClient.patch<APIResponse<Agency>>(url, agency).pipe(
      map((res) => AgenciesActions.saveAgencySuccess({ agency: res.data })),
      catchError((error) => of(AgenciesActions.saveAgencyFailed({ error })))
    );
  }

  updateAgencyImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AgenciesActions.updateAgencyImage),

      switchMap((action) => {
        const body: FormData = new FormData();
        body.append('image', action.file);
        body.append('agencyId', action.agencyId);

        const url = `${environment.apiBaseUrl}/agencies/upload-image`;
        return this.httpClient.post<APIResponse<Agency>>(url, body).pipe(
          map((response) => {
            return AgenciesActions.updateAgencyImageSuccess({
              agency: response.data,
            });
          }),
          catchError((error) =>
            of(AgenciesActions.updateAgencyImageFailed({ error }))
          )
        );
      })
    )
  );

  addTeamAgency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AgenciesActions.addTeamAgency),

      switchMap((action) => {
        const payload = {
          agencyId: action.agency.id,
          teamId: action.team.id,
          markupPercent: action.markupPercent,
        };

        const url = `${environment.apiBaseUrl}/agencies/add/team`;
        return this.httpClient.post<APIResponse<Agency>>(url, payload).pipe(
          map((response) => {
            return AgenciesActions.addTeamAgencySuccess({
              agency: response.data,
              team: action.team,
            });
          }),
          catchError((error) =>
            of(AgenciesActions.addTeamAgencyFailed({ error }))
          )
        );
      })
    )
  );

  removeTeamAgency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AgenciesActions.removeTeamAgency),
      switchMap((action) => {
        const payload = {
          agencyId: action.agency.id,
          teamAgencyId: action.teamAgency.id,
        };

        const url = `${environment.apiBaseUrl}/agencies/remove/team`;
        return this.httpClient.post<APIResponse<Agency>>(url, payload).pipe(
          map((response) => {
            return AgenciesActions.removeTeamAgencySuccess({
              agency: response.data,
              team: action.team,
            });
          }),
          catchError((error) =>
            of(AgenciesActions.removeTeamAgencyFailed({ error }))
          )
        );
      })
    )
  );
}
