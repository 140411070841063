import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpService } from '../http.service';
import { Space, Team } from '../../models';

@Injectable()
export class SpaceService {
  private baseUrl = environment.apiBaseUrl + '/spaces';

  private spaces: Space[] = [];
  private currentSpace: Space;

  constructor(private httpService: HttpService) {}

  getSpacesById(id: string): Space | null {
    return this.spaces.find((space) => space.id === id);
  }

  async getSpaceById(spaceId: string): Promise<Space> {
    const res = await this.httpService.get(`${this.baseUrl}/${spaceId}`);
    return res.data;
  }

  async doesSlugExist(slug: string): Promise<boolean> {
    const res = await this.httpService.get(
      `${this.baseUrl}/slug/exists/${slug}`
    );
    return res.data;
  }

  async getSpaceBySlug(slug: string): Promise<Space> {
    const res = await this.httpService.get(`${this.baseUrl}/slug/${slug}`);
    return res.data;
  }

  async getSpacesByTeamId(teamId: string): Promise<Space[]> {
    const reqData = await this.httpService.get(
      `${this.baseUrl}/teams/${teamId}`
    );
    this.spaces = reqData.data;
    return this.spaces;
  }

  async create(space: Space): Promise<Space> {
    const res = await this.httpService.post(`${this.baseUrl}`, space);
    return res.data;
  }

async createPersonalSpace(team: Team, clusterId: string): Promise<any> {
  const baseUrl = environment.apiBaseUrl;
  const resp = await this.httpService.post(
    `${baseUrl}/spaces/personal`,
    { clusterId: clusterId, teamId: team.id }
  );
  return resp.data;
}


  getCurrentSpace(): Space {
    return this.currentSpace;
  }

  setCurrentSpace(space: Space): void {
    this.currentSpace = space;
  }

  async patchSpace(space: Space): Promise<Space> {
    const response = await this.httpService.patch(
      `${this.baseUrl}/${space.id}/name`,
      space
    );
    return response.data;
  }

  async deleteSpace(space: Space): Promise<any> {
    const res = await this.httpService.delete(`${this.baseUrl}/${space.id}/delete`);
    return res.data;
  }

  async getSpaceVars(space: Space): Promise<any[]> {
    const res = await this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/namespaces/${space.namespaceKey}/variables`
    );
    return res;
  }
}
