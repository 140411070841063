import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../user/user.actions';
import * as RepoActions from './repo.actions';
import { StateUtils as Utils } from '../state-utils';
import { RepoState, initialState } from './repo.state';

const reposReducer = createReducer(
  initialState,

  on(
    RepoActions.fetchRepos,
    RepoActions.fetchTeamRepos,
    RepoActions.addTeamRepo,
    RepoActions.removeTeamRepo,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    RepoActions.fetchReposFailed,
    RepoActions.fetchTeamReposFailed,
    RepoActions.addTeamRepoFailed,
    RepoActions.removeTeamRepoFailed,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),

  // Fetch Repos
  on(RepoActions.fetchReposSuccess, (state, { repos }) => ({
    ...state,
    repos,
    loading: false,
    loadedAt: new Date(),
  })),

  // Fetch Team Repos
  on(RepoActions.fetchTeamReposSuccess, (state, { team, repos }) => {
    const teamRepos = { ...state.teamRepos };
    teamRepos[team.id] = repos;
    return {
      ...state,
      teamRepos,
      loading: false,
      loadedAt: new Date(),
    };
  }),

  // Add Team Repo
  on(RepoActions.addTeamRepoSuccess, (state, { team, repo }) => {
    const teamRepos = { ...state.teamRepos };
    teamRepos[team.id] = Utils.combineState(teamRepos[team.id] || [], repo);
    return {
      ...state,
      teamRepos,
      loading: false,
      loadedAt: new Date(),
    };
  }),

  // Remove Team Repo
  on(RepoActions.removeTeamRepoSuccess, (state, { team, repo }) => {
    const teamRepos = { ...state.teamRepos };
    teamRepos[team.id] = teamRepos[team.id].filter((x) => x.id != repo.id);
    return {
      ...state,
      teamRepos,
      loading: false,
      loadedAt: new Date(),
    };
  }),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    repos: [],
    teamRepos: {},
    error: null,
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: RepoState | undefined, action: Action) {
  return reposReducer(state, action);
}
