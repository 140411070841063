import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { catchError, of, map, switchMap } from 'rxjs';
import * as LandingPageActions from './landing-page.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { APIResponse } from '../../api';
import { LandingPage } from '../../models/landing-page';
import { LandingPageSnippet } from '../../models/landing-page-snippet';

@Injectable()
export class LandingPageEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) {}

  getLandingPageById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LandingPageActions.getLandingPageById),
      switchMap((action) => {
        return this.httpClient
          .get<APIResponse<LandingPage>>(
            `${environment.apiBaseUrl}/landing-page/${action.landingPageId}`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return LandingPageActions.getLandingPageByIdFailed({
                  error: new Error('Something went wrong.'),
                });
              }
              let landingPage = apiResponse.data;
              landingPage.meta = landingPage.meta;

              return LandingPageActions.getLandingPageByIdSuccess({
                landingPage,
              });
            }),
            catchError((error) =>
              of(LandingPageActions.getLandingPageByIdFailed({ error }))
            )
          );
      })
    )
  );

  getLandingPageSnippets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LandingPageActions.getLandingPageSnippets),
      switchMap((action) => {
        return this.httpClient
          .get<APIResponse<LandingPageSnippet[]>>(
            `${environment.apiBaseUrl}/landing-page/snippets/${action.landingPageId}`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return LandingPageActions.getLandingPageSnippetsFailed({
                  error: new Error('Something went wrong.'),
                });
              }
              const landingPageSnippets = apiResponse.data;
              return LandingPageActions.getLandingPageSnippetsSuccess({
                landingPageSnippets,
              });
            }),
            catchError((error) =>
              of(LandingPageActions.getLandingPageSnippetsFailed({ error }))
            )
          );
      })
    )
  );
}
