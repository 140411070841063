import { Action, createReducer, on } from '@ngrx/store';
import {addBackupAndRestoreIds ,deleteBackupAndRestoreIds} from './backup-restore.actions';
import { BackupAndRestoreIdsState, initialState } from './backup-restore.state';

export const backupAndRestoreIdsReducer = createReducer(
  initialState,
  on(addBackupAndRestoreIds, (state, { backupId, restoreId, capsuleId }) => ({
    ...state,
    backupAndRestoreIds: [
      ...state.backupAndRestoreIds,
      { backupId, restoreId, capsuleId }
    ],
  })),
  on(deleteBackupAndRestoreIds, (state, { capsuleId }) => ({
    ...state,
    backupAndRestoreIds: state.backupAndRestoreIds.filter(item => item.capsuleId !== capsuleId)
  }))
);

export function reducer(
  state: BackupAndRestoreIdsState | undefined,
  action: Action
) {
  return backupAndRestoreIdsReducer(state, action);
}
