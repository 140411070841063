import { BaseState } from '../base.state';
import { Space } from '../../models';
import { TeamProductUsage } from '../../models/team-product-usage';

export interface SpaceState extends BaseState {
  spaces: Space[];
  usageBySpace: { [spaceId: string]: TeamProductUsage[] };
}

export const initialState: SpaceState = {
  spaces: [],
  usageBySpace: {},
};
