import { BaseState } from '../base.state';
import { TeamInvitation } from '../../models/team-invitation';

export interface NotificationState extends BaseState {
  notifications: TeamInvitation[];
}

export const initialState: NotificationState = {
  notifications: [],
};
