import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../user/user.actions';
import * as DomainsActions from './domain.actions';
import { DomainState, initialState } from './domain.state';
import { StateUtils as Utils } from '../state-utils';
import { Domain } from '../../models';

const domainsReducer = createReducer(
  initialState,

  // fetch domains
  on(DomainsActions.fetchDomains, (state) => ({
    ...state,
    loading: true,
  })),
  on(DomainsActions.fetchDomainsSuccess, (state, { domains }) => {
    const domainsByCapsuleId: { [capsuleId: string]: Domain[] } = {};
    for (const domain of domains) {
      if (domainsByCapsuleId[domain.capsuleId]) {
        domainsByCapsuleId[domain.capsuleId].push(domain);
      } else {
        domainsByCapsuleId[domain.capsuleId] = [domain];
      }
    }
    return {
      ...state,
      domains,
      domainsByCapsuleId,
      loading: false,
      loadedAt: new Date(),
    };
  }),
  on(DomainsActions.fetchDomainsFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // fetch capsule domains
  on(DomainsActions.fetchCapsuleDomains, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    DomainsActions.fetchCapsuleDomainsSuccess,
    (state, { capsuleId, domains, ipAddress, hostName }) => ({
      ...state,
      domains: Utils.combineStateArr(state.domains, domains),
      domainsByCapsuleId: { ...state.domainsByCapsuleId, [capsuleId]: domains },
      domainRouteByCapsuleId: {
        ...state.domainRouteByCapsuleId,
        [capsuleId]: {
          ipAddress: hostName != 'None' ? ipAddress : 'None',
          hostName,
        },
      },
      loading: false,
    })
  ),
  on(DomainsActions.fetchCapsuleDomainsFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // add domain
  on(DomainsActions.addDomain, (state, { domain }) => ({
    ...state,
    loading: true,
  })),
  on(DomainsActions.addDomainSuccess, (state, { domain }) => ({
    ...state,
    domains: [...state.domains, domain],
    loading: false,
  })),
  on(DomainsActions.addDomainFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // remove domain
  on(DomainsActions.removeCapsuleDomain, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    DomainsActions.removeCapsuleDomainSuccess,
    (state, { domain, capsule }) => {
      const domains = state.domains.filter((x) => x.id !== domain.id);

      const domainsByCapsuleId = {
        ...state.domainsByCapsuleId,
        [capsule.id]: state.domainsByCapsuleId[capsule.id].filter(
          (x) => x.id !== domain.id
        ),
      };

      return {
        ...state,
        domains: domains,
        domainsByCapsuleId,
        loading: false,
      };
    }
  ),
  on(DomainsActions.removeCapsuleDomainFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    domains: [],
    domainsByCapsuleId: {},
    domainRouteByCapsuleId: {},
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: DomainState | undefined, action: Action) {
  return domainsReducer(state, action);
}
