import { Capsule } from './capsule';
import { Cluster } from './cluster';
import { Team } from './team';

export class Space {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  teamId: string;
  name: string;
  slug: string;
  namespaceKey: string;
  clusterId: string;
  deleted: boolean = false;

  team?: Team;
  cluster: Cluster;
  capsules: Capsule[];
}
