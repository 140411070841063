import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ArrowButtonComponent } from './arrow-button/arrow-button.component';
import { ArrowLinkComponent } from './arrow-link/arrow-link.component';
import { CapsuleListItemComponent } from './capsule-list-item/capsule-list-item.component';
import { CardComponent } from './card/card.component';
import { CardListGridComponent } from './card-list-grid/card-list-grid.component';
import { CheckoutSpacesButtonComponent } from './checkout-spaces-button/checkout-spaces-button.component';
import { HeaderPopupComponent } from '../components/header-popup/header-popup.component';
import { ImageCardComponent } from './image-card/image-card.component';
import { ListItemComponent } from './list-item/list-item.component';
import { SpinnerButtonComponent } from './spinner-button/spinner-button.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TitleHrComponent } from './title-hr/title-hr.component';
import { TitleHrLeftComponent } from './title-hr-left/title-hr-left.component';

@NgModule({
  declarations: [
    ArrowButtonComponent,
    ArrowLinkComponent,
    CapsuleListItemComponent,
    CardComponent,
    CardListGridComponent,
    CheckoutSpacesButtonComponent,
    HeaderPopupComponent,
    ImageCardComponent,
    ListItemComponent,
    SpinnerButtonComponent,
    SpinnerComponent,
    TitleHrComponent,
    TitleHrLeftComponent,
  ],
  imports: [CommonModule, FormsModule, RouterModule],
  exports: [
    ArrowButtonComponent,
    ArrowLinkComponent,
    CapsuleListItemComponent,
    CardComponent,
    CardListGridComponent,
    CheckoutSpacesButtonComponent,
    HeaderPopupComponent,
    ImageCardComponent,
    ListItemComponent,
    SpinnerButtonComponent,
    SpinnerComponent,
    TitleHrComponent,
    TitleHrLeftComponent,
  ],
})
export class UiModule {}
