import { createAction, props } from '@ngrx/store';

import { LandingPage } from '../../models/landing-page';
import { LandingPageSnippet } from '../../models/landing-page-snippet';

export const getLandingPageById = createAction(
  '[Get Landing Page By ID] Get Landing Page By ID Initiated',
  props<{ landingPageId: string }>()
);
export const getLandingPageByIdSuccess = createAction(
  '[Get Landing Page By ID] Get Landing Page By ID Success',
  props<{ landingPage: LandingPage }>()
);
export const getLandingPageByIdFailed = createAction(
  '[Get Landing Page By ID] Get Landing Page By ID Failed',
  props<{ error: Error }>()
);

export const getLandingPageSnippets = createAction(
  '[Get Landing Page Snippets] Get Landing Page Snippets Initiated',
  props<{ landingPageId: string }>()
);
export const getLandingPageSnippetsSuccess = createAction(
  '[Get Landing Page Snippets] Get Landing Page Snippets Success',
  props<{ landingPageSnippets: LandingPageSnippet[] }>()
);
export const getLandingPageSnippetsFailed = createAction(
  '[Get Landing Page Snippets] Get Landing Page Snippets Failed',
  props<{ error: Error }>()
);
