import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Cluster, Region, Space } from '../../models';
import { selectAllClouds } from '../cloud';
import { selectCurrentSpace } from '../space';
import { selectAllRegions } from '../region';
import { ClusterState } from './cluster.state';

export const selectClusters = createFeatureSelector<ClusterState>('cluster');

export const selectAllClusters = createSelector(
  selectClusters,
  (state: ClusterState) => state.clusters
);

export const selectClusterScaleConfigs = createSelector(
  selectClusters,
  (state: ClusterState) => state.clusterScaleConfigs
);

export const selectClusterScaleConfigsByClusterAndCapsuleType = (props: {
  clusterId: string;
  capsuleType: string;
}) =>
  createSelector(selectClusters, (state: ClusterState) =>
    state.clusterScaleConfigs.find(
      (csc) =>
        csc.clusterId === props.clusterId &&
        csc.capsuleType === props.capsuleType
    )
  );

export const selectClusterById = (props: { id: string }) =>
  createSelector(selectAllClusters, (clusters) =>
    clusters.find((c) => c.id === props.id)
  );

export const selectClusterRegion = (props: { cluster: Cluster }) =>
  createSelector(selectAllRegions, (regions) =>
    regions.find((r) => r.key === props.cluster.regionKey)
  );

export const filterClustersByRegion = (props: { region: Region }) =>
  createSelector(selectAllClusters, (clusters) =>
    clusters.find((cluster) => {
      if (!props.region) {
        return true;
      }
      // TODO:
      // return cluster.regionKey === props.region.key
      return true;
    })
  );

export const selectClusterCloud = (props: { cluster: Cluster }) =>
  createSelector(selectAllClouds, selectAllRegions, (clouds, regions) => {
    const region = regions.find((r) => r.key === props.cluster.regionKey);
    return clouds.find((cloud) => cloud.key === region.cloudKey);
  });

export const selectCurrentCluster = createSelector(
  selectAllClusters,
  selectCurrentSpace,
  (clusters: Cluster[], space: Space) =>
    clusters.find((x) => x.id === space.clusterId)
);
