import { Repo } from '../../models';
import { BaseState } from '../base.state';

export interface RepoState extends BaseState {
  repos: Repo[];
  teamRepos: { [teamId: string]: Repo[] };
}

export const initialState: RepoState = {
  repos: [],
  teamRepos: {},
};
