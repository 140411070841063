import { createAction, props } from '@ngrx/store';
import { BillingCustomer } from '../../models/billing-customer';
import { BillingPaymentMethod } from '../../models/billing-payment-method';

export const setError = createAction(
  '[PaymentMethod] Set Error',
  props<{ error: Error }>()
);

export const createPaymentMethod = createAction(
  '[PaymentMethod] Create Payment Method Initiated',
  props<BillingPaymentMethod>()
);
export const createPaymentMethodSuccess = createAction(
  '[PaymentMethod] Create Payment Method Success',
  props<{ paymentMethod: BillingPaymentMethod }>()
);
export const createPaymentMethodnFailed = createAction(
  '[PaymentMethod] Create Payment Method Failed',
  props<{ error: Error }>()
);

export const fetchPaymentMethods = createAction(
  '[PaymentMethod] Fetch Payment Methods Initiated', props<{customer: BillingCustomer}>()
);

export const fetchPaymentMethodsSuccess = createAction(
  '[PaymentMethod] Fetch Payment Methods Success',
  props<{ paymentMethods: Array<BillingPaymentMethod>, primaryPaymentMethod: BillingPaymentMethod }>()
);

export const fetchPaymentMethodsFailed = createAction(
  '[PaymentMethod] Fetch Payment Methods Failed',
  props<{ error: Error }>()
);
