import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../user/user.actions';
import * as NotificationActions from './notification.actions';
import { initialState, NotificationState } from './notification.state';

const notificationsReducer = createReducer(
  initialState,

  // fetch notifications
  on(NotificationActions.fetchNotifications, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    NotificationActions.fetchNotificationsSuccess,
    (state, { notifications }) => ({
      ...state,
      notifications,
      loading: false,
      loadedAt: new Date(),
    })
  ),
  on(NotificationActions.fetchNotificationsFailed, (state, { error }) => ({
    ...state,
    loading: false,
  })),

  // accept team invite
  on(NotificationActions.acceptTeamInvite, (state) => ({
    ...state,
    loading: true,
  })),
  on(NotificationActions.acceptTeamInviteSuccess, (state, { inviteId }) => {
    return {
      ...state,
      notifications: state.notifications.filter((x) => x.id !== inviteId),
      loading: false,
    };
  }),
  on(NotificationActions.acceptTeamInviteFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // reject team invite
  on(NotificationActions.rejectTeamInvite, (state) => ({
    ...state,
    loading: true,
  })),
  on(NotificationActions.rejectTeamInviteSuccess, (state, { inviteId }) => {
    return {
      ...state,
      notifications: state.notifications.filter((x) => x.id !== inviteId),
      loading: false,
    };
  }),
  on(NotificationActions.rejectTeamInviteFailed, (state) => ({
    ...state,
    loading: false,
  })),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    notifications: [],
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: NotificationState | undefined, action: Action) {
  return notificationsReducer(state, action);
}
