import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Cloud } from '../../models/cloud';
import { RegionState } from './region.state';

export const selectRegions = createFeatureSelector<RegionState>('region');

export const selectAllRegions = createSelector(
  selectRegions,
  (state: RegionState) => state.regions
);

export const selectRegionById = (props: { id: string }) =>
  createSelector(selectAllRegions, (regions) =>
    regions.find((r) => r.id === props.id)
  );

export const selectRegionByKey = (props: { key: string }) =>
  createSelector(selectAllRegions, (regions) =>
    regions.find((r) => r.key === props.key)
  );

export const filterRegionsByCloud = (props: { cloud: Cloud }) =>
  createSelector(selectAllRegions, (regions) =>
    regions.find((r) => {
      if (!props.cloud) {
        return true;
      }
      return r.cloudKey === props.cloud.key;
    })
  );
