import { BaseState } from '../base.state';
import { PhoneNumber } from '../../models/phone-number';

export interface PhoneNumberState extends BaseState {
  activePhoneNumber?: PhoneNumber;
  phoneNumbers?: PhoneNumber[];
  error?: any;
}

export const initialState: PhoneNumberState = {
  activePhoneNumber: null,
  phoneNumbers: [],
  error: null,
};
