<div class="alert-holder">
  <div *ngFor="let alert of alerts" [@flyInOut]>
    <div class="bg-light alert" [ngClass]="[getAlertClass(alert)]">
      <div data-testid="alert-text">{{ alert.text }}</div>
      <button type="button" class="btn pointer" (click)="removeAlert(alert)">
        <i class="icon-cross"></i>
      </button>
    </div>
  </div>
</div>
