import { createAction, props } from '@ngrx/store';

import { PhoneNumber } from '../../models/phone-number';

function action(name: string): string {
  return '[User Phone Number] ' + name;
}

export const setError = createAction(
  action('Set Error'),
  props<{ error: unknown }>()
);

// fetch phone numbers
export const fetchPhoneNumbers = createAction(
  action('Fetch Phone Numbers')
);
export const fetchPhoneNumbersSuccess = createAction(
  action('Fetch Phone Numbers Success'),
  props<{ phoneNumbers: PhoneNumber[] }>()
);
export const fetchPhoneNumbersFailed = createAction(
  action('Fetch Phone Numbers Failed'),
  props<{ error: unknown }>()
);

// create phone number
export const createPhoneNumber = createAction(
  action('Create Phone Number'),
  props<{ phoneNumber: string }>()
);
export const createPhoneNumberSuccess = createAction(
  action('Create Phone Number Success'),
  props<{ phoneNumber: PhoneNumber }>()
);
export const createPhoneNumberFailed = createAction(
  action('Create Phone Number Failed'),
  props<{ error: unknown }>()
);

// set phone number active
export const setPhoneNumberActive = createAction(
  action('Set Phone Number Active'),
  props<{ phoneNumber: PhoneNumber }>()
);
export const setPhoneNumberActiveSuccess = createAction(
  action('Set Phone Number Active Success'),
  props<{ phoneNumbers: PhoneNumber[] }>()
);
export const setPhoneNumberActiveFailed = createAction(
  action('Set Phone Number Active Failed'),
  props<{ error: unknown }>()
);

// send code to phone number
export const sendCodeToPhoneNumber = createAction(
  action('Send Code To Phone Number'),
  props<{ phoneNumber: PhoneNumber }>()
);
export const sendCodeToPhoneNumberSuccess = createAction(
  action('Send Code To Phone Number Success')
);
export const sendCodeToPhoneNumberFailed = createAction(
  action('Send Code To Phone Number Failed'),
  props<{ error: unknown }>()
);

// verify phone number
export const verifyPhoneNumber = createAction(
  action('Verify Phone Number'),
  props<{
    phoneNumber: PhoneNumber,
    code: string,
  }>()
);
export const verifyPhoneNumberSuccess = createAction(
  action('Verify Phone Number Success'),
  props<{ phoneNumbers: PhoneNumber[] }>()
);
export const verifyPhoneNumberFailed = createAction(
  action('Verify Phone Number Failed'),
  props<{ error: unknown }>()
);
