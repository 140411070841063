import { User } from '@appstrax/services/auth';
import { BaseState } from '../base.state';
import { Cluster } from './../../models/cluster';
import { UserProfile, Team, Capsule } from 'src/app/core/models';
import { PhoneNumber } from './../../models/phone-number';
import { BillingCustomer } from '../../models/billing-customer';
import { BillingPaymentMethod } from 'src/app/core/models/billing-payment-method';
import { Customer } from '../../models/customer';
import { Invoice } from '../../models/invoice';
import { BillingProduct } from '../../models/billing-product';
import { CustomerUsage } from '../../models/customer-usage';
import { Plan } from '../../models/plan';
import { Space } from "../../../core/models/space";
import { BillingInvoice } from "../../models/billing-invoice";
import { BillingTask } from "../../models/billing-task";

export interface AdminState extends BaseState {
  // customers: Array<Customer>;
  billingCustomers: Array<BillingCustomer>;
  billingCustomersById: {},
  currentBillingInvoice: BillingInvoice;
  billingTasks: BillingTask[];
  users: Array<UserProfile>;
  usersById: {},
  teams: Team[];
  spaces: Space[];
  capsules: Capsule[];
  invoices: Array<Invoice>;
  products: Array<BillingProduct>;
  phoneNumbers: Array<PhoneNumber>;
  usage: CustomerUsage;
  customerUsage: Array<CustomerUsage>;
  plans: Array<Plan>;
  clusters: Array<Cluster>;

  // authUser: UserProfile;
  // currentUser: User;
  // phoneNumber: PhoneNumber;
  // userTeams: Array<any>; // TODO: Use site model instead of api
  // clusters: Array<Cluster>;
  // customer: BillingCustomer;
  // paymentMethods: Array<BillingPaymentMethod>;
}

export const initialState: AdminState = {
  // customers: [],
  billingCustomers: [],
  billingCustomersById: {},
  currentBillingInvoice: null,
  billingTasks: [],

  users: [],
  usersById: {},

  teams: [],
  spaces: [],
  capsules: [],
  invoices: [],
  products: [],
  phoneNumbers: [],

  usage: {
    userId: null,
    clusters: [],
    teams: [],
    clustersTotal: 0,
    teamsTotal: 0,
    total: 0,
  },
  customerUsage: [],

  plans: [],
  clusters: [],


  // authUser: null,
  // currentUser: null,
  // phoneNumber: null,
  // userTeams: [],
  // clusters: [],
  // customer: null,
  // paymentMethods: [],
};
