import { Cluster } from '../../models/cluster';
import { createAction, props } from '@ngrx/store';
import { ClusterScaleConfig } from '../../models/cluster-scale-config';
import { ClusterPricing } from '../../models';

// fetch clusters
export const fetchClusters = createAction(
  '[Cluster API] Fetch Clusters Initiated'
);
export const fetchClustersSuccess = createAction(
  '[Cluster API] Fetch Clusters Success',
  props<{ clusters: Array<Cluster> }>()
);
export const fetchClustersFailed = createAction(
  '[Cluster API] Fetch Clusters Failed',
  props<{ error: Error }>()
);

// fetch cluster
export const fetchCluster = createAction(
  '[Cluster API] Fetch Cluster Initiated',
  props<{ clusterSlugOrId: string }>()
);
export const fetchClusterSuccess = createAction(
  '[Cluster API] Fetch Clusters Success',
  props<{ cluster: Cluster }>()
);
export const fetchClusterFailed = createAction(
  '[Cluster API] Fetch Clusters Failed',
  props<{ error: Error }>()
);

// fetch cluster scale configs
export const fetchClusterScaleConfigs = createAction(
  '[Cluster API] Fetch Cluster Scale Configs Initiated'
);
export const fetchClusterScaleConfigsSuccess = createAction(
  '[Cluster API] Fetch Cluster Scale Configs Success',
  props<{ clusterScaleConfigs: Array<ClusterScaleConfig> }>()
);
export const fetchClusterScaleConfigsFailed = createAction(
  '[Cluster API] Fetch Cluster Scale Configs Failed',
  props<{ error: Error }>()
);

// add cluster
export const addCluster = createAction(
  '[Cluster API] Add Cluster Initiated',
  props<{ cluster: Cluster }>()
);
export const addClusterSuccess = createAction(
  '[Cluster API] Add Cluster Success',
  props<{ cluster: Cluster }>()
);
export const addClusterFailed = createAction(
  '[Cluster API] Add Cluster Failed',
  props<{ error: Error }>()
);


// fetch cluster pricing
export const fetchClusterPricing = createAction(
  '[Cluster] Fetch Cluster Pricing',
  props<{ clusters: Cluster[] }>()
);
export const fetchClusterPricingSuccess = createAction(
  '[Cluster] Fetch Cluster Pricing',
  props<{ cluster: Cluster; pricing: ClusterPricing }>()
);
export const fetchClusterPricingFailed = createAction(
  '[Cluster] Fetch Cluster Cluster Failed',
  props<{ error: Error }>()
);
