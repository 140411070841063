import { Team, TeamPaymentStatus, TeamProductUsage } from '../../models';
import { TeamInvitation } from '../../models/team-invitation';
import { BaseState } from '../base.state';

export interface TeamState extends BaseState {
  teams: Team[];
  invitesByTeam: { [teamId: string]: TeamInvitation[] };
  usageByTeam: { [teamId: string]: TeamProductUsage[] };
  paymentStatusByTeam: { [teamId: string]: TeamPaymentStatus };
}

export const initialState: TeamState = {
  teams: [],
  invitesByTeam: {},
  usageByTeam: {},
  paymentStatusByTeam: {},
};
