import { Action, createReducer, on } from '@ngrx/store';

import * as UserActions from '../user/user.actions';
import * as CloudActions from './cloud.actions';
import { initialState, CloudState } from './cloud.state';

const cloudsReducer = createReducer(
  initialState,
  // Fetch Cloud Actions
  on(CloudActions.fetchClouds, (state) => ({
    ...state,
    loading: true,
  })),
  on(CloudActions.fetchCloudsSuccess, (state, { clouds }) => ({
    ...state,
    clouds,
    loading: false,
    loadedAt: new Date(),
  })),
  on(CloudActions.fetchCloudsFailed, (state, { error }) => ({
    ...state,
    loading: false,
  })),

  // clear state on logout
  on(UserActions.logoutSuccess, (state) => ({
    ...state,
    clouds: [],
    currentCloud: null,
    loadedAt: null,
    loading: false,
    saving: false,
  }))
);

export function reducer(state: CloudState | undefined, action: Action) {
  return cloudsReducer(state, action);
}
