import {
  Component,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements AfterViewInit {
  @Input() size = '100%';
  @Input() color = '#fff';

  @ViewChild('holder') holder: ElementRef;

  ngAfterViewInit(): void {
    let width = this.holder.nativeElement.offsetWidth;
    const borderWidth = (width ? width : 24) * 0.1 + 'px';

    const elements = Array.from(document.getElementsByClassName('lds-border'));
    for (const div of elements) {
      div.setAttribute(
        'style',
        `
      border: ${borderWidth} solid ${this.color};
      border-color: ${this.color} transparent transparent transparent;
      `
      );
    }
  }
}
