import { createAction, props } from '@ngrx/store';

export const addBackupAndRestoreIds = createAction(
  '[BackupAndRestoreIds] Add Backup, Restore, and Capsule IDs',
  props<{ backupId: string | null; restoreId: string | null; capsuleId: string }>()
);

export const deleteBackupAndRestoreIds = createAction(
  '[BackupAndRestoreIds] Delete Backup, Restore, and Capsule IDs',
  props<{ capsuleId: string }>()
);
