// angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  concatMap,
  mergeMap,
} from 'rxjs/operators';

// ngrx
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ClusterActions from './cluster.actions';
import * as ClusterSelectors from './cluster.selector';
import * as UserActions from '../user/user.actions';

import { APIResponse } from '../../api/models/api-response';
import { environment } from '../../../../environments/environment';
import { Cluster } from '../../models/cluster';
import { ClusterPricing, ClusterScaleConfig } from '../../models';

@Injectable()
export class ClusterEffects {
  constructor(
    private actions$: Actions<any>,
    private httpClient: HttpClient,
    private store: Store
  ) {}

  fetchClusters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess, ClusterActions.fetchClusters),

      switchMap(() => {
        return this.httpClient
          .get<APIResponse<Cluster[]>>(`${environment.apiBaseUrl}/clusters`)
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return ClusterActions.fetchClustersFailed({
                  error: new Error('Something went wrong.')
                });
              }

              return ClusterActions.fetchClustersSuccess({
                clusters: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(ClusterActions.fetchClustersFailed({ error }))
            )
          );
      })
    )
  );

  fetchClusterScaleConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ClusterActions.fetchClustersSuccess,
        ClusterActions.fetchClusterScaleConfigs
      ),

      switchMap(() => {
        return this.httpClient
          .get<APIResponse<ClusterScaleConfig[]>>(
            `${environment.apiBaseUrl}/clusters/scale-configs`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return ClusterActions.fetchClusterScaleConfigsFailed({
                  error: new Error('Something went wrong.')
                });
              }

              return ClusterActions.fetchClusterScaleConfigsSuccess({
                clusterScaleConfigs: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(ClusterActions.fetchClusterScaleConfigsFailed({ error }))
            )
          );
      })
    )
  );

  fetchClustersPricing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ClusterActions.fetchClusterPricing,
        ClusterActions.fetchClustersSuccess
      ),

      switchMap((action: Partial<{ clusters: Cluster[] }>) => {
        return from(action.clusters || []);
      }),

      mergeMap((cluster: Cluster) =>
        this.httpClient
          .get<ClusterPricing>(
            `${cluster.clusterApiEndpoint}/configured-products`
          )
          .pipe(
            map((apiResponse) => {
              return ClusterActions.fetchClusterPricingSuccess({
                cluster,
                pricing: apiResponse,
              });
            }),
            catchError((error) =>
              of(ClusterActions.fetchClusterPricingFailed({ error }))
            )
          )
      )
    )
  );

  fetchCluster$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClusterActions.fetchCluster),

      withLatestFrom(this.store.select(ClusterSelectors.selectAllClusters)),

      concatMap(([action, clusters]) => {
        const clusterSlugOrId = action.clusterSlugOrId;
        const cluster = clusters.find(
          (x) => x.slug === clusterSlugOrId || x.id === clusterSlugOrId
        );
        if (cluster) {
          return of(ClusterActions.fetchClusterSuccess({ cluster }));
        }

        // TODO: Fix api endpoint to return by slug or id in 1 endpoint
        return this.httpClient
          .get<APIResponse<Cluster>>(
            `${environment.apiBaseUrl}/clusters/slug/${action.clusterSlugOrId}`
          )
          .pipe(
            map((apiResponse) => {
              if (!apiResponse.data) {
                return ClusterActions.fetchClusterFailed({
                  error: new Error('Something went wrong.'),
                });
              }

              const clusterData = apiResponse.data;
              clusterData.role = 'owner';
              return ClusterActions.fetchClusterSuccess({
                cluster: clusterData,
              });
            }),
            catchError((error) =>
              of(ClusterActions.fetchClusterFailed({ error }))
            )
          );
      })
    )
  );
}
