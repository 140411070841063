<div class="form-group" [formGroup]="form">
  <label *ngIf="label">
    {{ label }}
    <span class="text-danger small" *ngIf="isRequired()">*</span>
  </label>
  <input class="form-control dark-border" [type]="type" [formControlName]="name"
    [class.is-invalid]="formControl.invalid && form.submitted" [autofocus]="autofocus"
    [placeholder]="placeholder" (blur)="focus = false;" (focus)="focus = true;" data-testid="app-input">

  <div *ngIf="form.submitted && !focus && this.formControl.invalid" class="errors">
    <div class="error">{{ getError() }}</div>
  </div>
</div>
