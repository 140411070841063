import { Action, createReducer, on } from '@ngrx/store';
import * as LandingPageActions from './landing-page.actions';
import { LandingPageState, initialState } from './landing-page.state';

const landingPageReducer = createReducer(
  initialState,
  on(
    LandingPageActions.getLandingPageByIdSuccess,
    (state, { landingPage }) => ({
      ...state,
      landingPage: landingPage,
    })
  ),
  on(
    LandingPageActions.getLandingPageSnippetsSuccess,
    (state, { landingPageSnippets }) => ({
      ...state,
      landingPageSnippets: landingPageSnippets,
    })
  )
);

export function reducer(state: LandingPageState, action: Action) {
  return landingPageReducer(state, action);
}
