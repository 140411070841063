import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CloudState } from './cloud.state';
import { Region } from '../../models/region';

export const selectClouds = createFeatureSelector<CloudState>('cloud');

export const selectAllClouds = createSelector(
  selectClouds,
  (state: CloudState) => state.clouds
);

export const selectCloudById = (props: { id: string }) =>
  createSelector(selectAllClouds, (clouds) =>
    clouds.find((cloud) => cloud.id === props.id)
  );


export const selectCloudByRegion = (props: { region: Region }) =>
  createSelector(selectAllClouds, (clouds) =>
    clouds.find((cloud) => cloud.key === props.region.cloudKey)
  );

export const selectCloudByKey = (props: { key: string }) =>
  createSelector(selectAllClouds, (clouds) =>
    clouds.find((cloud) => cloud.key === props.key)
  );

export const selectCurrentCloud = createSelector(
  selectClouds,
  (state: CloudState) => state.currentCloud
);
