import { createAction, props } from '@ngrx/store';

import { Team, Repo } from '../../models';

// fetch repos action
export const fetchRepos = createAction('[Repo] Fetch Repos');
export const fetchReposSuccess = createAction(
  '[Repo] Fetch Repos Success',
  props<{ repos: Repo[] }>()
);
export const fetchReposFailed = createAction(
  '[Repo] Fetch Repos Failed',
  props<{ error: Error }>()
);

// fetch team repos action
export const fetchTeamRepos = createAction(
  '[Repo] Fetch Team Repos',
  props<{ team: Team }>()
);
export const fetchTeamReposSuccess = createAction(
  '[Repo] Fetch Team Repos Success',
  props<{ repos: Repo[]; team: Team }>()
);
export const fetchTeamReposFailed = createAction(
  '[Repo] Fetch Team Repos Failed',
  props<{ error: Error }>()
);

// add team repo action
export const addTeamRepo = createAction(
  '[Repo] Add Team Repo',
  props<{ repo: Repo; team: Team }>()
);
export const addTeamRepoSuccess = createAction(
  '[Repo] Add Team Repo Success',
  props<{ repo: Repo; team: Team }>()
);
export const addTeamRepoFailed = createAction(
  '[Repo] Add Team Repo Failed',
  props<{ error: Error }>()
);

// remove team repo action
export const removeTeamRepo = createAction(
  '[Repo] Remove Team Repo',
  props<{ repo: Repo; team: Team }>()
);
export const removeTeamRepoSuccess = createAction(
  '[Repo] Remove Team Repo Success',
  props<{ repo: Repo; team: Team }>()
);
export const removeTeamRepoFailed = createAction(
  '[Repo] Remove Team Repos Failed',
  props<{ error: Error }>()
);
