import { createFeatureSelector, createSelector } from '@ngrx/store';
import { routerStateConfig } from './ngrx-router.module';
import { RouteReducerState } from './route';

export const selectRouterReducerState =
  createFeatureSelector<RouteReducerState>(routerStateConfig.stateKey);

export const selectRoute = createSelector(
  selectRouterReducerState,
  (routerReducerState) => routerReducerState.state
);
