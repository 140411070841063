import { Agency } from '../../models/agency';
import { BaseState } from '../base.state';

export interface AgencyState extends BaseState {
  agency: Agency;
}

export const initialState: AgencyState = {
  agency: null,
};
