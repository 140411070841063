export const environment = {
  production: false,
  apiBaseUrl: 'https://test-api.codecapsules.io/api',
  billingApi: 'https://test-billing.codecapsules.io',
  siteDisplayName: '(Test) CodeCapsules',
  stripePublicKey: 'pk_test_U3ePM9TNbbWKRXyKnZtZaToK',
  githubInstallationBaseUrl: 'https://github.com/apps/code-capsules-testing/installations/new?state=',

  appstraxServicesApiUrl: 'https://test-appstrax-services.codecapsules.io',
  appstraxServicesApiKey: 'pCxvKe4Ijf0Y8xBSxdnLoydpts6eay',

  appBaseUrl: 'https://test.codecapsules.io',
  hotjarSiteId: 2703882,
  hotjarVersion: 6,
  reCaptchaSiteId: '6LcURt4mAAAAADGQsQl5o9LJcfpKoNyKK2ZAlmWR',
};
